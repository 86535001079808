import { CALL_API } from 'store/middlewares/api';
import { ERROR_CODE } from 'utils/error';
import { getApiMarket } from 'store/utils';

export const actionTypes = {
  ANSWER_GROUPS_FETCH: 'ANSWER_GROUPS_FETCH',
  ANSWER_GROUPS_FETCH_REQUEST: 'ANSWER_GROUPS_FETCH_REQUEST',
  ANSWER_GROUPS_FETCH_SUCCESS: 'ANSWER_GROUPS_FETCH_SUCCESS',
  ANSWER_GROUPS_FETCH_FAILURE: 'ANSWER_GROUPS_FETCH_FAILURE',
  ANSWER_GROUPS_CREATE: 'ANSWER_GROUPS_CREATE',
  ANSWER_GROUPS_CREATE_REQUEST: 'ANSWER_GROUPS_CREATE_REQUEST',
  ANSWER_GROUPS_CREATE_SUCCESS: 'ANSWER_GROUPS_CREATE_SUCCESS',
  ANSWER_GROUPS_CREATE_FAILURE: 'ANSWER_GROUPS_CREATE_FAILURE',
  ANSWER_GROUPS_DISABLE_GROUP: 'ANSWER_GROUPS_DISABLE_GROUP',
  ANSWER_GROUPS_DISABLE_GROUP_REQUEST: 'ANSWER_GROUPS_DISABLE_GROUP_REQUEST',
  ANSWER_GROUPS_DISABLE_GROUP_SUCCESS: 'ANSWER_GROUPS_DISABLE_GROUP_SUCCESS',
  ANSWER_GROUPS_DISABLE_GROUP_FAILURE: 'ANSWER_GROUPS_DISABLE_GROUP_FAILURE',
  ANSWER_GROUPS_ENABLE_GROUP: 'ANSWER_GROUPS_ENABLE_GROUP',
  ANSWER_GROUPS_ENABLE_GROUP_REQUEST: 'ANSWER_GROUPS_ENABLE_GROUP_REQUEST',
  ANSWER_GROUPS_ENABLE_GROUP_SUCCESS: 'ANSWER_GROUPS_ENABLE_GROUP_SUCCESS',
  ANSWER_GROUPS_ENABLE_GROUP_FAILURE: 'ANSWER_GROUPS_ENABLE_GROUP_FAILURE',
  ANSWER_GROUPS_UPDATE_GROUP: 'ANSWER_GROUPS_UPDATE_GROUP',
  ANSWER_GROUPS_UPDATE_GROUP_REQUEST: 'ANSWER_GROUPS_UPDATE_GROUP_REQUEST',
  ANSWER_GROUPS_UPDATE_GROUP_SUCCESS: 'ANSWER_GROUPS_UPDATE_GROUP_SUCCESS',
  ANSWER_GROUPS_UPDATE_GROUP_FAILURE: 'ANSWER_GROUPS_UPDATE_GROUP_FAILURE',
};

export const getAnswerGroups = () => ({
  [CALL_API]: {
    type: actionTypes.ANSWER_GROUPS_FETCH,
    payload: {
      endpoint: `/${getApiMarket()}/answer-groups`,
      method: 'get',
    },
    meta: {},
  },
});

export const createAnswerGroup = values => ({
  [CALL_API]: {
    type: actionTypes.ANSWER_GROUPS_CREATE,
    payload: {
      endpoint: `/${getApiMarket()}/answer-groups`,
      method: 'post',
      ...values,
    },
    meta: {
      successToastMessage: 'answerGroupCreated',
      errorToastMessageConfig: {
        [ERROR_CODE.CONFLICT]: { default: 'answerGroupExists' },
        [ERROR_CODE.UNPROCESSABLE_ENTITY]: { default: 'createValidationError' },
      },
    },
  },
});

export const disableAnswerGroup = id => ({
  [CALL_API]: {
    type: actionTypes.ANSWER_GROUPS_DISABLE_GROUP,
    payload: {
      endpoint: `/${getApiMarket()}/answer-groups/${id}/disable`,
      method: 'post',
    },
    meta: {
      successToastMessage: 'answerGroupDisabled',
      id,
    },
  },
});

export const enableAnswerGroup = id => ({
  [CALL_API]: {
    type: actionTypes.ANSWER_GROUPS_ENABLE_GROUP,
    payload: {
      endpoint: `/${getApiMarket()}/answer-groups/${id}/enable`,
      method: 'post',
    },
    meta: {
      successToastMessage: 'answerGroupEnabled',
      id,
    },
  },
});

export const updateAnswerGroup = ({ id, values }) => ({
  [CALL_API]: {
    type: actionTypes.ANSWER_GROUPS_UPDATE_GROUP,
    payload: {
      endpoint: `/${getApiMarket()}/answer-groups/${id}`,
      method: 'put',
      ...values,
    },
    meta: {
      id,
      successToastMessage: 'answerGroupUpdated',
      errorToastMessageConfig: {
        [ERROR_CODE.CONFLICT]: { default: 'answerGroupExists' },
        [ERROR_CODE.UNPROCESSABLE_ENTITY]: { default: 'updateValidationError' },
      },
    },
  },
});
