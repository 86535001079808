import { actionTypes } from './actions';

const initialState = {
  data: {},
  loading: false,
  fulfilled: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ASSIGNED_QUESTIONS_LOG_FETCH_REQUEST:
      return { ...initialState, loading: true };
    case actionTypes.ASSIGNED_QUESTIONS_LOG_FETCH_SUCCESS:
      return { ...initialState, data: action.payload, fulfilled: true };
    case actionTypes.ASSIGNED_QUESTIONS_LOG_FETCH_FAILURE:
      return { ...initialState, error: action.payload };
    default:
      return state;
  }
};
