import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import { DEFAULT_SEARCH_DEBOUNCE } from 'const/timings';

const Search = ({ placeholder, className, onChange, defaultValue }) => {
  let timeout;

  const debouncedOnChange = event => {
    event.stopPropagation();
    const searchValue = event.target.value.trim().toLowerCase();
    clearTimeout(timeout);
    timeout = setTimeout(() => onChange(searchValue), DEFAULT_SEARCH_DEBOUNCE);
  };

  const classNames = cx({
    'sg-search': true,
    [className]: !!className,
  });

  return (
    <div className={classNames}>
      <input
        type='search'
        className='sg-input sg-input--with-icon sg-search__input'
        placeholder={placeholder}
        onChange={debouncedOnChange}
        defaultValue={defaultValue}
      />
      <button className='sg-search__icon'>
        <div className='sg-icon sg-icon--icon-gray-50 sg-icon--x16'>
          <svg className='sg-icon__svg' role='img' aria-labelledby='title-search-6qp6hz' focusable='false'>
            <title id='title-search-6qp6hz'>search</title>
            <use xlinkHref='#icon-search' aria-hidden='true' />
          </svg>
        </div>
      </button>
    </div>
  );
};

Search.propTypes = {
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

Search.defaultProps = {
  defaultValue: '',
  className: null,
  placeholder: 'Search',
};

export default Search;
