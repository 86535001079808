import { Redirect, Route, Switch } from 'react-router-dom';
import React, { lazy } from 'react';

import { freelancerRoutes, profilePath } from 'const/routing';

import { Container } from 'ui';

const AnsweringContainer = lazy(() => import('screens/Freelancer/AnsweringFeed/Containers/AnsweringContainer'));
const EvaluationView = lazy(() => import('screens/Freelancer/EvaluationView/EvaluationView'));
const ProfilePage = lazy(() => import('screens/Profile/ProfilePage'));

const FreelancerRoutes = () => {
  return (
    <Container>
      <Switch>
        <Route exact path={freelancerRoutes.answeringPath} component={AnsweringContainer} />
        <Route exact path={freelancerRoutes.evaluateAnswerPath} component={EvaluationView} />
        <Route path={profilePath} component={ProfilePage} />
        <Route exact path='/' render={() => <Redirect to={freelancerRoutes.answeringPath} />} />
      </Switch>
    </Container>
  );
};

export default FreelancerRoutes;
