import { createSelector } from 'reselect';

const getSearches = createSelector(
  state => state.search,
  search => search,
);

export const getAnswerGroupsListSearch = createSelector(
  getSearches,
  ({ answerGroupsListSearch }) => answerGroupsListSearch.toLowerCase(),
);

export const getUserListSearch = createSelector(
  getSearches,
  ({ userListSearch }) => userListSearch.toLowerCase(),
);

export const getAddUsersToGroupSearch = createSelector(
  getSearches,
  ({ addUsersToGroupSearch }) => addUsersToGroupSearch.toLowerCase(),
);

export const getAnswerGroupUsersSearch = createSelector(
  getSearches,
  ({ answerGroupUsersSearch }) => answerGroupUsersSearch.toLowerCase(),
);

export const getProjectsSearch = createSelector(
  getSearches,
  ({ projectsSearch }) => projectsSearch.toLowerCase(),
);
