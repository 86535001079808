import { CALL_API } from 'store/middlewares/api';
import { ERROR_CODE } from 'utils/error';
import { getApiMarket } from 'store/utils';

export const actionTypes = {
  SINGLE_WORKER_LOG_FETCH: 'SINGLE_WORKER_LOG_FETCH',
  SINGLE_WORKER_LOG_FETCH_REQUEST: 'SINGLE_WORKER_LOG_FETCH_REQUEST',
  SINGLE_WORKER_LOG_FETCH_SUCCESS: 'SINGLE_WORKER_LOG_FETCH_SUCCESS',
  SINGLE_WORKER_LOG_FETCH_FAILURE: 'SINGLE_WORKER_LOG_FETCH_FAILURE',
};

export const getSingelWorkerLogDetails = (feed, id) => ({
  [CALL_API]: {
    type: actionTypes.SINGLE_WORKER_LOG_FETCH,
    payload: {
      endpoint: `/${getApiMarket()}/worker-history/${feed}/${id}`,
      method: 'get',
    },
    meta: {
      errorToastMessageConfig: {
        [ERROR_CODE.BAD_REQUEST]: { default: 'limitsBadRequest' },
        [ERROR_CODE.FORBIDDEN]: { default: 'limitsForbidden' },
      },
    },
  },
});
