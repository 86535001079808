import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import { Label } from '..';

import style from './RadioInput.module.scss';

const RadioInput = ({ label, value, onChange, checked, disabled }) => {
  const classNames = cx(style.sgInputRadio, {
    [style.sgInputRadioChecked]: checked,
    [style.sgInputRadioDisabled]: disabled,
  });
  return (
    <div className={style.inputWrapper}>
      <Label>{!!label && <span>{label}</span>}</Label>
      <div className={classNames} onClick={() => disabled || onChange(value)} />
    </div>
  );
};

RadioInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

RadioInput.defaultProps = {
  label: null,
  value: '',
  disabled: false,
};

export default RadioInput;
