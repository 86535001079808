import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import { renderCells } from './utils';

import style from './Table.module.scss';

const TableRow = ({ className, cellClassName, row, isHeader }) => {
  const classNames = cx({
    [style.row]: !isHeader,
    [className]: !!className,
  });

  return <tr className={classNames}>{renderCells(row, isHeader, cellClassName)}</tr>;
};

TableRow.propTypes = {
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  isHeader: PropTypes.bool,
  row: PropTypes.array.isRequired,
};

TableRow.defaultProps = {
  className: null,
  cellClassName: null,
  isHeader: false,
};

export default TableRow;
