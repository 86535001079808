import { actionTypes } from './actions';

const initialState = { active: null };

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MENU_OPTION_SET_ACTIVE_OPTION:
      return { active: action.payload.active };
    default:
      return state;
  }
};
