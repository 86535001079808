import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import { childrenPropType } from 'utils/propTypes';

import { LABEL_TYPE } from 'const/styles';

import styles from './Label.module.scss';

const Label = ({ icon, type, labelText, children, className, isRequired, closable, closableFunction }) => {
  const containerClasses = {
    'sg-label': true,
  };
  if (type.length > 0) {
    // eslint-disable-next-line no-return-assign
    type.split(',').forEach(t => (containerClasses[`sg-label--${t}`] = true));
  }
  if (closable === LABEL_TYPE.CLOSABLE) {
    containerClasses['sg-label--closable'] = true;
  }
  const containerClassNames = cx(containerClasses);

  const textClasses = {
    'sg-text': true,
    'sg-label__text': true,
  };
  if (labelText.length > 0) {
    // eslint-disable-next-line no-return-assign
    labelText.split(',').forEach(t => (textClasses[`sg-text--${t}`] = true));
  }
  const containerTextClassNames = cx(textClasses);
  const classNames = cx({
    [containerTextClassNames]: !!containerTextClassNames,
    [className]: !!className,
  });

  return (
    <div className='sg-flex'>
      <div
        className={
          closable === LABEL_TYPE.CLOSABLE
            ? 'sg-flex sg-flex--column'
            : 'sg-flex sg-flex--column sg-flex--margin-right-l'
        }
      >
        <div className={containerClassNames}>
          <If condition={icon !== null}>
            <div className='sg-label__icon'>
              <div className='sg-icon sg-icon--icon-black sg-icon--x16'>
                <svg className='sg-icon__svg' role='img' aria-labelledby={`title-${icon}-bbussh`} focusable='false'>
                  <title id={`title-${icon}-bbussh`}>{icon}</title>
                  <use xlinkHref={`#icon-${icon}`} aria-hidden='true' />
                </svg>
              </div>
            </div>
          </If>

          <div className={classNames}>
            {children} {isRequired && <span className={styles.labelRequiredMark}>*</span>}
          </div>

          <If condition={closable === LABEL_TYPE.CLOSABLE}>
            <button className='sg-label__close-button' title='close' aria-label='close' onClick={closableFunction}>
              <div className='sg-icon sg-icon--icon-black sg-icon--x16'>
                <svg className='sg-icon__svg' role='img' aria-labelledby='title-close-bkhxa' focusable='false'>
                  <title id='title-close-bkhxa'>close</title>
                  <use xlinkHref='#icon-close' aria-hidden='true' />
                </svg>
              </div>
            </button>
          </If>
        </div>
      </div>
    </div>
  );
};

Label.propTypes = {
  children: childrenPropType.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
  labelText: PropTypes.string,
  isRequired: PropTypes.bool,
  closable: PropTypes.string,
  closableFunction: PropTypes.func,
};

Label.defaultProps = {
  className: null,
  icon: null,
  type: '',
  labelText: '',
  isRequired: false,
  closable: '',
  closableFunction: null,
};

export default Label;
