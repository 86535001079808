import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import { INPUT_SIZE, LABEL_TYPE, TEXT_TYPE } from 'const/styles';
import { Label } from 'ui';

import style from './Input.module.scss';

const Input = ({
  placeholder,
  type,
  label,
  required,
  onChange,
  value,
  error,
  maxLength,
  disabled,
  size,
  className,
}) => {
  const classNames = cx(style.textInput, {
    'sg-input': true,
    'sg-input--invalid': error,
    [`sg-input--${size}`]: !!size,
    [className]: !!className,
  });

  return (
    <>
      {label && (
        <Label
          type={LABEL_TYPE.TRANSPARENT}
          labelText={`${TEXT_TYPE.TEXT_BLACK},${TEXT_TYPE.SMALL},${TEXT_TYPE.BOLD}`}
          isRequired={required}
        >
          {label}
        </Label>
      )}
      <input
        type={type}
        className={classNames}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        maxLength={maxLength}
        disabled={disabled}
      />
    </>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(INPUT_SIZE)),
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Input.defaultProps = {
  className: null,
  disabled: false,
  error: false,
  label: null,
  maxLength: 255,
  placeholder: '',
  required: false,
  size: '',
  type: 'text',
  value: '',
};

export default Input;
