import { CALL_API } from 'store/middlewares/api';
import { ERROR_CODE } from 'utils/error';
import { generateAnsweringModeQuestionURL } from 'utils/generateQuestionURL';
import { getCurrentUserMarket } from 'store/auth/selectors';
import { getQuestions } from 'store/questionsFeed/selectors';
import { pushGAEvent } from 'utils/GAEvents';

export const actionTypes = {
  ANSWERING_MODE_STATUS: 'ANSWERING_MODE_STATUS',
  ANSWERING_MODE_STATUS_REQUEST: 'ANSWERING_MODE_STATUS_REQUEST',
  ANSWERING_MODE_STATUS_SUCCESS: 'ANSWERING_MODE_STATUS_SUCCESS',
  ANSWERING_MODE_STATUS_FAILURE: 'ANSWERING_MODE_STATUS_FAILURE',

  ANSWERING_MODE_CANCEL: 'ANSWERING_MODE_CANCEL',
  ANSWERING_MODE_CANCEL_REQUEST: 'ANSWERING_MODE_CANCEL_REQUEST',
  ANSWERING_MODE_CANCEL_SUCCESS: 'ANSWERING_MODE_CANCEL_SUCCESS',
  ANSWERING_MODE_CANCEL_FAILURE: 'ANSWERING_MODE_CANCEL_FAILURE',

  ANSWERING_CHECK_IF_ANSWERED: 'ANSWERING_CHECK_IF_ANSWERED',
  ANSWERING_CHECK_IF_ANSWERED_REQUEST: 'ANSWERING_CHECK_IF_ANSWERED_REQUEST',
  ANSWERING_CHECK_IF_ANSWERED_SUCCESS: 'ANSWERING_CHECK_IF_ANSWERED_SUCCESS',
  ANSWERING_CHECK_IF_ANSWERED_FAILURE: 'ANSWERING_CHECK_IF_ANSWERED_FAILURE',

  ANSWERING_MODE_CLOSE: 'ANSWERING_MODE_CLOSE',

  ANSWERING_MODE_OPEN: 'ANSWERING_MODE_OPEN',
  ANSWERING_MODE_OPEN_REQUEST: 'ANSWERING_MODE_OPEN_REQUEST',
  ANSWERING_MODE_OPEN_SUCCESS: 'ANSWERING_MODE_OPEN_SUCCESS',
  ANSWERING_MODE_OPEN_FAILURE: 'ANSWERING_MODE_OPEN_FAILURE',
};

export const openAnsweringMode = (feed, questionId) => (dispatch, getState) => {
  const state = getState();
  const questions = getQuestions(state);
  const market = getCurrentUserMarket(state);
  const { content: questionContent } = questions.find(question => question.questionId === questionId);

  return dispatch({
    [CALL_API]: {
      type: actionTypes.ANSWERING_MODE_OPEN,
      payload: {
        endpoint: `/${market}/questions/${feed}/${questionId}/open`,
        method: 'post',
      },
      meta: {
        questionContent,
        errorToastMessageConfig: {
          [ERROR_CODE.CONFLICT]: { default: 'userAlreadyOpenedQuestion' },
          [ERROR_CODE.FORBIDDEN]: { default: 'accessDenied' },
        },
      },
      successCallback: () => {
        const questionUrl = generateAnsweringModeQuestionURL(questionId, market);
        window.open(questionUrl);

        // GA TRACKING
        pushGAEvent({
          event: 'GAEvent',
          eventCategory: 'user loggedin',
          eventAction: 'answer question',
          eventLabel: feed,
        });
      },
    },
  });
};

export const getAnsweringModeStatus = market => ({
  [CALL_API]: {
    type: actionTypes.ANSWERING_MODE_STATUS,
    payload: {
      endpoint: `/${market}/questions/answering-mode/status`,
      method: 'get',
    },
  },
});

export const closeAnsweringMode = () => ({
  type: actionTypes.ANSWERING_MODE_CLOSE,
});

export const cancelAnswering = market => ({
  [CALL_API]: {
    type: actionTypes.ANSWERING_MODE_CANCEL,
    payload: {
      endpoint: `/${market}/questions/answering-mode/close`,
      method: 'post',
    },
  },
});

export const checkIfAnswered = market => ({
  [CALL_API]: {
    type: actionTypes.ANSWERING_CHECK_IF_ANSWERED,
    payload: {
      endpoint: `/${market}/questions/answering-mode/check-answer`,
      method: 'post',
    },
  },
});
