import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

import style from './MultipleSelect.module.scss';

const MultipleSelect = ({ name, label, options, className, onChange, value, onBlur, ...props }) => {
  const { t } = useTranslation('MultipleSelect');
  const styles = {
    placeholder: provided => ({
      ...provided,
      color: style.fontColor,
      fontWeight: 'bold',
    }),
    control: provided => ({
      ...provided,
      minHeight: 36,
      boxShadow: '0 1px 6px 0 rgba(2,10,27,0.2)',
      border: 'unset',
    }),
  };

  return (
    <Select
      className={className}
      value={options.filter(({ value: v }) => value.includes(v))}
      isMulti
      name={name}
      options={options}
      styles={styles}
      placeholder={value.length !== 0 ? t('placeholderWhenFiltered') : label}
      closeMenuOnSelect={false}
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      backspaceRemovesValue={false}
      onChange={values => onChange(values ? values.map(({ value: v }) => v) : values)}
      onBlur={onBlur}
      {...props}
    />
  );
};

MultipleSelect.propTypes = {
  className: PropTypes.string,
  value: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};

MultipleSelect.defaultProps = {
  className: null,
  value: [],
  label: null,
  onBlur: () => {},
};

export default MultipleSelect;
