export const mainPagePath = '/';
export const loginPath = '/login';
export const logoutPath = '/logout';
export const profilePath = '/profile';
export const accountSetupPath = '/account-setup';
export const privacyInformationPath = '/privacy-information';
export const termsOfUsePath = '/tutor-terms-of-use';
export const privacyPolicyPath = '/tutor-privacy-policy';

export const freelancerRoutes = {
  answeringPath: '/answering',
  evaluateAnswerPath: '/evaluate-answers',
};

// ADMIN ROUTES
const adminBasePath = '/admin';

export const adminRoutes = {
  adminBasePath,
  profilePath,
  userListPath: `${adminBasePath}/users`,
  createUserPath: `${adminBasePath}/users/new`,
  manageUserPath: `${adminBasePath}/users/:id`,
  createAnswerGroupPath: `${adminBasePath}/answer-groups/new`,
  manageAnswerGroupPath: `${adminBasePath}/answer-groups/:id`,
  answerGroupsPath: `${adminBasePath}/answer-groups`,
  createProjectPath: `${adminBasePath}/projects/new`,
  manageProjectPath: `${adminBasePath}/projects/:id`,
  projectsPath: `${adminBasePath}/projects`,
  limitsPath: `${adminBasePath}/limits`,
  feedsPath: `${adminBasePath}/feeds`,
  limitsFulfillmentPath: `${adminBasePath}/limits-fulfilment-status`,
  reassignPath: `${adminBasePath}/reassign`,
  workerLogsPath: `${adminBasePath}/worker-logs`,
  workerLogDetailsPath: `${adminBasePath}/worker-logs/:feed/:id`,
  assignedQuestionsPath: `${adminBasePath}/assigned-questions`,
  evaluationContentPath: `${adminBasePath}/evaluation-content`,
  evaluationCheckpointsManagmentPath: `${adminBasePath}/evaluation-checkpoints-management`,
};

// ANSWERING GROUP ADMIN ROUTES
const answeringGroupAdminBasePath = '/answering-group-admin';

export const answeringGroupAdminRoutes = {
  answeringGroupAdminBasePath,
  profilePath,
  userListPath: `${answeringGroupAdminBasePath}/users`,
  createUserPath: `${answeringGroupAdminBasePath}/users/new`,
  manageUserPath: `${answeringGroupAdminBasePath}/users/:id`,
};
