import { actionTypes } from './actions';

const initialState = {
  isActive: false,
  questionContent: '',
  checkStatus: {
    loading: false,
    error: null,
  },
  cancelAnswering: { loading: false, error: null },
  checkIfAnswered: {
    loading: false,
    error: null,
  },
  openQuestion: {
    loading: false,
    error: null,
  },
};

const OPENED_QUESTION_NOT_FOUND_ERROR_MESSAGE = 'OPENED_QUESTION_NOT_FOUND';

const {
  ANSWERING_MODE_STATUS_REQUEST,
  ANSWERING_MODE_STATUS_SUCCESS,
  ANSWERING_MODE_STATUS_FAILURE,
  ANSWERING_MODE_CLOSE,
  ANSWERING_MODE_OPEN_REQUEST,
  ANSWERING_MODE_OPEN_SUCCESS,
  ANSWERING_MODE_OPEN_FAILURE,
  ANSWERING_MODE_CANCEL_REQUEST,
  ANSWERING_MODE_CANCEL_SUCCESS,
  ANSWERING_MODE_CANCEL_FAILURE,
  ANSWERING_CHECK_IF_ANSWERED_REQUEST,
  ANSWERING_CHECK_IF_ANSWERED_SUCCESS,
  ANSWERING_CHECK_IF_ANSWERED_FAILURE,
} = actionTypes;

export default (state = initialState, action) => {
  switch (action.type) {
    case ANSWERING_MODE_STATUS_REQUEST:
      return { ...initialState, checkStatus: { loading: true, error: null } };
    case ANSWERING_MODE_STATUS_SUCCESS:
      return {
        ...initialState,
        isActive: action.payload.active,
        questionContent: action.payload.questionContent,
        checkStatus: { ...initialState.checkStatus },
      };
    case ANSWERING_MODE_STATUS_FAILURE:
      return { ...initialState, checkStatus: { loading: false, error: action.payload } };
    case ANSWERING_MODE_CLOSE:
      return { ...initialState, isActive: false };
    case ANSWERING_MODE_OPEN_REQUEST:
      return { ...state, openQuestion: { loading: true, error: null } };
    case ANSWERING_MODE_OPEN_SUCCESS:
      return {
        ...state,
        isActive: true,
        questionContent: action.meta.questionContent,
        openQuestion: { ...initialState.openQuestion },
      };
    case ANSWERING_MODE_OPEN_FAILURE:
      return { ...state, openQuestion: { loading: false, error: action.payload } };
    case ANSWERING_MODE_CANCEL_REQUEST:
      return { ...state, cancelAnswering: { ...state.cancelAnswering, loading: true } };
    case ANSWERING_MODE_CANCEL_SUCCESS:
      return { ...initialState };
    case ANSWERING_MODE_CANCEL_FAILURE:
      return { ...state, cancelAnswering: { loading: false, error: action.payload } };
    case ANSWERING_CHECK_IF_ANSWERED_REQUEST:
      return { ...state, checkIfAnswered: { ...state.checkIfAnswered, loading: true } };
    case ANSWERING_CHECK_IF_ANSWERED_SUCCESS:
      return { ...initialState };
    case ANSWERING_CHECK_IF_ANSWERED_FAILURE: {
      // Closing answering mode if for some reason opened question is not found
      // and websocket doesn't send the event AnsweringModeClose
      if (action.payload.message === OPENED_QUESTION_NOT_FOUND_ERROR_MESSAGE) {
        return { ...initialState, isActive: false };
      }
      return { ...state, checkIfAnswered: { loading: false, error: action.payload } };
    }

    default:
      return state;
  }
};
