import PropTypes from 'prop-types';
import React from 'react';

/**
 * @param iconName - It has to be one of names included here: https://styleguide.brainly.com/210.0.0/docs/basics.html#subject-icons
 * @param fillColor - It has to match one of icon color mixins
 * @param className - Whatever extra class user needs for this component
 * @param size - It has to be a number multiple of 8 - 16,32
 * @param focusable - It has to be true or false
 * @param labellledby - It has to be one of the id on styleguide for each icon
 * @param props - rest of the props
 */
const Icon = ({ iconName, fillColor, className, size, focusable, labelledby, ...props }) => (
  <div className={`sg-icon sg-icon--${fillColor} sg-icon--x${size} ${className}`} {...props}>
    <svg className='sg-icon__svg' role='img' aria-labelledby={labelledby} focusable={focusable}>
      <title id={labelledby}>{iconName}</title>
      <use xlinkHref={`#icon-${iconName}`} aria-hidden='true' />
    </svg>
  </div>
);

Icon.propTypes = {
  iconName: PropTypes.string.isRequired,
  fillColor: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  focusable: PropTypes.string,
  labelledby: PropTypes.string,
};

Icon.defaultProps = {
  fillColor: 'gray-light',
  className: '',
  size: 32,
  focusable: 'false',
  labelledby: '',
};

export default Icon;
