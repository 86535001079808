import { CALL_API } from 'store/middlewares/api';
import { ERROR_CODE } from 'utils/error';

export const actionTypes = {
  USER_FEED_PRIVILEGES_GET_PRIVILEGES: 'USER_FEED_PRIVILEGES_GET_PRIVILEGES',
  USER_FEED_PRIVILEGES_GET_PRIVILEGES_REQUEST: 'USER_FEED_PRIVILEGES_GET_PRIVILEGES_REQUEST',
  USER_FEED_PRIVILEGES_GET_PRIVILEGES_SUCCESS: 'USER_FEED_PRIVILEGES_GET_PRIVILEGES_SUCCESS',
  USER_FEED_PRIVILEGES_GET_PRIVILEGES_FAILURE: 'USER_FEED_PRIVILEGES_GET_PRIVILEGES_FAILURE',

  USER_EVAL_FEED_PRIVILEGES_FETCH: 'USER_EVAL_FEED_PRIVILEGES_FETCH',
  USER_EVAL_FEED_PRIVILEGES_FETCH_REQUEST: 'USER_EVAL_FEED_PRIVILEGES_FETCH_REQUEST',
  USER_EVAL_FEED_PRIVILEGES_FETCH_SUCCESS: 'USER_EVAL_FEED_PRIVILEGES_FETCH_SUCCESS',
  USER_EVAL_FEED_PRIVILEGES_FETCH_FAILURE: 'USER_EVAL_FEED_PRIVILEGES_FETCH_FAILURE',
};

export const getUserFeedPrivileges = () => ({
  [CALL_API]: {
    type: actionTypes.USER_FEED_PRIVILEGES_GET_PRIVILEGES,
    payload: {
      endpoint: `/auth/me/feeds/privileges`,
      method: 'get',
    },
    meta: {},
  },
});

export const getUserEvaluationPrivileges = () => ({
  [CALL_API]: {
    type: actionTypes.USER_EVAL_FEED_PRIVILEGES_FETCH,
    payload: {
      endpoint: `/auth/me/evaluation/privileges`,
      method: 'get',
    },
    meta: {
      errorToastMessageConfig: {
        [ERROR_CODE.UNAUTHORIZED]: {
          default: 'evaluationPrivilegesUnauthorized',
        },
        [ERROR_CODE.FORBIDDEN]: { default: 'evaluationPrivilegesForbidden' },
      },
    },
  },
});
