import { CALL_API } from 'store/middlewares/api';
import { ERROR_CODE } from 'utils/error';
import { getApiMarket } from 'store/utils';

import {
  getAnswerGradesIdsFilter,
  getAnswerId,
  getAnswerSubjectsIdsFilter,
  getCurrentFeed,
  getCurrentScore,
  getEndCursor,
  getQuestionId,
} from './selectors';

export const actionTypes = {
  EVALUATE_ANSWER_SET_ANSWER_FILTERS: 'EVALUATE_ANSWER_SET_ANSWER_FILTERS',
  EVALUATE_ANSWER_SET_CURRENT_FEED: 'EVALUATE_ANSWER_SET_CURRENT_FEED',
  EVALUATE_ANSWER_SET_CURRENT_SCORE: 'EVALUATE_ANSWER_SET_CURRENT_SCORE',

  EVALUATE_ANSWERS_GET_DATA: 'EVALUATE_ANSWERS_GET_DATA',
  EVALUATE_ANSWERS_GET_DATA_REQUEST: 'EVALUATE_ANSWERS_GET_DATA_REQUEST',
  EVALUATE_ANSWERS_GET_DATA_SUCCESS: 'EVALUATE_ANSWERS_GET_DATA_SUCCESS',
  EVALUATE_ANSWERS_GET_DATA_FAILURE: 'EVALUATE_ANSWERS_GET_DATA_FAILURE',

  EVALUATE_ANSWERS_SKIP: 'EVALUATE_ANSWERS_SKIP',
  EVALUATE_ANSWERS_SKIP_REQUEST: 'EVALUATE_ANSWERS_SKIP_REQUEST',
  EVALUATE_ANSWERS_SKIP_SUCCESS: 'EVALUATE_ANSWERS_SKIP_SUCCESS',
  EVALUATE_ANSWERS_SKIP_FAILURE: 'EVALUATE_ANSWERS_SKIP_FAILURE',

  EVALUATE_ANSWERS_RATE: 'EVALUATE_ANSWERS_RATE',
  EVALUATE_ANSWERS_RATE_REQUEST: 'EVALUATE_ANSWERS_RATE_REQUEST',
  EVALUATE_ANSWERS_RATE_SUCCESS: 'EVALUATE_ANSWERS_RATE_SUCCESS',
  EVALUATE_ANSWERS_RATE_FAILURE: 'EVALUATE_ANSWERS_RATE_FAILURE',

  EVALUATE_ANSWERS_MARK_AS_FAULTY: 'EVALUATE_ANSWERS_MARK_AS_FAULTY',
  EVALUATE_ANSWERS_MARK_AS_FAULTY_REQUEST: 'EVALUATE_ANSWERS_MARK_AS_FAULTY_REQUEST',
  EVALUATE_ANSWERS_MARK_AS_FAULTY_SUCCESS: 'EVALUATE_ANSWERS_MARK_AS_FAULTY_SUCCESS',
  EVALUATE_ANSWERS_MARK_AS_FAULTY_FAILURE: 'EVALUATE_ANSWERS_MARK_AS_FAULTY_FAILURE',

  FREELANCER_EVALUATION_CHECKPOINTS_FETCH: 'FREELANCER_EVALUATION_CHECKPOINTS_FETCH',
  FREELANCER_EVALUATION_CHECKPOINTS_FETCH_REQUEST: 'FREELANCER_EVALUATION_CHECKPOINTS_FETCH_REQUEST',
  FREELANCER_EVALUATION_CHECKPOINTS_FETCH_SUCCESS: 'FREELANCER_EVALUATION_CHECKPOINTS_FETCH_SUCCESS',
  FREELANCER_EVALUATION_CHECKPOINTS_FETCH_FAILURE: 'FREELANCER_EVALUATION_CHECKPOINTS_FETCH_FAILURE',
};

export const setEvaluateAnswerFilters = values => ({
  type: actionTypes.EVALUATE_ANSWER_SET_ANSWER_FILTERS,
  payload: values,
});

export const setCurrentFeed = feedType => ({
  type: actionTypes.EVALUATE_ANSWER_SET_CURRENT_FEED,
  payload: feedType,
});

export const setCurrentScore = score => ({
  type: actionTypes.EVALUATE_ANSWER_SET_CURRENT_SCORE,
  payload: score,
});

export const getAnswerToEvaluate = ({ evaluationFeed, subjectIds, gradeIds, endCursor }) => ({
  [CALL_API]: {
    type: actionTypes.EVALUATE_ANSWERS_GET_DATA,
    payload: {
      endpoint: `/${getApiMarket()}/questions/evaluation/reserve`,
      method: 'get',
      params: {
        evaluationFeed,
        subjectIds,
        gradeIds,
        endCursor,
      },
    },
  },
});

export const skipAnswer = ({ answerId, currentFeed }) => ({
  [CALL_API]: {
    type: actionTypes.EVALUATE_ANSWERS_SKIP,
    payload: {
      endpoint: `/${getApiMarket()}/questions/evaluation/skip`,
      method: 'post',
      answerId,
      currentFeed,
    },
  },
});

export const rateAnswer = ({ answerId, score, currentFeed }) => ({
  [CALL_API]: {
    type: actionTypes.EVALUATE_ANSWERS_RATE,
    payload: {
      endpoint: `/${getApiMarket()}/questions/evaluation/rate`,
      method: 'post',
      answerId,
      score,
      currentFeed,
    },
  },
});

export const markQuestionFaulty = ({ questionId, reason, currentFeed }) => ({
  [CALL_API]: {
    type: actionTypes.EVALUATE_ANSWERS_MARK_AS_FAULTY,
    payload: {
      endpoint: `/${getApiMarket()}/questions/evaluation/faulty`,
      method: 'post',
      questionId,
      reason,
      currentFeed,
    },
  },
});

export const getFreelancerEvaluationCheckpoints = feed => ({
  [CALL_API]: {
    type: actionTypes.FREELANCER_EVALUATION_CHECKPOINTS_FETCH,
    payload: {
      endpoint: `/${getApiMarket()}/evaluation/evaluation-checklist`,
      method: 'get',
      params: {
        feed,
      },
    },
    meta: {
      errorToastMessageConfig: {
        [ERROR_CODE.BAD_REQUEST]: { default: 'defaultBadRequest' },
        [ERROR_CODE.UNAUTHORIZED]: { default: 'defaultUnauthorized' },
        [ERROR_CODE.FORBIDDEN]: { default: 'defaultForbidden' },
        [ERROR_CODE.NOT_FOUND]: { RESOURCE_NOT_FOUND: 'checklistNotFound', default: 'defaultNotFound' },
        [ERROR_CODE.CONFLICT]: { default: 'defaultConflict' },
        [ERROR_CODE.UNPROCESSABLE_ENTITY]: { default: 'defaultUnprocessableEntity' },
      },
    },
  },
});

export const fetchQuestionToEvaluate = () => (dispatch, getState) => {
  const state = getState();
  const subjectIds = getAnswerSubjectsIdsFilter(state);
  const gradeIds = getAnswerGradesIdsFilter(state);
  const currentFeed = getCurrentFeed(state);
  const activeEndCursor = getEndCursor(state);
  return dispatch(
    getAnswerToEvaluate({
      subjectIds,
      gradeIds,
      endCursor: activeEndCursor,
      evaluationFeed: currentFeed,
    }),
  );
};

export const markQuestionAsFaulty = ({ reason }) => (dispatch, getState) => {
  const state = getState();
  const questionId = getQuestionId(state);
  const currentFeed = getCurrentFeed(state);
  return dispatch(markQuestionFaulty({ questionId, reason, currentFeed }));
};

export const skipAnswerToEvaluate = () => async (dispatch, getState) => {
  const state = getState();
  const answerId = getAnswerId(state);
  const currentFeed = getCurrentFeed(state);
  dispatch(skipAnswer({ answerId, currentFeed }));
};

export const rateEvaluatedQuestion = () => async (dispatch, getState) => {
  const state = getState();
  const answerId = getAnswerId(state);
  const currentFeed = getCurrentFeed(state);
  const score = getCurrentScore(state);
  dispatch(rateAnswer({ answerId, score, currentFeed }));
};
