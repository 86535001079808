import { useTranslation } from 'react-i18next';
import React from 'react';

import { Container, Headline } from 'ui';
import { HEADLINE_COLOR, HEADLINE_SIZE } from 'const/styles';

import style from './Error500.module.scss';

const Error500 = () => {
  const { t } = useTranslation('Error500');

  return (
    <Container className={style.container} centered>
      <Headline
        value='#500'
        size={HEADLINE_SIZE.LARGE}
        className={style.headline}
        color={HEADLINE_COLOR.TEXT_BLUE_40}
        textBit
      />
      <Headline value={t('header')} size={HEADLINE_SIZE.LARGE} className={style.headline} />
      <div>
        <span className='sg-text sg-text--small sg-text--bold'>{t('instruction')}</span>
      </div>
    </Container>
  );
};

export default Error500;
