/* eslint-disable react/no-array-index-key,react/jsx-filename-extension */
import React from 'react';
import TableCell from './TableCell';
import TableRow from './TableRow';

const defaultCell = { content: <div />, className: null };
const isCellObject = _cell => typeof _cell === 'object' && _cell.content != null;

export const renderRows = (rows, rowClassName, cellClassName) =>
  rows.map((row, rowIndex) => (
    <TableRow row={row} cellClassName={cellClassName} key={rowIndex} className={rowClassName} />
  ));

export const renderCells = (row, isHeader, cellClassName) =>
  row.map((_cell = defaultCell, cellIndex) => {
    if (isCellObject(_cell)) {
      return <TableCell key={cellIndex} content={_cell.content} isHeader={isHeader} className={_cell.className} />;
    }

    return <TableCell key={cellIndex} content={_cell} isHeader={isHeader} className={cellClassName} />;
  });
