import { useSelector } from 'react-redux';
import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';

import { getShouldCurrentUserSetupAccount } from 'store/auth/selectors';

import { accountSetupPath, mainPagePath } from 'const/routing';

import style from './Logo.module.scss';

const Logo = ({ className }) => {
  const shouldSetupAccount = useSelector(getShouldCurrentUserSetupAccount);

  let dynamicHref = '/';

  shouldSetupAccount ? (dynamicHref = accountSetupPath) : (dynamicHref = mainPagePath);

  return (
    <a href={dynamicHref} className={cx(style.logoContainer, className)}>
      <img
        className={`sg-home-button__logo ${style.logoDesktop}`}
        src='https://styleguide.brainly.com/images/logos/brainly-761d75d6ea.svg'
        alt='Logo'
      />
      <img
        className={`sg-home-button__logo ${style.logoMobile}`}
        src='https://styleguide.brainly.pl/images/logos/brainly-mobile-6879551770.svg'
        alt='Logo'
      />
    </a>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
};
Logo.defaultProps = {
  className: null,
};
export default Logo;
