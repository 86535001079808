import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { childrenPropType } from 'utils/propTypes';
import TabButton from './TabButton';
import styles from './Tabs.module.scss';

const getFirstTabId = tabs => (tabs && tabs[0] && tabs[0].id) || '';
const getTabsButtons = ({ tabs, handleTabChange, selectedTabId }) =>
  tabs.map(({ header, id, color }) => (
    <TabButton
      onClick={() => handleTabChange(id)}
      key={id || header}
      header={header}
      isActive={selectedTabId === id}
      color={color}
    />
  ));
const getCurrentTabContent = ({ tabs, selectedTabId }) =>
  tabs.find(tab => tab.id === selectedTabId) || { content: undefined };

const Tabs = ({ tabs, onChange }) => {
  const [selectedTabId, setSelectedTabId] = useState(getFirstTabId(tabs));
  const handleTabChange = data => {
    onChange(data);
    setSelectedTabId(data);
  };
  return (
    <div>
      <div className={styles.buttonsWrapper}>{getTabsButtons({ tabs, handleTabChange, selectedTabId })}</div>
      {getCurrentTabContent({ tabs, selectedTabId }).content}
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      header: childrenPropType,
      content: childrenPropType,
    }),
  ).isRequired,
  onChange: PropTypes.func,
};

Tabs.defaultProps = {
  onChange: () => {},
};

export default Tabs;

export { tabThemes } from './TabButton';
