import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import { LABEL_TYPE, TEXT_TYPE } from 'const/styles';
import { Label } from 'ui/index';

import style from './Chips.module.scss';

const Chips = ({ name, label, info, className, onChange, width, value, required, placeholder, chipValidators }) => {
  const styles = {
    placeholder: provided => ({
      ...provided,
      color: style.fontColor,
      fontWeight: 'bold',
    }),
    control: (provided, { isFocused }) => ({
      ...provided,
      minHeight: 36,
      borderRadius: 10,
      width,
      boxShadow: isFocused ? '0 3px 16px 0 rgba(2,10,27,0.15)' : 0,
      border: `2px solid ${isFocused ? 'transparent' : style.borderColor}`,
      ':hover': {
        border: `2px solid ${isFocused ? 'transparent' : style.borderColor}`,
      },
    }),
    menu: provided => ({
      ...provided,
      display: 'none',
    }),
    multiValue: provided => ({
      ...provided,
      borderRadius: 20,
      padding: '0 10px',
    }),
    multiValueRemove: provided => ({
      ...provided,
      margin: 'auto',
      padding: 0,
    }),
  };

  const classNames = cx({
    [style.chips]: true,
    [className]: !!className,
  });

  const components = {
    DropdownIndicator: null,
    ClearIndicator: null,
  };

  const trimValuesOnChange = values => {
    if (!values) {
      return onChange([]);
    }

    const trimmedValues = values.map(({ label: l, value: v }) => ({
      label: l.trim(),
      value: v.trim(),
    }));
    return onChange(trimmedValues);
  };

  const isValidNewOption = (inputValue, selectValue) => {
    const alreadySelectedValues = selectValue.map(({ value: v }) => v);
    return chipValidators.every(validator => validator(inputValue)) && !alreadySelectedValues.includes(inputValue);
  };

  return (
    <>
      {label && (
        <Label
          type={LABEL_TYPE.TRANSPARENT}
          labelText={`${TEXT_TYPE.TEXT_BLACK},${TEXT_TYPE.SMALL},${TEXT_TYPE.BOLD}`}
          isRequired={required}
        >
          {label}
        </Label>
      )}
      <CreatableSelect
        className={classNames}
        value={value}
        isMulti
        name={name}
        styles={styles}
        placeholder={placeholder}
        onChange={trimValuesOnChange}
        components={components}
        isValidNewOption={isValidNewOption}
      />
      {info && (
        <Label className={style.disclaimer} labelText={`${TEXT_TYPE.TEXT_BLACK},${TEXT_TYPE.SMALL},${TEXT_TYPE.BOLD}`}>
          {info}
        </Label>
      )}
      <div className={style.spaceBottom} />
    </>
  );
};

Chips.propTypes = {
  className: PropTypes.string,
  chipValidators: PropTypes.array,
  label: PropTypes.string,
  info: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.array,
  width: PropTypes.string,
};

Chips.defaultProps = {
  className: null,
  chipValidators: [],
  label: null,
  info: null,
  placeholder: null,
  required: false,
  width: '100%',
  value: [],
};

export default Chips;
