import { USER_ROLE } from 'const/roles';
import { accountSetupPath, freelancerRoutes, profilePath } from 'const/routing';
import { logout } from 'store/auth/actions';

export const getDropdownMenuOptions = ({ dispatch, t, history }, shouldSetupAccount) => {
  const onProfileClick = () => {
    if (shouldSetupAccount) {
      history.push(accountSetupPath);
    } else {
      history.push(profilePath);
    }
  };
  const onLogoutClick = async () => {
    await dispatch(logout());
    history.push('/');
  };
  const onEvaluateAnswerClick = () => {
    if (shouldSetupAccount) {
      history.push(accountSetupPath);
    } else {
      history.push(freelancerRoutes.evaluateAnswerPath);
    }
  };
  const onAnsweringQuestionClick = () => {
    if (shouldSetupAccount) {
      history.push(accountSetupPath);
    } else {
      history.push(freelancerRoutes.answeringPath);
    }
  };
  const adminOptions = [
    {
      onClick: onProfileClick,
      value: t('profile'),
    },
    {
      onClick: onLogoutClick,
      value: t('logout'),
    },
  ];
  const freelancerOptions = [
    {
      onClick: onProfileClick,
      value: t('profile'),
    },
    {
      onClick: onEvaluateAnswerClick,
      value: t('evaluateAnswers'),
    },
    {
      onClick: onAnsweringQuestionClick,
      value: t('answeringQuestions'),
    },
    {
      onClick: onLogoutClick,
      value: t('logout'),
    },
  ];

  return {
    [USER_ROLE.ADMIN]: adminOptions,
    [USER_ROLE.ANSWERING_GROUP_ADMIN]: adminOptions,
    [USER_ROLE.FREELANCER]: freelancerOptions,
  };
};
