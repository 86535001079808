import PropTypes from 'prop-types';
import React from 'react';

const Checkbox = ({ type, onChange, value, checked, label, textClass, id }) => {
  return (
    <div className='sg-space-x-m'>
      <label className='sg-checkbox' htmlFor={id}>
        <input
          className='sg-checkbox__element'
          id={id}
          type={type}
          onChange={onChange}
          value={value}
          checked={checked}
        />
        <div className='sg-checkbox__ghost' aria-hidden='true'>
          <div className='sg-icon sg-icon--adaptive sg-icon--x16'>
            <svg className='sg-icon__svg' role='img' aria-labelledby='title-check-etkzb' focusable='false'>
              <title id='title-check-etkzb'>check</title>
              <use xlinkHref='#icon-check' aria-hidden='true' />
            </svg>
          </div>
        </div>
        <span className={`sg-text sg-text--small sg-text--bold sg-checkbox__label ${textClass}`}>{label}</span>
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  value: PropTypes.bool,
  label: PropTypes.string,
  textClass: PropTypes.string,
  id: PropTypes.string,
};

Checkbox.defaultProps = {
  checked: false,
  value: false,
  type: 'checkbox',
  label: null,
  textClass: '',
  id: 'default-id',
};

export default Checkbox;
