import { onInitState, successState } from 'store/consts';

import { actionTypes } from './actions';

const formatGrades = grades => grades.map(({ gradeId, gradeName }) => ({ value: gradeId, label: gradeName }));
const formatSubjects = subjects => subjects.map(({ id, name }) => ({ value: id, label: name }));

const initialState = {
  grades: [],
  subjects: [],
  projects: [],
  loading: false,
  fulfilled: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FILTERS_GET_GRADES_REQUEST:
    case actionTypes.FILTERS_GET_SUBJECTS_REQUEST:
    case actionTypes.FILTERS_GET_PROJECTS_REQUEST:
      return { ...state, ...onInitState };
    case actionTypes.FILTERS_GET_GRADES_SUCCESS:
      return { ...state, ...successState, grades: formatGrades(action.payload.grades) };
    case actionTypes.FILTERS_GET_SUBJECTS_SUCCESS:
      return { ...state, ...successState, subjects: formatSubjects(action.payload.subjects) };
    case actionTypes.FILTERS_GET_PROJECTS_SUCCESS:
      return { ...state, ...successState, projects: formatSubjects(action.payload.projects) };
    case actionTypes.FILTERS_GET_GRADES_FAILURE:
    case actionTypes.FILTERS_GET_SUBJECTS_FAILURE:
    case actionTypes.FILTERS_GET_PROJECTS_FAILURE:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
