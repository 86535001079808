import { FEED_TYPE } from 'const/feeds';
import { loadingState, onInitState, successState } from 'store/consts';

import { actionTypes } from './actions';

const handleQuestionsInit = ({ state, payload, feedType }) => {
  // Check if user switches between tabs before data loads
  const newState =
    state.feedType !== feedType
      ? state
      : {
        ...state,
        ...successState,
        questions: payload.questions,
        totalCount: payload.totalCount,
        offset: payload.questions.length,
        newQuestions: 0,
      };

  return newState;
};

const filterDeletedQuestion = ({ questions, questionIdToDelete }) =>
  questions.filter(({ questionId }) => questionId !== questionIdToDelete);

const reserveQuestion = ({ questions, questionIdToUpdate }) =>
  questions.map(question => {
    const questionData = question.questionId === questionIdToUpdate ? { ...question, reserved: true } : question;
    return questionData;
  });

const unreserveQuestion = ({ questions, questionIdToUpdate }) =>
  questions.map(question => {
    return question.questionId === questionIdToUpdate ? { ...question, reserved: false } : question;
  });

const filterSubjectQuestions = ({ questions, subjectId, projectId }) =>
  questions.filter(question => subjectId !== question.subject.id || projectId !== question.projectId);

const initialState = {
  questions: [],
  skippedQuestions: {
    [FEED_TYPE.TSR]: [],
    [FEED_TYPE.QI]: [],
    [FEED_TYPE.FAST_ANSWERING]: [],
  },
  activeFilters: {},
  offset: 0,
  totalCount: 0,
  newQuestions: 0,
  feedType: null,
  loading: false,
  fulfilled: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.QUESTIONS_FEED_TSR_INIT_FETCH_REQUEST:
      return { ...state, ...onInitState, feedType: FEED_TYPE.TSR };
    case actionTypes.QUESTIONS_FEED_FA_INIT_FETCH_REQUEST:
      return { ...state, ...onInitState, feedType: FEED_TYPE.FAST_ANSWERING };
    case actionTypes.QUESTIONS_FEED_QI_INIT_FETCH_REQUEST:
      return { ...state, ...onInitState, feedType: FEED_TYPE.QI };
    case actionTypes.QUESTIONS_FEED_TSR_INIT_FETCH_SUCCESS:
      return handleQuestionsInit({ state, payload: action.payload, feedType: FEED_TYPE.TSR });
    case actionTypes.QUESTIONS_FEED_FA_INIT_FETCH_SUCCESS:
      return handleQuestionsInit({ state, payload: action.payload, feedType: FEED_TYPE.FAST_ANSWERING });
    case actionTypes.QUESTIONS_FEED_QI_INIT_FETCH_SUCCESS:
      return handleQuestionsInit({ state, payload: action.payload, feedType: FEED_TYPE.QI });
    case actionTypes.QUESTIONS_FEED_ADD_SKIPPED_QUESTIONS: {
      const { skippedQuestionsIds, feedType } = action.payload;
      return {
        ...state,
        skippedQuestions: {
          ...state.skippedQuestions,
          [feedType]: [...state.skippedQuestions[feedType], ...skippedQuestionsIds],
        },
      };
    }
    case actionTypes.QUESTIONS_FEED_CLEAR_SKIPPED_QUESTIONS:
      return { ...state, skippedQuestions: { ...state.skippedQuestions, [action.payload]: [] } };
    case actionTypes.QUESTIONS_FEED_LOAD_MORE_REQUEST:
      return { ...state, ...loadingState };
    case actionTypes.QUESTIONS_FEED_LOAD_MORE_SUCCESS:
      return {
        ...state,
        ...successState,
        questions: [...state.questions, ...action.payload.questions],
        offset: state.offset + action.payload.questions.length,
        totalCount: action.payload.totalCount,
      };
    case actionTypes.QUESTIONS_FEED_SET_ACTIVE_FILTERS:
      return { ...state, activeFilters: action.payload };
    case actionTypes.QUESTIONS_FEED_CLEAR_ACTIVE_FILTERS:
      return { ...state, activeFilters: {} };
    case actionTypes.QUESTIONS_FEED_SET_NEW_QUESTIONS_AMOUNT:
      return { ...state, newQuestions: action.payload };
    case actionTypes.QUESTIONS_FEED_DELETE_QUESTION_BY_ID:
      return {
        ...state,
        questions: filterDeletedQuestion({ questions: state.questions, questionIdToDelete: action.payload }),
      };
    case actionTypes.QUESTIONS_FEED_QUESTION_RESERVED:
      return {
        ...state,
        questions: reserveQuestion({ questions: state.questions, questionIdToUpdate: action.payload }),
      };
    case actionTypes.QUESTIONS_FEED_QUESTION_UNRESERVED:
      return {
        ...state,
        questions: unreserveQuestion({ questions: state.questions, questionIdToUpdate: action.payload }),
      };
    case actionTypes.QUESTIONS_FEED_CLEAR_SUBJECT_QUESTIONS:
      return {
        ...state,
        questions: filterSubjectQuestions({ questions: state.questions, ...action.payload }),
      };
    case actionTypes.QUESTIONS_FEED_CLEAR_REDUCER:
      return initialState;
    default:
      return state;
  }
};
