import moment from 'moment';

export const addWeekToDate = date =>
  moment(date)
    .utc()
    .add(1, 'week')
    .format();

export const subtractWeekFromDate = date =>
  moment(date)
    .utc()
    .subtract(1, 'week')
    .format();

export const getDateOfFirstDayOfCurrentWeek = moment()
  .utc()
  .startOf('isoWeek')
  .format();
export const getDateOfLastDayOfCurrentWeek = moment()
  .utc()
  .endOf('isoWeek')
  .format();
