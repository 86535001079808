import { CALL_API } from 'store/middlewares/api';
import { ERROR_CODE } from 'utils/error';
import { getApiMarket } from 'store/utils';

export const actionTypes = {
  WORKER_LOGS_FETCH: 'WORKER_LOGS_FETCH',
  WORKER_LOGS_FETCH_REQUEST: 'WORKER_LOGS_FETCH_REQUEST',
  WORKER_LOGS_FETCH_SUCCESS: 'WORKER_LOGS_FETCH_SUCCESS',
  WORKER_LOGS_FETCH_FAILURE: 'WORKER_LOGS_FETCH_FAILURE',

  WORKER_LOGS_SET_FILTERS: 'WORKER_LOGS_SET_FILTERS',
};

export const getWorkerLogs = () => (dispatch, getState) => {
  const {
    workerLogs: {
      selectedFilters: { date, projectId, feed },
    },
  } = getState();

  dispatch({
    [CALL_API]: {
      type: actionTypes.WORKER_LOGS_FETCH,
      payload: {
        endpoint: `/${getApiMarket()}/worker-history/${feed}`,
        method: 'get',
        params: {
          date,
          projectId,
        },
      },
      meta: {
        errorToastMessageConfig: {
          [ERROR_CODE.BAD_REQUEST]: { default: 'limitsBadRequest' },
          [ERROR_CODE.FORBIDDEN]: { default: 'limitsForbidden' },
        },
      },
    },
  });
};

export const setWorkerLogsFilters = filters => ({
  type: actionTypes.WORKER_LOGS_SET_FILTERS,
  payload: filters,
});
