import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import { LABEL_TYPE, SELECT_TYPE, TEXT_TYPE } from 'const/styles';
import { Label } from 'ui';

import style from './Select.module.scss';

const Select = ({
  className,
  selectClassName,
  type,
  label,
  options,
  required,
  onChange,
  value,
  error,
  placeholder,
}) => {
  const classNames = cx(style.select, {
    'sg-select': true,
    'sg-select--selected': true,
    'sg-select--invalid': error,
    [`sg-select--${type}`]: !!type,
    [className]: !!className,
  });

  const selectClassNames = cx({
    'sg-select__element': true,
    [selectClassName]: !!selectClassName,
  });

  /**
   * Options can be string[] or { value, label }[]
   */
  const optionsElements = options.map(option => {
    const { value: optionValue, label: optionLabel } =
      typeof option === 'string' ? { value: option, label: option } : option;

    return (
      <option value={optionValue} key={optionValue}>
        {optionLabel}
      </option>
    );
  });

  return (
    <>
      {label && (
        <Label
          type={LABEL_TYPE.TRANSPARENT}
          labelText={`${TEXT_TYPE.TEXT_BLACK},${TEXT_TYPE.SMALL},${TEXT_TYPE.BOLD}`}
          isRequired={required}
        >
          {label}
        </Label>
      )}
      <div className={classNames}>
        <div className='sg-select__icon'>
          <div className='sg-icon sg-icon--icon-gray-50 sg-icon--x16'>
            <svg className='sg-icon__svg' role='img' aria-labelledby='title-arrow_down-zda3nj' focusable='false'>
              <title id='title-arrow_down-zda3nj'>arrow down</title>
              <use xlinkHref='#icon-arrow_down' aria-hidden='true' />
            </svg>
          </div>
        </div>
        <select className={selectClassNames} value={value} onChange={onChange}>
          <If condition={placeholder}>
            <option value='' disabled>
              {placeholder}
            </option>
          </If>
          {optionsElements && optionsElements}
        </select>
      </div>
    </>
  );
};

Select.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array.isRequired,
  selectClassName: PropTypes.string,
  type: PropTypes.oneOf(Object.values(SELECT_TYPE)),
  value: PropTypes.string,
};

Select.defaultProps = {
  className: null,
  error: false,
  label: null,
  placeholder: null,
  required: false,
  selectClassName: null,
  type: SELECT_TYPE.DEFAULT,
  value: '',
};

export default Select;
