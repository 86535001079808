import { useTabState } from '@bumaga/tabs';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import cx from 'classnames';

import { BUTTON_TYPE, TAB_COLOR } from 'const/styles';
import Button from 'ui/Button/Button';

import Tooltip from 'ui/Tooltip/Tooltip';
import style from './Tab.module.scss';

const Tab = ({ value, className, color, currentLimit, maxLimit, faultyValue }) => {
  const { onClick, isActive } = useTabState();
  const { t } = useTranslation('Tab');

  const classNames = cx({
    [style.tab]: true,
    [style[`tabActive${color}`]]: isActive,
    [style.tabDisabled]: !isActive,
    [className]: !!className,
  });

  const onTabClick = useCallback(
    event => {
      event.currentTarget.blur(); // removes the grey outline after click, keeps accessibility
      onClick();
    },
    [onClick],
  );

  const header = (
    <div className={style.tabHeader}>
      <span>{value}</span>
      <Tooltip tooltipText={t('headerTooltip')}>
        <span>
          {currentLimit}/{faultyValue}/{maxLimit}
        </span>
      </Tooltip>
    </div>
  );

  return (
    <Button
      onClick={onTabClick}
      className={classNames}
      type={BUTTON_TYPE.TRANSPARENT}
      value={header}
      buttonTextClassName={style.buttonText}
    />
  );
};

Tab.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.values(TAB_COLOR)).isRequired,
  value: PropTypes.string.isRequired,
  currentLimit: PropTypes.number,
  maxLimit: PropTypes.number,
  faultyValue: PropTypes.number,
};

Tab.defaultProps = {
  className: null,
  currentLimit: 0,
  maxLimit: 0,
  faultyValue: 0,
};

export default Tab;
