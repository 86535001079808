import { CALL_API } from 'store/middlewares/api';
import { ERROR_CODE } from 'utils/error';
import { ERROR_TYPE } from 'const/errorTypes';
import { getApiMarket } from 'store/utils';

export const actionTypes = {
  PROJECTS_FETCH: 'PROJECTS_FETCH',
  PROJECTS_FETCH_REQUEST: 'PROJECTS_FETCH_REQUEST',
  PROJECTS_FETCH_SUCCESS: 'PROJECTS_FETCH_SUCCESS',
  PROJECTS_FETCH_FAILURE: 'PROJECTS_FETCH_FAILURE',

  PROJECTS_CREATE_PROJECT: 'PROJECTS_CREATE_PROJECT',
  PROJECTS_CREATE_PROJECT_REQUEST: 'PROJECTS_CREATE_PROJECT_REQUEST',
  PROJECTS_CREATE_PROJECT_SUCCESS: 'PROJECTS_CREATE_PROJECT_SUCCESS',
  PROJECTS_CREATE_PROJECT_FAILURE: 'PROJECTS_CREATE_PROJECT_FAILURE',

  PROJECTS_DISABLE_PROJECT: 'PROJECTS_DISABLE_PROJECT',
  PROJECTS_DISABLE_PROJECT_REQUEST: 'PROJECTS_DISABLE_PROJECT_REQUEST',
  PROJECTS_DISABLE_PROJECT_SUCCESS: 'PROJECTS_DISABLE_PROJECT_SUCCESS',
  PROJECTS_DISABLE_PROJECT_FAILURE: 'PROJECTS_DISABLE_PROJECT_FAILURE',

  PROJECTS_ENABLE_PROJECT: 'PROJECTS_ENABLE_PROJECT',
  PROJECTS_ENABLE_PROJECT_REQUEST: 'PROJECTS_ENABLE_PROJECT_REQUEST',
  PROJECTS_ENABLE_PROJECT_SUCCESS: 'PROJECTS_ENABLE_PROJECT_SUCCESS',
  PROJECTS_ENABLE_PROJECT_FAILURE: 'PROJECTS_ENABLE_PROJECT_FAILURE',

  PROJECTS_UPDATE_PROJECT: 'PROJECTS_UPDATE_PROJECT',
  PROJECTS_UPDATE_PROJECT_REQUEST: 'PROJECTS_UPDATE_PROJECT_REQUEST',
  PROJECTS_UPDATE_PROJECT_SUCCESS: 'PROJECTS_UPDATE_PROJECT_SUCCESS',
  PROJECTS_UPDATE_PROJECT_FAILURE: 'PROJECTS_UPDATE_PROJECT_FAILURE',
};

export const getProjects = () => ({
  [CALL_API]: {
    type: actionTypes.PROJECTS_FETCH,
    payload: {
      endpoint: `/${getApiMarket()}/projects`,
      method: 'get',
    },
    meta: {},
  },
});

export const createProject = project => ({
  [CALL_API]: {
    type: actionTypes.PROJECTS_CREATE_PROJECT,
    payload: {
      endpoint: `/${getApiMarket()}/projects`,
      method: 'post',
      ...project,
    },
    meta: {
      successToastMessage: 'projectCreated',
      errorToastMessageConfig: {
        [ERROR_CODE.CONFLICT]: {
          [ERROR_TYPE.PROJECT_PHRASE_ALREADY_IN_USE]: 'projectPhraseAlreadyInUse',
          [ERROR_TYPE.PROJECT_ALREADY_EXISTS]: 'projectExists',
        },
        [ERROR_CODE.UNPROCESSABLE_ENTITY]: {
          default: 'createValidationError',
        },
      },
    },
  },
});

export const disableProject = id => ({
  [CALL_API]: {
    type: actionTypes.PROJECTS_DISABLE_PROJECT,
    payload: {
      endpoint: `/${getApiMarket()}/projects/${id}/disable`,
      method: 'post',
    },
    meta: {
      successToastMessage: 'projectDisabled',
    },
  },
});

export const enableProject = id => ({
  [CALL_API]: {
    type: actionTypes.PROJECTS_ENABLE_PROJECT,
    payload: {
      endpoint: `/${getApiMarket()}/projects/${id}/enable`,
      method: 'post',
    },
    meta: {
      successToastMessage: 'projectEnabled',
    },
  },
});

export const updateProject = (id, project) => ({
  [CALL_API]: {
    type: actionTypes.PROJECTS_UPDATE_PROJECT,
    payload: {
      endpoint: `/${getApiMarket()}/projects/${id}`,
      method: 'put',
      ...project,
    },
    meta: {
      successToastMessage: 'projectUpdated',
      errorToastMessageConfig: {
        [ERROR_CODE.CONFLICT]: {
          [ERROR_TYPE.PROJECT_PHRASE_ALREADY_IN_USE]: 'projectPhraseAlreadyInUse',
          [ERROR_TYPE.PROJECT_ALREADY_EXISTS]: 'projectExists',
        },
        [ERROR_CODE.UNPROCESSABLE_ENTITY]: { default: 'updateValidationError' },
        [ERROR_CODE.FORBIDDEN]: { default: 'updateValidationError' },
      },
    },
  },
});
