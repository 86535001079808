/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Dropdown } from 'ui/index';
import { getCurrentUserRole, getShouldCurrentUserSetupAccount } from 'store/auth/selectors';
import { getDropdownMenuOptions } from './getDropdownMenuOptions';

import style from './TopBar.module.scss';

const AccountInfo = ({ email }) => {
  const { t } = useTranslation('AccountInfo');
  const dispatch = useDispatch();
  const history = useHistory();
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const onDropdownClick = () => setIsDropdownOpened(!isDropdownOpened);
  const onOutsideDropdownClick = () => setIsDropdownOpened(false);
  const userRole = useSelector(getCurrentUserRole);

  const shouldSetupAccount = useSelector(getShouldCurrentUserSetupAccount);

  const dropdownItems = getDropdownMenuOptions({ dispatch, t, history }, shouldSetupAccount)[userRole];

  return (
    <div className='sg-header__right'>
      <span className={style.email}>{email.toUpperCase()}</span>
      <div className='sg-avatar' style={{ marginRight: 10 }}>
        <div className='sg-avatar__image sg-avatar__image--icon'>
          <div className='sg-icon sg-icon--gray-light sg-icon--x32'>
            <svg className='sg-icon__svg'>
              <use xlinkHref='#icon-profile' />
            </svg>
          </div>
        </div>
      </div>
      <OutsideClickHandler onOutsideClick={onOutsideDropdownClick}>
        <div className={style.dropdownContainer} onClick={onDropdownClick}>
          <div className={isDropdownOpened ? style.dropdownOpened : style.dropdownClosed} />
          <If condition={isDropdownOpened}>
            <Dropdown
              opened={isDropdownOpened}
              className={style.sgDropdown}
              itemsClassName={style.sgDropdownItems}
              items={dropdownItems}
            />
          </If>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

AccountInfo.propTypes = {
  email: PropTypes.string,
};

AccountInfo.defaultProps = {
  email: '',
};

export default AccountInfo;
