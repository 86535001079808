import Cookies from 'js-cookie';

const { NODE_ENV } = process.env;
const isProduction = NODE_ENV === 'production';

const COOKIES_KEYS = {
  AUTH_TOKEN: 'token',
  REFRESH_TOKEN: 'refresh',
};

Cookies.defaults.secure = isProduction;

/**
 * @returns {TokenInterface}
 */
export const getAuthToken = () => Cookies.get(COOKIES_KEYS.AUTH_TOKEN);
const setAuthToken = (token, expirationTime) =>
  Cookies.set(COOKIES_KEYS.AUTH_TOKEN, token, { expires: new Date(expirationTime * 1000) });

/**
 * @returns {TokenInterface}
 */
export const getRefreshToken = () => Cookies.get(COOKIES_KEYS.REFRESH_TOKEN);
const setRefreshToken = (token, expirationTime) =>
  Cookies.set(COOKIES_KEYS.REFRESH_TOKEN, token, { expires: new Date(expirationTime * 1000) });

/**
 * @param {SessionInterface} session
 */
export const setSessionCookies = session => {
  const { accessToken, refreshToken } = session;
  setAuthToken(accessToken.value, accessToken.expirationTime);
  setRefreshToken(refreshToken.value, refreshToken.expirationTime);
};

export const removeSessionCookies = () => {
  Cookies.remove(COOKIES_KEYS.AUTH_TOKEN);
  Cookies.remove(COOKIES_KEYS.REFRESH_TOKEN);
};
