import { actionTypes } from './actions';

const initialState = {
  status: 'enabled',
  roles: [],
  answerGroupIds: [],
};

const { SET_USERS_FILTERS } = actionTypes;

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS_FILTERS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
