import React from 'react';

import { childrenPropType } from 'utils/propTypes';
import Portal from './Portal';

const NotificationsPortal = ({ children }) => (
  <Portal portalRoot={document.getElementById('notifications-root')}>{children}</Portal>
);

NotificationsPortal.propTypes = {
  children: childrenPropType.isRequired,
};

export default NotificationsPortal;
