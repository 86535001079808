import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import { childrenPropType } from 'utils/propTypes';

import style from './Box.module.scss';

const Box = ({ children, className }) => <div className={cx(style.box, className && className)}>{children}</div>;

Box.propTypes = {
  children: childrenPropType.isRequired,
  className: PropTypes.string,
};

Box.defaultProps = {
  className: '',
};

export default Box;
