import { Redirect, Route, Switch } from 'react-router-dom';
import React, { lazy } from 'react';

import { adminRoutes } from 'const/routing';

const AdminPanel = lazy(() => import('./AdminPanel/AdminPanel'));
const ProfilePage = lazy(() => import('screens/Profile/ProfilePage'));

const AdminRoutes = () => {
  return (
    <Switch>
      <Route path={adminRoutes.adminBasePath} component={AdminPanel} />
      <Route exact path={adminRoutes.profilePath} component={ProfilePage} />
      <Route exact path='/' component={() => <Redirect to={adminRoutes.userListPath} />} />
    </Switch>
  );
};

export default AdminRoutes;
