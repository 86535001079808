import PropTypes from 'prop-types';
import React from 'react';

import Button from 'ui/Button/Button';
import Modal from 'portals/ModalPortal/ModalPortal';

import { BUTTON_TYPE, MODAL_SIZE } from 'const/styles';

import style from './AcceptModal.module.scss';

const AcceptModal = ({
  isOpen,
  onClose,
  handleAccept,
  acceptButtonText,
  cancelButtonText,
  popUpQuestion,
  popUpMessage,
  size,
  modalWrapperClass,
}) => {
  const handleButtonAccept = () => {
    handleAccept();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} size={size} customClass={modalWrapperClass}>
      <div className={`sg-text sg-text--container ${style.modalMessage}`}>
        <div className={style.question}>{popUpQuestion}</div>
        <div className={style.message}>{popUpMessage}</div>
        <div className={style.buttonContainer}>
          <Button className={style.button} value={cancelButtonText} onClick={onClose} type={BUTTON_TYPE.OUTLINE} />
          <Button
            className={style.button}
            value={acceptButtonText}
            onClick={handleButtonAccept}
            type={BUTTON_TYPE.SOLID}
          />
        </div>
      </div>
    </Modal>
  );
};

AcceptModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleAccept: PropTypes.func.isRequired,
  acceptButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  popUpQuestion: PropTypes.string.isRequired,
  popUpMessage: PropTypes.string,
  modalWrapperClass: PropTypes.string,
  size: PropTypes.oneOf(Object.values(MODAL_SIZE)),
};

AcceptModal.defaultProps = {
  acceptButtonText: 'Accept',
  cancelButtonText: 'Accept',
  popUpMessage: '',
  modalWrapperClass: '',
  size: MODAL_SIZE.MEDIUM,
};

export default AcceptModal;
