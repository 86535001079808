import { brainlyDomains } from 'const/brainlyDomains';

export const generateQuestionURL = (questionId, market) => {
  const questionUrl = `${brainlyDomains[market]}${questionId}`;
  return questionUrl;
};

export const generateAnsweringModeQuestionURL = (questionId, market) => {
  const questionUrl = generateQuestionURL(questionId, market);
  return `${questionUrl}?answering=true`;
};
