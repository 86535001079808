import { Fetch } from 'utils/fetch';

export const CALL_API = 'CALL_API';
export const UPLOAD_API = 'UPLOAD_API';

const getApiActionTypes = actionType => ({
  requestType: `${actionType}_REQUEST`,
  successType: `${actionType}_SUCCESS`,
  failureType: `${actionType}_FAILURE`,
});

const defaultUploadApiConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

const uploadApi = ({ payload }) => {
  const { endpoint, method, config = defaultUploadApiConfig, ...data } = payload;
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => formData.append(key, value));
  return Fetch[method]
    ? Fetch[method](endpoint, formData, config)
    : // eslint-disable-next-line prefer-promise-reject-errors
    Promise.reject('Wrong API method passed');
};

const callApi = ({ payload }) => {
  const { endpoint, method, config = {}, ...data } = payload;
  return Fetch[method]
    ? Fetch[method](endpoint, data, config)
    : // eslint-disable-next-line prefer-promise-reject-errors
    Promise.reject('Wrong API method passed');
};

// eslint-disable-next-line no-unused-vars
export default store => next => action => {
  const actionData = action[CALL_API] || action[UPLOAD_API];
  if (actionData) {
    let apiCall;
    if (action[CALL_API]) {
      apiCall = callApi;
    } else if (action[UPLOAD_API]) {
      apiCall = uploadApi;
    }
    const { type, payload, meta = {}, successCallback, failureCallback } = actionData;
    const { requestType, successType, failureType } = getApiActionTypes(type);
    next({ type: requestType, meta });
    return apiCall({ payload })
      .then(({ data }) => {
        if (successCallback) {
          successCallback(data);
        }
        return next({
          type: successType,
          payload: data,
          meta,
        });
      })
      .catch(error => {
        if (failureCallback) {
          failureCallback(error);
        }
        return next({
          type: failureType,
          payload: error,
          meta,
        });
      });
  }
  return next(action);
};
