import { actionTypes } from './actions';

const initialState = {
  data: [],
  loading: false,
  fulfilled: false,
  error: null,
  totalCount: null,
  offset: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ANSWER_GROUP_USERS_FETCH_REQUEST:
      return { ...initialState, loading: true };
    case actionTypes.ANSWER_GROUP_USERS_FETCH_SUCCESS:
      return {
        ...initialState,
        data: action.payload.users,
        totalCount: action.payload.totalCount,
        offset: action.payload.users.length,
        fulfilled: true,
      };
    case actionTypes.ANSWER_GROUP_USERS_FETCH_FAILURE:
      return { ...initialState, error: action.payload };
    case actionTypes.ANSWER_GROUP_USERS_LOAD_MORE_REQUEST:
      return { ...state, fulfilled: false, error: null, loading: true };
    case actionTypes.ANSWER_GROUP_USERS_LOAD_MORE_SUCCESS:
      return {
        ...initialState,
        fulfilled: true,
        data: [...state.data, ...action.payload.users],
        totalCount: action.payload.totalCount,
        offset: state.offset + action.payload.users.length,
      };
    case actionTypes.ANSWER_GROUP_USERS_LOAD_MORE_FAILURE:
      return {
        ...state,
        loading: false,
        fulfilled: false,
        error: action.payload,
      };
    case actionTypes.ANSWER_GROUP_USERS_UPDATE_USERS:
      return { ...state, data: [...state.data, ...action.payload] };
    default:
      return state;
  }
};
