export const onInitState = {
  loading: true,
  fulfilled: false,
  error: null,
};

export const loadingState = {
  loading: true,
};

export const successState = {
  loading: false,
  fulfilled: true,
  error: null,
};
