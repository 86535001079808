import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { Suspense, lazy, useEffect } from 'react';

import { USER_ROLE } from 'const/roles';
import { accountSetupPath, loginPath, logoutPath } from 'const/routing';

import { fetchCurrentUserSession } from 'store/auth/actions';
import {
  getBrainlyProfileId,
  getCurrentUserAnswerGroup,
  getCurrentUserRole,
  getIsCurrentUserLoaded,
  getShouldAskForConsent,
  getShouldCurrentUserSetupAccount,
  getShouldUserLogout,
} from 'store/auth/selectors';

import { GARouting } from 'utils/GARouting';
import { Loader } from 'ui';
import { getUserIsAuthenticated } from 'utils/auth';
import { pushGAEvent } from 'utils/GAEvents';
import NotFoundPage from 'screens/NotFoundPage/NotFoundPage';

import AdminRoutes from './AdminRoutes/AdminRoutes';
import AnsweringGroupAdminRoutes from './AnsweringGroupAdminRoutes/AnsweringGroupAdminRoutes';
import FreelancerRoutes from './FreelancerRoutes/FreelancerRoutes';

const AccountSetupPage = lazy(() => import('screens/AccountSetup/AccountSetupPage'));
const LoginPage = lazy(() => import('screens/Login/Login'));
const LogoutPage = lazy(() => import('screens/Logout/Logout'));

const Routes = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isUserAuthenticated = getUserIsAuthenticated();
  const isUserLoaded = useSelector(getIsCurrentUserLoaded);
  const shouldFetchUserData = isUserAuthenticated && !isUserLoaded;
  const userRole = useSelector(getCurrentUserRole);
  const shouldSetupAccount = useSelector(getShouldCurrentUserSetupAccount);
  const brainlyId = useSelector(getBrainlyProfileId);
  const answerGroup = useSelector(getCurrentUserAnswerGroup);
  const shouldLogout = useSelector(getShouldUserLogout);
  const shouldAskForConsent = useSelector(getShouldAskForConsent);

  useEffect(() => {
    shouldFetchUserData && !shouldLogout && dispatch(fetchCurrentUserSession());
  }, [dispatch, shouldFetchUserData, shouldLogout]);

  // GA TRAKICNG
  useEffect(GARouting({ history }), []);
  useEffect(() => {
    if (brainlyId && answerGroup && answerGroup.name) {
      pushGAEvent({
        event: 'gotBrainlyToken',
        brainlyProfileId: brainlyId,
        agencyName: answerGroup.name,
      });
    }
  }, [answerGroup, brainlyId]);

  if (shouldFetchUserData && !shouldLogout && !shouldAskForConsent) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route exact path={[loginPath]} component={LoginPage} />
        <Route path={accountSetupPath} component={AccountSetupPage} />
        <Route path={logoutPath} component={LogoutPage} />

        <Choose>
          <When condition={shouldLogout}>
            <Redirect to={logoutPath} />
          </When>
          <When condition={!userRole}>
            <Route
              exact
              path='/'
              component={() => {
                return <Redirect to={logoutPath} />;
              }}
            />
          </When>
          <When condition={userRole === USER_ROLE.ADMIN}>
            <AdminRoutes />
          </When>
          <When condition={userRole === USER_ROLE.FREELANCER && !shouldSetupAccount}>
            <FreelancerRoutes />
          </When>
          <When condition={userRole === USER_ROLE.ANSWERING_GROUP_ADMIN}>
            <AnsweringGroupAdminRoutes />
          </When>
        </Choose>
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
