import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import { tableRowPropType } from 'utils/propTypes';

import { renderRows } from './utils';
import TableRow from './TableRow';

import style from './Table.module.scss';

const Table = ({
  className,
  header,
  rows,
  headClassName,
  cellClassName,
  bodyClassName,
  rowClassName,
  scrollContainerExtraClass,
}) => {
  const classNames = cx({
    [style.table]: !className,
    [className]: !!className,
  });

  return (
    <div className={style.tableWrapper}>
      <div className={`${style.scrollContainer} ${scrollContainerExtraClass}`}>
        <table className={classNames}>
          <thead className={headClassName}>
            <TableRow row={header} cellClassName={cellClassName} className={rowClassName} isHeader />
          </thead>
          <tbody className={bodyClassName}>{renderRows(rows, rowClassName, cellClassName)}</tbody>
        </table>
      </div>
    </div>
  );
};

Table.propTypes = {
  bodyClassName: PropTypes.string,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  headClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  header: tableRowPropType.isRequired,
  rows: PropTypes.arrayOf(tableRowPropType).isRequired,
  scrollContainerExtraClass: PropTypes.string,
};

Table.defaultProps = {
  bodyClassName: '',
  className: null,
  cellClassName: null,
  headClassName: '',
  rowClassName: '',
  scrollContainerExtraClass: '',
};

export default Table;
