import { CALL_API } from 'store/middlewares/api';
import { ERROR_CODE } from 'utils/error';
import { getApiMarket } from 'store/utils';

export const actionTypes = {
  MANAGE_EVALUATION_CHECKPOINTS_FETCH: 'MANAGE_EVALUATION_CHECKPOINTS_FETCH',
  MANAGE_EVALUATION_CHECKPOINTS_FETCH_REQUEST: 'MANAGE_EVALUATION_CHECKPOINTS_FETCH_REQUEST',
  MANAGE_EVALUATION_CHECKPOINTS_FETCH_SUCCESS: 'MANAGE_EVALUATION_CHECKPOINTS_FETCH_SUCCESS',
  MANAGE_EVALUATION_CHECKPOINTS_FETCH_FAILURE: 'MANAGE_EVALUATION_CHECKPOINTS_FETCH_FAILURE',

  MANAGE_EVALUATION_CHECKPOINTS_UPDATE: 'MANAGE_EVALUATION_CHECKPOINTS_UPDATE',
  MANAGE_EVALUATION_CHECKPOINTS_UPDATE_REQUEST: 'MANAGE_EVALUATION_CHECKPOINTS_UPDATE_REQUEST',
  MANAGE_EVALUATION_CHECKPOINTS_UPDATE_SUCCESS: 'MANAGE_EVALUATION_CHECKPOINTS_UPDATE_SUCCESS',
  MANAGE_EVALUATION_CHECKPOINTS_UPDATE_FAILURE: 'MANAGE_EVALUATION_CHECKPOINTS_UPDATE_FAILURE',

  MANAGE_EVALUATION_CHECKPOINTS_SET_FEED: 'MANAGE_EVALUATION_CHECKPOINTS_SET_FEED',

  MANAGE_EVALUATION_CHECKPOINTS_SET_CHANGES_STATUS: 'MANAGE_EVALUATION_CHECKPOINTS_SET_CHANGES_STATUS',

  MANAGE_EVALUATION_CHECKPOINTS_RESET_DATA: 'MANAGE_EVALUATION_CHECKPOINTS_RESET_DATA',

  MANAGE_EVALUATION_CHECKPOINTS_RESET_ALL_DATA: 'MANAGE_EVALUATION_CHECKPOINTS_RESET_ALL_DATA',
};

export const setWorkingFeed = feed => ({
  type: actionTypes.MANAGE_EVALUATION_CHECKPOINTS_SET_FEED,
  payload: feed,
});

export const setChangesDoneStatus = () => ({
  type: actionTypes.MANAGE_EVALUATION_CHECKPOINTS_SET_CHANGES_STATUS,
});

export const resetChecklistData = () => ({
  type: actionTypes.MANAGE_EVALUATION_CHECKPOINTS_RESET_DATA,
});

export const resetAllChecklistData = () => ({
  type: actionTypes.MANAGE_EVALUATION_CHECKPOINTS_RESET_ALL_DATA,
});

export const getEvaluationCheckpoints = feed => ({
  [CALL_API]: {
    type: actionTypes.MANAGE_EVALUATION_CHECKPOINTS_FETCH,
    payload: {
      endpoint: `/${getApiMarket()}/evaluation/checklist`,
      method: 'get',
      params: {
        feed,
      },
    },
    meta: {
      errorToastMessageConfig: {
        [ERROR_CODE.BAD_REQUEST]: { default: 'defaultBadRequest' },
        [ERROR_CODE.UNAUTHORIZED]: { default: 'defaultUnauthorized' },
        [ERROR_CODE.FORBIDDEN]: { default: 'defaultForbidden' },
        [ERROR_CODE.NOT_FOUND]: { RESOURCE_NOT_FOUND: 'checklistNotFound', default: 'defaultNotFound' },
        [ERROR_CODE.CONFLICT]: { default: 'defaultConflict' },
        [ERROR_CODE.UNPROCESSABLE_ENTITY]: { default: 'defaultUnprocessableEntity' },
      },
    },
  },
});

export const setEvaluationCheckpoints = (feed, data) => ({
  [CALL_API]: {
    type: actionTypes.MANAGE_EVALUATION_CHECKPOINTS_UPDATE,
    payload: {
      endpoint: `/${getApiMarket()}/evaluation/checklist?feed=${feed}`,
      method: 'post',
      ...data,
    },
    meta: {
      successToastMessage: 'checklistAdded',
      errorToastMessageConfig: {
        [ERROR_CODE.BAD_REQUEST]: { default: 'defaultBadRequest' },
        [ERROR_CODE.UNAUTHORIZED]: { default: 'defaultUnauthorized' },
        [ERROR_CODE.FORBIDDEN]: { default: 'defaultForbidden' },
        [ERROR_CODE.NOT_FOUND]: { default: 'defaultNotFound' },
        [ERROR_CODE.CONFLICT]: { default: 'defaultConflict' },
        [ERROR_CODE.UNPROCESSABLE_ENTITY]: { default: 'defaultUnprocessableEntity' },
      },
    },
  },
});
