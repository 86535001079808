import { actionTypes } from './actions';

const initialState = {
  data: {},
  currentFeed: null,
  changed: false,
  loading: false,
  fulfilled: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MANAGE_EVALUATION_CHECKPOINTS_FETCH_REQUEST:
      return { ...state, loading: true, fulfilled: false };
    case actionTypes.MANAGE_EVALUATION_CHECKPOINTS_FETCH_SUCCESS:
      return { ...state, data: action.payload, changed: false, loading: false, fulfilled: true, error: null };
    case actionTypes.MANAGE_EVALUATION_CHECKPOINTS_FETCH_FAILURE:
      return { ...state, data: {}, changed: false, loading: false, fulfilled: true, error: action.payload };
    case actionTypes.MANAGE_EVALUATION_CHECKPOINTS_SET_FEED:
      return { ...initialState, currentFeed: action.payload };
    case actionTypes.MANAGE_EVALUATION_CHECKPOINTS_RESET_DATA:
      return { ...state, data: {}, changed: false, loading: false, fulfilled: false, error: null };
    case actionTypes.MANAGE_EVALUATION_CHECKPOINTS_RESET_ALL_DATA:
      return { ...initialState };
    case actionTypes.MANAGE_EVALUATION_CHECKPOINTS_SET_CHANGES_STATUS:
      return { ...state, changed: true };
    default:
      return state;
  }
};
