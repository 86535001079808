import { createSelector } from 'reselect';

const getEvaluateAnswer = createSelector(
  state => state.evaluateAnswer,
  evaluateAnswer => evaluateAnswer,
);

export const getAnswerData = createSelector(
  getEvaluateAnswer,
  ({ data }) => data,
);

export const getIsAnswerLoading = createSelector(
  getEvaluateAnswer,
  ({ loading }) => loading,
);

export const getIsAnswerFulfilled = createSelector(
  getEvaluateAnswer,
  ({ fulfilled }) => fulfilled,
);

export const getCurrentFeed = createSelector(
  getEvaluateAnswer,
  ({ currentFeed }) => currentFeed || null,
);

export const getQuestionGradeName = createSelector(
  getAnswerData,
  ({ question }) => question && question.grade && question.grade.name,
);

export const getQuestionSubjectName = createSelector(
  getAnswerData,
  ({ question }) => question && question.subject && question.subject.name,
);

export const getQuestionId = createSelector(
  getAnswerData,
  ({ question }) => (question && question.databaseId) || '',
);

export const getAnswerAttachments = createSelector(
  getAnswerData,
  ({ answer }) => answer && answer.attachments,
);

export const getQuestionAttachments = createSelector(
  getAnswerData,
  ({ question }) => question && question.attachments,
);

export const getEvaluationFilters = createSelector(
  getEvaluateAnswer,
  ({ filters }) => filters || {},
);

export const getAnswerSubjectsIdsFilter = createSelector(
  getEvaluationFilters,
  ({ subjectIds }) => subjectIds,
);

export const getAnswerGradesIdsFilter = createSelector(
  getEvaluationFilters,
  ({ gradeIds }) => gradeIds,
);

export const getEndCursor = createSelector(
  getEvaluateAnswer,
  ({ endCursor }) => endCursor,
);

export const getAnswersCount = createSelector(
  getAnswerData,
  ({ count }) => count,
);

export const getAnswerError = createSelector(
  getEvaluateAnswer,
  ({ error }) => error,
);

export const getAnswerId = createSelector(
  getAnswerData,
  ({ answer }) => (answer && answer.databaseId) || '',
);

export const getCurrentScore = createSelector(
  getEvaluateAnswer,
  ({ score }) => score || null,
);

export const getFreelancerChecklist = createSelector(
  getEvaluateAnswer,
  ({ checkpoints }) => checkpoints,
);
