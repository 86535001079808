import { actionTypes } from './actions';

const initialState = {
  answersFeedsPrivileges: [],
  evaluationFeedsPrivileges: [],
  loading: false,
  answersFeedsPrivilegesFulfilled: false,
  evaluationFeedsPrivilegesFulfilled: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_FEED_PRIVILEGES_GET_PRIVILEGES_REQUEST:
      return { ...state, loading: true };
    case actionTypes.USER_FEED_PRIVILEGES_GET_PRIVILEGES_SUCCESS: {
      const allowedFeeds = action.payload.feedPrivileges
        .filter(({ isEnabled }) => isEnabled)
        .map(({ feedId }) => feedId);
      return {
        ...state,
        answersFeedsPrivilegesFulfilled: true,
        loading: false,
        answersFeedsPrivileges: allowedFeeds,
      };
    }
    case actionTypes.USER_EVAL_FEED_PRIVILEGES_FETCH_REQUEST:
      return { ...state, loading: true };
    case actionTypes.USER_EVAL_FEED_PRIVILEGES_FETCH_SUCCESS: {
      const allowedEvalFeeds = action.payload.feedsPrivileges
        .filter(({ isEnabled }) => isEnabled)
        .map(({ feedId }) => feedId);
      return {
        ...state,
        evaluationFeedsPrivilegesFulfilled: true,
        loading: false,
        evaluationFeedsPrivileges: allowedEvalFeeds,
      };
    }
    default:
      return state;
  }
};
