import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import { childrenPropType } from 'utils/propTypes';
import styles from './Tabs.module.scss';

export const tabThemes = {
  mint: 'mint',
  mustard: 'mustard',
  peach: 'peach',
};

const TabButton = ({ header, isActive, color, ...props }) => {
  const classNames = cx({
    [styles.tabButton]: true,
    [styles[`tabButton--${color}`]]: true,
    [styles[`tabButton--active`]]: isActive,
  });
  return (
    <button className={classNames} {...props}>
      {header}
    </button>
  );
};

TabButton.propTypes = {
  header: childrenPropType.isRequired,
  isActive: PropTypes.bool,
  color: PropTypes.oneOf(Object.values(tabThemes)),
};

TabButton.defaultProps = {
  isActive: false,
  color: tabThemes.mint,
};

export default TabButton;
