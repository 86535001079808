import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';

import { ICON_STYLE, MODAL_SIZE, OVERLAY_COLORS } from 'const/styles';
import { childrenPropType } from 'utils/propTypes';

import CloseButton from 'ui/CloseButton/CloseButton';
import style from './ModalPortal.module.scss';

const ModalPortal = ({ children, isOpen, onRequestClose, size, customClass, overlayColor }) => {
  return (
    <ReactModal
      className={`sg-toplayer sg-toplayer--${size} sg-toplayer--modal ${style.modalContent} ${customClass}`}
      overlayClassName={`sg-overlay sg-overlay--${overlayColor}`}
      parentSelector={() => document.getElementById('dialog-root')}
      ariaHideApp={false}
      isOpen={isOpen}
      shouldCloseOnEsc
      onRequestClose={onRequestClose}
    >
      <div className='sg-toplayer__wrapper'>
        {onRequestClose && <CloseButton onClick={onRequestClose} fillColor={ICON_STYLE.ICON_BLACK} />}
        {children}
      </div>
    </ReactModal>
  );
};

ModalPortal.propTypes = {
  children: childrenPropType.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
  size: PropTypes.oneOf(Object.values(MODAL_SIZE)),
  customClass: PropTypes.string,
  overlayColor: PropTypes.oneOf(Object.values(OVERLAY_COLORS)),
};

ModalPortal.defaultProps = {
  onRequestClose: undefined,
  size: MODAL_SIZE.LARGE,
  customClass: '',
  overlayColor: OVERLAY_COLORS.BLACK,
};

export default ModalPortal;
