import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import { DROPDOWN_TYPE } from 'const/styles';

import style from './Dropdown.module.scss';

const Dropdown = ({ className, itemsClassName, type, items, opened, label }) => {
  const classNames = cx(style.dropdown, {
    'sg-dropdown': true,
    'sg-dropdown--opened': opened,
    [`sg-dropdown--${type}`]: !!type,
    [className]: !!className,
  });

  const itemsElements = items.map(({ value: itemValue, onClick }) => (
    <div className={`sg-dropdown__item ${style.item}`} key={itemValue} onClick={onClick}>
      {itemValue}
    </div>
  ));

  return (
    <div className={classNames}>
      <If condition={label}>
        <div className='sg-dropdown__icon' />
        <div className='sg-dropdown__item'>{label}</div>
      </If>
      <div className={cx('sg-dropdown__items', itemsClassName && itemsClassName)}>{itemsElements}</div>
    </div>
  );
};

Dropdown.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ),
  itemsClassName: PropTypes.string,
  opened: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(DROPDOWN_TYPE)),
};

Dropdown.defaultProps = {
  className: null,
  label: null,
  items: [],
  itemsClassName: null,
  opened: false,
  type: DROPDOWN_TYPE.DEFAULT,
};

export default Dropdown;
