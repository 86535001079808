import { CALL_API } from 'store/middlewares/api';
import { DEFAULT_PAGINATION } from 'const/limits';
import { FEED_TYPE } from 'const/feeds';
import { clearSkippedQuestionForUser, getSkippedQuestionForUser } from 'utils/storage';
import { getCurrentUserId, getCurrentUserMarket } from 'store/auth/selectors';

import {
  getActiveFilters,
  getCurrentOffset,
  getCurrentQuestionsFeedType,
  getFirstQuestionTimestamp,
} from './selectors';

export const actionTypes = {
  QUESTIONS_FEED_CLEAR_REDUCER: 'QUESTIONS_FEED_CLEAR_REDUCER',
  QUESTIONS_FEED_TSR_INIT_FETCH: 'QUESTIONS_FEED_TSR_INIT_FETCH',
  QUESTIONS_FEED_TSR_INIT_FETCH_REQUEST: 'QUESTIONS_FEED_TSR_INIT_FETCH_REQUEST',
  QUESTIONS_FEED_TSR_INIT_FETCH_SUCCESS: 'QUESTIONS_FEED_TSR_INIT_FETCH_SUCCESS',
  QUESTIONS_FEED_TSR_INIT_FETCH_FAILURE: 'QUESTIONS_FEED_TSR_INIT_FETCH_FAILURE',
  QUESTIONS_FEED_FA_INIT_FETCH: 'QUESTIONS_FEED_FA_INIT_FETCH',
  QUESTIONS_FEED_FA_INIT_FETCH_REQUEST: 'QUESTIONS_FEED_FA_INIT_FETCH_REQUEST',
  QUESTIONS_FEED_FA_INIT_FETCH_SUCCESS: 'QUESTIONS_FEED_FA_INIT_FETCH_SUCCESS',
  QUESTIONS_FEED_FA_INIT_FETCH_FAILURE: 'QUESTIONS_FEED_FA_INIT_FETCH_FAILURE',
  QUESTIONS_FEED_QI_INIT_FETCH: 'QUESTIONS_FEED_QI_INIT_FETCH',
  QUESTIONS_FEED_QI_INIT_FETCH_REQUEST: 'QUESTIONS_FEED_QI_INIT_FETCH_REQUEST',
  QUESTIONS_FEED_QI_INIT_FETCH_SUCCESS: 'QUESTIONS_FEED_QI_INIT_FETCH_SUCCESS',
  QUESTIONS_FEED_QI_INIT_FETCH_FAILURE: 'QUESTIONS_FEED_QI_INIT_FETCH_FAILURE',
  QUESTIONS_FEED_LOAD_MORE: 'QUESTIONS_FEED_LOAD_MORE',
  QUESTIONS_FEED_LOAD_MORE_REQUEST: 'QUESTIONS_FEED_LOAD_MORE_REQUEST',
  QUESTIONS_FEED_LOAD_MORE_SUCCESS: 'QUESTIONS_FEED_LOAD_MORE_SUCCESS',
  QUESTIONS_FEED_LOAD_MORE_FAILURE: 'QUESTIONS_FEED_LOAD_MORE_FAILURE',
  QUESTIONS_FEED_SKIP_QUESTION: 'QUESTIONS_FEED_SKIP_QUESTION',
  QUESTIONS_FEED_ADD_SKIPPED_QUESTIONS: 'QUESTIONS_FEED_ADD_SKIPPED_QUESTIONS',
  QUESTIONS_FEED_CLEAR_SKIPPED_QUESTIONS: 'QUESTIONS_FEED_CLEAR_SKIPPED_QUESTIONS',
  QUESTIONS_FEED_SET_ACTIVE_FILTERS: 'QUESTIONS_FEED_SET_ACTIVE_FILTERS',
  QUESTIONS_FEED_CLEAR_ACTIVE_FILTERS: 'QUESTIONS_FEED_CLEAR_ACTIVE_FILTERS',
  QUESTIONS_FEED_SET_NEW_QUESTIONS_AMOUNT: 'QUESTIONS_FEED_SET_NEW_QUESTIONS_AMOUNT',
  QUESTIONS_FEED_DELETE_QUESTION_BY_ID: 'QUESTIONS_FEED_DELETE_QUESTION_BY_ID',
  QUESTIONS_FEED_QUESTION_RESERVED: 'QUESTIONS_FEED_QUESTION_RESERVED',
  QUESTIONS_FEED_QUESTION_UNRESERVED: 'QUESTIONS_FEED_QUESTION_UNRESERVED',
  QUESTIONS_FEED_CLEAR_SUBJECT_QUESTIONS: 'QUESTIONS_FEED_CLEAR_SUBJECT_QUESTIONS',
};

export const clearQuestionsFeedReducer = () => ({
  type: actionTypes.QUESTIONS_FEED_CLEAR_REDUCER,
});

const defaultGetQuestionsParams = {
  limit: DEFAULT_PAGINATION,
  offset: 0,
  offsetTimestamp: null,
  subjectIds: null,
  gradeIds: null,
  projectIds: null,
  attachment: null,
};

// API ACTIONS
const getQuestions = ({ feedType, baseAction, market, ...params }) => ({
  [CALL_API]: {
    type: baseAction,
    payload: {
      endpoint: `/${market}/questions/${feedType}`,
      method: 'get',
      params: {
        ...defaultGetQuestionsParams,
        ...params,
      },
    },
  },
});

export const getMoreQuestions = ({ market, feedType, ...params }) => ({
  [CALL_API]: {
    type: actionTypes.QUESTIONS_FEED_LOAD_MORE,
    payload: {
      endpoint: `/${market}/questions/${feedType}`,
      method: 'get',
      params: {
        ...defaultGetQuestionsParams,
        ...params,
      },
    },
  },
});

export const getTsrQuestions = (market, params) =>
  getQuestions({
    feedType: FEED_TYPE.TSR,
    baseAction: actionTypes.QUESTIONS_FEED_TSR_INIT_FETCH,
    market,
    ...params,
  });

export const getFaQuestions = (market, params) =>
  getQuestions({
    feedType: FEED_TYPE.FAST_ANSWERING,
    baseAction: actionTypes.QUESTIONS_FEED_FA_INIT_FETCH,
    market,
    ...params,
  });

export const getQiQuestions = (market, params) =>
  getQuestions({
    feedType: FEED_TYPE.QI,
    baseAction: actionTypes.QUESTIONS_FEED_QI_INIT_FETCH,
    market,
    ...params,
  });

const getQuestionsToFeedMap = {
  [FEED_TYPE.FAST_ANSWERING]: getFaQuestions,
  [FEED_TYPE.TSR]: getTsrQuestions,
  [FEED_TYPE.QI]: getQiQuestions,
};

export const getQuestionsStrategy = feedType => getQuestionsToFeedMap[feedType];

// ACTIONS
export const addSkippedQuestions = ({ skippedQuestionsIds, feedType }) => ({
  type: actionTypes.QUESTIONS_FEED_ADD_SKIPPED_QUESTIONS,
  payload: { skippedQuestionsIds, feedType },
});

export const clearSkippedQuestions = feedType => ({
  type: actionTypes.QUESTIONS_FEED_CLEAR_SKIPPED_QUESTIONS,
  payload: feedType,
});

export const setActiveFilters = activeFilters => ({
  type: actionTypes.QUESTIONS_FEED_SET_ACTIVE_FILTERS,
  payload: activeFilters,
});

export const clearActiveFilters = () => ({
  type: actionTypes.QUESTIONS_FEED_CLEAR_ACTIVE_FILTERS,
});

export const setNewQuestionsAmount = questionsAmount => ({
  type: actionTypes.QUESTIONS_FEED_SET_NEW_QUESTIONS_AMOUNT,
  payload: questionsAmount,
});

export const deleteQuestionById = questionId => ({
  type: actionTypes.QUESTIONS_FEED_DELETE_QUESTION_BY_ID,
  payload: questionId,
});

export const reserveQuestion = questionId => ({
  type: actionTypes.QUESTIONS_FEED_QUESTION_RESERVED,
  payload: questionId,
});

export const unreserveQuestion = questionId => ({
  type: actionTypes.QUESTIONS_FEED_QUESTION_UNRESERVED,
  payload: questionId,
});

export const clearSubjectQuestions = ({ subjectId, projectId }) => ({
  type: actionTypes.QUESTIONS_FEED_CLEAR_SUBJECT_QUESTIONS,
  payload: { subjectId, projectId },
});

// THUNKS
export const initSkippedQuestions = feedType => (dispatch, getState) => {
  const state = getState();
  const userId = getCurrentUserId(state);
  const skippedQuestionsIds = getSkippedQuestionForUser(userId, feedType);
  dispatch(addSkippedQuestions({ skippedQuestionsIds, feedType }));
};

export const clearUserSkippedQuestions = () => (dispatch, getState) => {
  const state = getState();
  const userId = getCurrentUserId(state);
  const feedType = getCurrentQuestionsFeedType(state);
  clearSkippedQuestionForUser(userId, feedType);
  dispatch(clearSkippedQuestions(feedType));
};

export const loadMoreFeedQuestions = () => (dispatch, getState) => {
  const state = getState();
  const feedType = getCurrentQuestionsFeedType(state);
  const offsetTimestamp = new Date(getFirstQuestionTimestamp(state)).getTime();
  const offset = getCurrentOffset(state);
  const activeFilters = getActiveFilters(state);

  const market = getCurrentUserMarket(state);

  dispatch(
    getMoreQuestions({
      market,
      feedType,
      offsetTimestamp,
      offset,
      ...activeFilters,
    }),
  );
};

export const getFeedQuestions = feedType => (dispatch, getState) => {
  const state = getState();
  const activeFilters = getActiveFilters(state);

  const market = getCurrentUserMarket(state);

  const fetchQuestions = getQuestionsStrategy(feedType);

  dispatch(fetchQuestions(market, activeFilters));
};

export const refreshFeedQuestions = () => (dispatch, getState) => {
  const state = getState();
  const feedType = getCurrentQuestionsFeedType(state);
  const fetchQuestions = getFeedQuestions(feedType);
  dispatch(fetchQuestions);
};
