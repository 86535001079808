import { Provider } from 'react-redux';
import { render } from 'react-dom';
import React from 'react';

import App from './App/App';

import './utils/i18n';
import * as serviceWorker from './serviceWorker';
import { initFetch } from './utils/fetch';
import store from './store';

window.env = process.env.REACT_APP_INSTANCE_NAME;

initFetch(store);

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
