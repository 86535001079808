export const brainlyDomains = {
  us: 'https://brainly.com/question/',
  pt: 'https://brainly.com.br/tarefa/',
  es: 'https://brainly.lat/tarea/',
  fr: 'https://nosdevoirs.fr/devoir/',
  pl: 'https://brainly.pl/zadanie/',
  ro: 'https://brainly.ro/tema/',
  ru: 'https://znanija.com/task/',
  tr: 'https://eodev.com/gorev/',
  hi: 'https://brainly.in/question/',
  id: 'https://brainly.co.id/tugas/',
  ph: 'https://brainly.ph/question/',
};
