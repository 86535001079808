import { combineReducers } from 'redux';
import answerGroupUsers from './answerGroupUsers/reducer';
import answerGroups from './answerGroups/reducer';
import answeringMode from './answeringMode/reducer';
import assignedQuestionsLog from './assignedQuestionsLog/reducer';
import attachments from './attachments/reducer';
import auth from './auth/reducer';
import comments from './comments/reducer';
import evaluateAnswer from './evaluateAnswer/reducer';
import evaluationCheckpoints from './manageEvaluationCheckpoints/reducer';
import evaluationFeeds from './evaluationFeeds/reducer';
import evaluationPrivileges from './evaluationPrivileges/reducer';
import filters from './filters/reducer';
import limits from './limits/reducer';
import menuOption from './menuOption/reducer';
import privileges from './privileges/reducer';
import projects from './projects/reducer';
import questionsCounter from './questionsCounters/reducer';
import questionsFeed from './questionsFeed/reducer';
import search from './search/reducer';
import singleProject from './singleProject/reducer';
import singleUser from './singleUser/reducer';
import singleWorkerLog from './singleWorkerLog/reducer';
import toasts from './toasts/reducer';
import userFeedPrivileges from './userFeedPrivileges/reducer';
import users from './users/reducer';
import usersFilters from './usersFilters/reducer';
import websocket from './websocket/reducer';
import workerLogs from './workerLogs/reducer';

const appReducer = combineReducers({
  answerGroupUsers,
  answerGroups,
  answeringMode,
  attachments,
  auth,
  comments,
  menuOption,
  toasts,
  privileges,
  projects,
  search,
  workerLogs,
  singleWorkerLog,
  assignedQuestionsLog,
  singleProject,
  singleUser,
  users,
  usersFilters,
  userFeedPrivileges,
  limits,
  websocket,
  evaluateAnswer,
  questionsCounters: questionsCounter,
  questionsFeed,
  filters,
  evaluationPrivileges,
  evaluationFeeds,
  evaluationCheckpoints,
});

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STORE') {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
