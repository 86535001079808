import PropTypes from 'prop-types';
import React from 'react';

const Breadcrumbs = ({ elements, listClassName, elementClassName }) => {
  return (
    <ul className={`sg-breadcrumb-list ${listClassName}`}>
      {elements.map(breadcrumb => (
        <li className={`sg-breadcrumb-list__element ${elementClassName}`} key={breadcrumb}>
          <span className='sg-text sg-text--bold sg-text--small sg-text--gray'>{breadcrumb}</span>
        </li>
      ))}
    </ul>
  );
};

Breadcrumbs.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.string).isRequired,
  listClassName: PropTypes.string,
  elementClassName: PropTypes.string,
};

Breadcrumbs.defaultProps = {
  listClassName: '',
  elementClassName: '',
};

export default Breadcrumbs;
