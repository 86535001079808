import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import style from './Table.module.scss';

const TableCell = ({ className, content, isHeader }) => {
  const classNames = cx({
    [style.cell]: !isHeader,
    [style.headerCell]: isHeader,
    [className]: !!className,
  });

  return (
    <Choose>
      <When condition={isHeader}>
        <th className={classNames}>{content}</th>
      </When>
      <Otherwise>
        <td className={classNames}>{content}</td>
      </Otherwise>
    </Choose>
  );
};

TableCell.propTypes = {
  className: PropTypes.string,
  content: PropTypes.any.isRequired,
  isHeader: PropTypes.bool,
};

TableCell.defaultProps = {
  className: null,
  isHeader: false,
};

export default TableCell;
