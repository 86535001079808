import * as Cookies from 'utils/cookies';
import { CALL_API } from 'store/middlewares/api';
import { ERROR_CODE } from 'utils/error';
import { clearStoreAction } from 'store/actions/clearStore';
import { socketCleanup } from 'store/websocket/actions';

export const actionTypes = {
  AUTH_USER_FETCH: 'AUTH_USER_FETCH',
  AUTH_USER_FETCH_REQUEST: 'AUTH_USER_FETCH_REQUEST',
  AUTH_USER_FETCH_SUCCESS: 'AUTH_USER_FETCH_SUCCESS',
  AUTH_USER_FETCH_FAILURE: 'AUTH_USER_FETCH_FAILURE',

  AUTH_REFRESH_TOKEN_FETCH: 'AUTH_REFRESH_TOKEN_FETCH',
  AUTH_REFRESH_TOKEN_FETCH_REQUEST: 'AUTH_REFRESH_TOKEN_FETCH_REQUEST',
  AUTH_REFRESH_TOKEN_FETCH_SUCCESS: 'AUTH_REFRESH_TOKEN_FETCH_SUCCESS',
  AUTH_REFRESH_TOKEN_FETCH_FAILURE: 'AUTH_REFRESH_TOKEN_FETCH_FAILURE',

  AUTH_LOG_IN: 'AUTH_LOG_IN',
  AUTH_LOG_IN_REQUEST: 'AUTH_LOG_IN_REQUEST',
  AUTH_LOG_IN_SUCCESS: 'AUTH_LOG_IN_SUCCESS',
  AUTH_LOG_IN_FAILURE: 'AUTH_LOG_IN_FAILURE',

  AUTH_ACCEPT_TERMS: 'AUTH_ACCEPT_TERMS',
  AUTH_ACCEPT_TERMS_REQUEST: 'AUTH_ACCEPT_TERMS_REQUEST',
  AUTH_ACCEPT_TERMS_SUCCESS: 'AUTH_ACCEPT_TERMS_SUCCESS',
  AUTH_ACCEPT_TERMS_FAILURE: 'AUTH_ACCEPT_TERMS_FAILURE',

  AUTH_LOG_OUT: 'AUTH_LOG_OUT',
};

export const logout = () => async dispatch => {
  dispatch({ type: actionTypes.AUTH_LOG_OUT });
};

export const fetchCurrentUserSession = () => ({
  [CALL_API]: {
    type: actionTypes.AUTH_USER_FETCH,
    payload: {
      endpoint: '/auth/me',
      method: 'get',
    },
  },
});

export const clearAppData = () => async dispatch => {
  Cookies.removeSessionCookies();
  socketCleanup(dispatch);
  dispatch(clearStoreAction());
};

export const login = (email, password) => async dispatch => {
  await dispatch({
    [CALL_API]: {
      type: actionTypes.AUTH_LOG_IN,
      payload: {
        endpoint: '/auth/sign-in',
        method: 'post',
        email,
        password,
      },
      successCallback: async tokens => {
        Cookies.setSessionCookies(tokens);
        await dispatch(fetchCurrentUserSession());
      },
      meta: {
        successToastMessage: 'loggedIn',
        errorToastMessageConfig: {
          [ERROR_CODE.UNAUTHORIZED]: { default: 'invalidCredentials' },
          [ERROR_CODE.FORBIDDEN]: { default: 'accessDenied' },
        },
      },
    },
  });
};

export const acceptTermsAndConditions = () => async dispatch => {
  return dispatch({
    [CALL_API]: {
      type: actionTypes.AUTH_ACCEPT_TERMS,
      payload: {
        endpoint: '/auth/me/consent',
        method: 'post',
        consent: true,
      },
      successCallback: () => {
        dispatch(fetchCurrentUserSession());
      },
    },
  });
};
