import { CALL_API } from 'store/middlewares/api';
import { getApiMarket } from 'store/utils';

export const actionTypes = {
  SINGLE_PROJECT_FETCH: 'SINGLE_PROJECT_FETCH',
  SINGLE_PROJECT_FETCH_REQUEST: 'SINGLE_PROJECT_FETCH_REQUEST',
  SINGLE_PROJECT_FETCH_SUCCESS: 'SINGLE_PROJECT_FETCH_SUCCESS',
  SINGLE_PROJECT_FETCH_FAILURE: 'SINGLE_PROJECT_FETCH_FAILURE',
};

export const getSingleProjectData = id => ({
  [CALL_API]: {
    type: actionTypes.SINGLE_PROJECT_FETCH,
    payload: {
      endpoint: `/${getApiMarket()}/projects/${id}`,
      method: 'get',
    },
    meta: {},
  },
});
