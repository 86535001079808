import { createSelector } from 'reselect';

export const getAuth = createSelector(
  state => state.auth,
  auth => auth,
);

export const getCurrentUserData = createSelector(
  getAuth,
  ({ data }) => data,
);

export const getCurrentUserId = createSelector(
  getCurrentUserData,
  ({ id }) => id,
);

export const getBrainlyProfileId = createSelector(
  getCurrentUserData,
  ({ brainlyProfileId }) => brainlyProfileId,
);

export const getCurrentUserRole = createSelector(
  getCurrentUserData,
  ({ role }) => role,
);

export const getCurrentUserMarket = createSelector(
  getCurrentUserData,
  ({ market }) => market,
);

export const getShouldCurrentUserSetupAccount = createSelector(
  getCurrentUserData,
  ({ forcePasswordChange, forceBrainlyProfileLinkSet }) => forcePasswordChange || forceBrainlyProfileLinkSet,
);

export const getCurrentUserAnswerGroup = createSelector(
  getCurrentUserData,
  ({ answerGroup }) => answerGroup,
);

export const getIsCurrentUserLoaded = createSelector(
  getAuth,
  ({ fulfilled }) => fulfilled,
);

export const getIsCurrentUserLoading = createSelector(
  getAuth,
  ({ loading }) => loading,
);

export const getShouldUserLogout = createSelector(
  getAuth,
  ({ shouldLogout }) => shouldLogout,
);

export const getShouldAskForConsent = createSelector(
  getAuth,
  ({ shouldAskForConsent }) => shouldAskForConsent,
);
