import { USER_ROLE } from 'const/roles';
import { getCurrentUserMarket, getCurrentUserRole } from './auth/selectors';
import store from './index';

export const getApiPrefix = () => {
  const state = store.getState();
  const role = getCurrentUserRole(state);
  const prefix =
    {
      [USER_ROLE.ADMIN]: '',
      [USER_ROLE.ANSWERING_GROUP_ADMIN]: '/answer-group-admin',
    }[role] || '';
  return prefix;
};

export const getApiMarket = () => {
  const state = store.getState();
  const market = getCurrentUserMarket(state);
  return market;
};
