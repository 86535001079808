import { actionTypes } from './actions';

const initialState = {
  data: [],
  loading: false,
  fulfilled: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EVALUATION_FEEDS_FETCH_REQUEST:
      return { ...state, loading: true };
    case actionTypes.EVALUATION_FEEDS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        fulfilled: true,
        data: action.payload.feeds,
      };
    case actionTypes.EVALUATION_FEEDS_FETCH_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
