import uuid from 'uuid/v1';

export const actionTypes = {
  TOASTS_ADD_TOAST: 'TOASTS_ADD_TOAST',
  TOASTS_REMOVE_TOAST: 'TOASTS_REMOVE_TOAST',
};

export const addToast = (type, translationKey) => ({
  type: actionTypes.TOASTS_ADD_TOAST,
  payload: { id: uuid(), type, translationKey },
});

export const removeToast = id => ({ type: actionTypes.TOASTS_REMOVE_TOAST, payload: { id } });
