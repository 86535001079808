import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import { LABEL_TYPE, LOADER_TYPE } from 'const/styles';
import { Label, Loader } from 'ui/index';
import { SOCKET_CONNECTION_ALERT_LIFESPAN } from 'const/timings';
import { isSocketConnected, isSocketReconnecting } from 'store/websocket/selectors';

import style from './TopBar.module.scss';

const SocketConnectionAlert = () => {
  const { t } = useTranslation('SocketConnectionAlert');

  const socketConnected = useSelector(isSocketConnected);
  const socketReconnecting = useSelector(isSocketReconnecting);

  const [wasSocketReconnectionBegun, setSocketReconnectionBegun] = useState(false);

  const isSocketLiveAgain = wasSocketReconnectionBegun && socketConnected;
  if (!wasSocketReconnectionBegun && socketReconnecting) {
    setSocketReconnectionBegun(true);
  }

  useEffect(() => {
    isSocketLiveAgain && setTimeout(() => setSocketReconnectionBegun(false), SOCKET_CONNECTION_ALERT_LIFESPAN);
  }, [isSocketLiveAgain]);

  return (
    <div className={style.alertWrapper}>
      <If condition={socketReconnecting}>
        <div className={style.disconnected}>
          <Label type={`${LABEL_TYPE.TRANSPARENT}`}>
            {t('disconnected')}
            <Loader type={LOADER_TYPE.DOTS} />
          </Label>
        </div>
      </If>
      <If condition={isSocketLiveAgain}>
        <div className={style.connected}>
          <Label type={`${LABEL_TYPE.TRANSPARENT}`}>{t('connected')}</Label>
        </div>
      </If>
    </div>
  );
};

export default SocketConnectionAlert;
