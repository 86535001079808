import { actionTypes } from './actions';

const initialState = {
  data: [],
  loading: false,
  fulfilled: false,
  error: null,
};

const disableAnswerGroup = id => answerGroup =>
  answerGroup.id === id ? { ...answerGroup, disabledAt: new Date().toISOString() } : answerGroup;
const enableAnswerGroup = id => answerGroup =>
  answerGroup.id === id ? { ...answerGroup, disabledAt: null } : answerGroup;

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ANSWER_GROUPS_FETCH_REQUEST:
      return { ...state, loading: true };
    case actionTypes.ANSWER_GROUPS_FETCH_SUCCESS:
      return { ...state, loading: false, fulfilled: true, data: action.payload.answerGroups };
    case actionTypes.ANSWER_GROUPS_FETCH_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case actionTypes.ANSWER_GROUPS_DISABLE_GROUP_SUCCESS:
      return { ...state, data: state.data.map(disableAnswerGroup(action.meta.id)) };
    case actionTypes.ANSWER_GROUPS_ENABLE_GROUP_SUCCESS:
      return { ...state, data: state.data.map(enableAnswerGroup(action.meta.id)) };
    default:
      return state;
  }
};
