import { actionTypes } from './actions';

const initialState = {
  selectedFilters: {},
  data: {},
  loading: false,
  fulfilled: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.WORKER_LOGS_FETCH_REQUEST:
      return { ...initialState, selectedFilters: state.selectedFilters, loading: true };
    case actionTypes.WORKER_LOGS_FETCH_SUCCESS:
      return { ...initialState, selectedFilters: state.selectedFilters, data: action.payload.logs, fulfilled: true };
    case actionTypes.WORKER_LOGS_FETCH_FAILURE:
      return { ...initialState, selectedFilters: state.selectedFilters, error: action.payload };
    case actionTypes.WORKER_LOGS_SET_FILTERS:
      return { ...state, selectedFilters: action.payload };
    default:
      return state;
  }
};
