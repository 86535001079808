export const WEBSOCKET_EVENT = {
  ANSWERED: 'AnsweredQuestionEvent',
  DELETED: 'DeletedQuestionEvent',
  NEW_QUESTIONS: 'NewQuestionsEvent',
  RESERVED: 'ReservedQuestionEvent',
  UNRESERVED: 'UnreservedQuestionEvent',
  COUNTER_INCREMENTED: 'CounterIncremented',
  LIMIT_REACHED: 'LimitReached',
  ANSWERING_MODE_CLOSED: 'AnsweringModeClosed',
};
