import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import { childrenPropType } from 'utils/propTypes';

import style from './Container.module.scss';

const Container = ({ children, className, centered }) => {
  const classNames = cx(style.container, {
    [className]: !!className,
    [style.centered]: centered,
  });

  return <div className={classNames}>{children}</div>;
};

Container.propTypes = {
  centered: PropTypes.bool,
  children: childrenPropType.isRequired,
  className: PropTypes.string,
};

Container.defaultProps = {
  centered: false,
  className: '',
};

export default Container;
