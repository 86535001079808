import { actionTypes } from './actions';

const initialState = {
  data: {},
  loading: false,
  fulfilled: false,
  error: null,
  shouldLogout: false,
  shouldAskForConsent: false,
};

const {
  AUTH_LOG_IN_SUCCESS,
  AUTH_USER_FETCH_REQUEST,
  AUTH_USER_FETCH_SUCCESS,
  AUTH_USER_FETCH_FAILURE,
  AUTH_LOG_OUT,
  AUTH_ACCEPT_TERMS_SUCCESS,
} = actionTypes;

const noConsentErrorMessage = 'USER_HAS_TO_SET_CONSENT';

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOG_IN_SUCCESS:
      return { ...state, shouldLogout: false };
    case AUTH_USER_FETCH_REQUEST:
      return { ...initialState, loading: true };
    case AUTH_USER_FETCH_SUCCESS:
      return { ...initialState, data: { ...action.payload }, fulfilled: true };
    case AUTH_USER_FETCH_FAILURE: {
      if (action.payload.message === noConsentErrorMessage) {
        return {
          ...state,
          shouldAskForConsent: true,
        };
      }
      return {
        ...state,
        error: action.payload,
        fulfilled: true,
      };
    }
    case AUTH_LOG_OUT:
      return { ...state, shouldLogout: true };
    case AUTH_ACCEPT_TERMS_SUCCESS:
      return { ...state, shouldAskForConsent: false };
    default:
      return state;
  }
};
