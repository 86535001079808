import {
  addWeekToDate,
  getDateOfFirstDayOfCurrentWeek,
  getDateOfLastDayOfCurrentWeek,
  subtractWeekFromDate,
} from 'utils/dates';

import { actionTypes } from './actions';

const initalLoadingState = {
  loading: false,
  fulfilled: false,
  error: null,
};

const initialState = {
  calendar: {
    dateFrom: getDateOfFirstDayOfCurrentWeek,
    dateTo: getDateOfLastDayOfCurrentWeek,
  },
  changedTableLimits: {},
  selectedFilters: {},
  data: [],
  ...initalLoadingState,
};

const {
  LIMITS_FETCH_REQUEST,
  LIMITS_FETCH_SUCCESS,
  LIMITS_FETCH_FAILURE,
  LIMITS_INCREMENT_CALENDAR_DATE,
  LIMITS_DECREMENT_CALENDAR_DATE,
  LIMITS_SET_SELECTED_DATES_AND_LIMITS,
  LIMITS_SET_SELECTED_FILTERS,
  LIMITS_CLEAR_SELECTED_LIMITS,
  LIMITS_CLEAR_LIMITS_STATE,
  LIMITS_CLEAR_CALENDAR_DATES,
} = actionTypes;

export default (state = initialState, action) => {
  switch (action.type) {
    case LIMITS_FETCH_REQUEST:
      return { ...state, ...initalLoadingState, loading: true };
    case LIMITS_FETCH_SUCCESS:
      return { ...state, ...initalLoadingState, data: action.payload.subjects, fulfilled: true };
    case LIMITS_FETCH_FAILURE:
      return { ...state, ...initalLoadingState, error: action.payload };
    case LIMITS_INCREMENT_CALENDAR_DATE:
      return {
        ...state,
        calendar: {
          dateFrom: addWeekToDate(state.calendar.dateFrom),
          dateTo: addWeekToDate(state.calendar.dateTo),
        },
      };
    case LIMITS_DECREMENT_CALENDAR_DATE:
      return {
        ...state,
        calendar: {
          dateFrom: subtractWeekFromDate(state.calendar.dateFrom),
          dateTo: subtractWeekFromDate(state.calendar.dateTo),
        },
      };
    case LIMITS_SET_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: { ...action.payload },
      };
    case LIMITS_SET_SELECTED_DATES_AND_LIMITS:
      return {
        ...state,
        changedTableLimits: { ...state.changedTableLimits, ...action.payload },
      };
    case LIMITS_CLEAR_SELECTED_LIMITS:
      return {
        ...state,
        changedTableLimits: {},
      };
    case LIMITS_CLEAR_LIMITS_STATE:
      return {
        ...state,
        data: {},
        changedTableLimits: {},
      };
    case LIMITS_CLEAR_CALENDAR_DATES:
      return {
        ...state,
        calendar: {
          dateFrom: getDateOfFirstDayOfCurrentWeek,
          dateTo: getDateOfLastDayOfCurrentWeek,
        },
      };
    default:
      return state;
  }
};
