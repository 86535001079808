import { actionTypes as actionTypesAnswerGroups } from 'store/answerGroups/actions';
import { actionTypes as actionTypesAnsweringMode } from 'store/answeringMode/actions';
import { actionTypes as actionTypesAssignedQuestionsLog } from 'store/assignedQuestionsLog/actions';
import { actionTypes as actionTypesAttachments } from 'store/attachments/actions';
import { actionTypes as actionTypesAuth } from 'store/auth/actions';
import { actionTypes as actionTypesCheckpoints } from 'store/manageEvaluationCheckpoints/actions';
import { actionTypes as actionTypesComments } from 'store/comments/actions';
import { actionTypes as actionTypesEvalContent } from 'store/evaluationFeeds/actions';
import { actionTypes as actionTypesEvalPrivileges } from 'store/evaluationPrivileges/actions';
import { actionTypes as actionTypesEvaluate } from 'store/evaluateAnswer/actions';
import { actionTypes as actionTypesLimits } from 'store/limits/actions';
import { actionTypes as actionTypesPrivileges } from 'store/privileges/actions';
import { actionTypes as actionTypesProjects } from 'store/projects/actions';
import { actionTypes as actionTypesSingleUser } from 'store/singleUser/actions';
import { actionTypes as actionTypesSingleWorkerLog } from 'store/singleWorkerLog/actions';
import { actionTypes as actionTypesUserFeedPrivileges } from 'store/userFeedPrivileges/actions';
import { actionTypes as actionTypesUsers } from 'store/users/actions';
import { actionTypes as actionTypesWorkerLogs } from 'store/workerLogs/actions';
import { getErrorTranslationKey } from 'utils/error';

import { actionTypes } from './actions';

// TODO: when refactoring slices use this functions to add toasts
import { createToastError, createToastSuccess } from './createToast';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    // Handling success
    case actionTypesCheckpoints.MANAGE_EVALUATION_CHECKPOINTS_UPDATE_SUCCESS:
    case actionTypesCheckpoints.MANAGE_EVALUATION_CHECKPOINTS_FETCH_SUCCESS:
    case actionTypesEvalContent.EVALUATION_CONTENT_CREATE_SUCCESS:
    case actionTypesEvalContent.EVALUATION_FEEDS_FETCH_SUCCESS:
    case actionTypesEvalPrivileges.EVALUATION_PRIVILEGES_UPDATE_SUCCESS:
    case actionTypesEvalPrivileges.EVALUATION_PRIVILEGES_FETCH_SUCCESS:
    case actionTypesPrivileges.PRIVILEGES_UPDATE_SUCCESS:
    case actionTypesProjects.PROJECTS_CREATE_PROJECT_SUCCESS:
    case actionTypesProjects.PROJECTS_DISABLE_PROJECT_SUCCESS:
    case actionTypesProjects.PROJECTS_ENABLE_PROJECT_SUCCESS:
    case actionTypesProjects.PROJECTS_UPDATE_PROJECT_SUCCESS:
    case actionTypesAnswerGroups.ANSWER_GROUPS_CREATE_SUCCESS:
    case actionTypesAnswerGroups.ANSWER_GROUPS_DISABLE_GROUP_SUCCESS:
    case actionTypesAnswerGroups.ANSWER_GROUPS_ENABLE_GROUP_SUCCESS:
    case actionTypesAnswerGroups.ANSWER_GROUPS_UPDATE_GROUP_SUCCESS:
    case actionTypesLimits.LIMITS_CREATE_SUCCESS:
    case actionTypesAssignedQuestionsLog.REASSIGN_QUESTIONS_SUCCESS:
    case actionTypesUsers.USERS_CREATE_USER_SUCCESS:
    case actionTypesUsers.USERS_RESET_PASSWORD_SUCCESS:
    case actionTypesUsers.USERS_SET_PASSWORD_SUCCESS:
    case actionTypesUsers.USERS_SET_BRAINLY_ACCOUNT_LINK_SUCCESS:
    case actionTypesUsers.USERS_RESET_BRAINLY_PROFILE_LINK_SUCCESS:
    case actionTypesUsers.USERS_UPDATE_USER_SUCCESS:
    case actionTypesSingleUser.SINGLE_USER_DISABLE_SUCCESS:
    case actionTypesSingleUser.SINGLE_USER_ENABLE_SUCCESS:
    case actionTypesSingleUser.SINGLE_USER_UPDATE_SUCCESS:
    case actionTypesAuth.AUTH_LOG_IN_SUCCESS:
    case actionTypesUserFeedPrivileges.USER_EVAL_FEED_PRIVILEGES_FETCH_SUCCESS:
      return [...state, createToastSuccess(action.meta.successToastMessage)];

    // Handling failure
    case actionTypesCheckpoints.MANAGE_EVALUATION_CHECKPOINTS_FETCH_FAILURE:
    case actionTypesCheckpoints.MANAGE_EVALUATION_CHECKPOINTS_UPDATE_FAILURE:
    case actionTypesEvalContent.EVALUATION_CONTENT_CREATE_FAILURE:
    case actionTypesEvalContent.EVALUATION_FEEDS_FETCH_FAILURE:
    case actionTypesEvalPrivileges.EVALUATION_PRIVILEGES_UPDATE_FAILURE:
    case actionTypesEvalPrivileges.EVALUATION_PRIVILEGES_FETCH_FAILURE:
    case actionTypesPrivileges.PRIVILEGES_UPDATE_FAILURE:
    case actionTypesPrivileges.PRIVILEGES_FETCH_FAILURE:
    case actionTypesProjects.PROJECTS_FETCH_FAILURE:
    case actionTypesProjects.PROJECTS_CREATE_PROJECT_FAILURE:
    case actionTypesProjects.PROJECTS_DISABLE_PROJECT_FAILURE:
    case actionTypesProjects.PROJECTS_ENABLE_PROJECT_FAILURE:
    case actionTypesProjects.PROJECTS_UPDATE_PROJECT_FAILURE:
    case actionTypesAssignedQuestionsLog.ASSIGNED_QUESTIONS_LOG_FETCH_FAILURE:
    case actionTypesAssignedQuestionsLog.REASSIGN_QUESTIONS_FAILURE:
    case actionTypesWorkerLogs.WORKER_LOGS_FETCH_FAILURE:
    case actionTypesSingleWorkerLog.SINGLE_WORKER_LOG_FETCH_FAILURE:
    case actionTypesAnswerGroups.ANSWER_GROUPS_CREATE_FAILURE:
    case actionTypesAnswerGroups.ANSWER_GROUPS_DISABLE_GROUP_FAILURE:
    case actionTypesAnswerGroups.ANSWER_GROUPS_ENABLE_GROUP_FAILURE:
    case actionTypesAnswerGroups.ANSWER_GROUPS_UPDATE_GROUP_FAILURE:
    case actionTypesLimits.LIMITS_FETCH_FAILURE:
    case actionTypesLimits.LIMITS_CREATE_FAILURE:
    case actionTypesUsers.USERS_CREATE_USER_FAILURE:
    case actionTypesUsers.USERS_RESET_PASSWORD_FAILURE:
    case actionTypesUsers.USERS_SET_PASSWORD_FAILURE:
    case actionTypesUsers.USERS_SET_BRAINLY_ACCOUNT_LINK_FAILURE:
    case actionTypesUsers.USERS_RESET_BRAINLY_PROFILE_LINK_FAILURE:
    case actionTypesUsers.USERS_UPDATE_USER_FAILURE:
    case actionTypesSingleUser.SINGLE_USER_ENABLE_FAILURE:
    case actionTypesSingleUser.SINGLE_USER_DISABLE_FAILURE:
    case actionTypesSingleUser.SINGLE_USER_UDPDATE_FAILURE:
    case actionTypesAttachments.ATTACHMENTS_FETCH_FAILURE:
    case actionTypesComments.COMMENTS_FETCH_FAILURE:
    case actionTypesAnsweringMode.ANSWERING_MODE_OPEN_FAILURE:
    case actionTypesAnsweringMode.ANSWERING_MODE_CANCEL_FAILURE:
    case actionTypesAuth.AUTH_LOG_IN_FAILURE:
    case actionTypesAuth.AUTH_ACCEPT_TERMS_FAILURE:
    case actionTypesEvaluate.EVALUATE_ANSWERS_MARK_AS_FAULTY_FAILURE:
    case actionTypesEvaluate.EVALUATE_ANSWERS_SKIP_FAILURE:
    case actionTypesEvaluate.EVALUATE_ANSWERS_RATE_FAILURE:
    case actionTypesUserFeedPrivileges.USER_EVAL_FEED_PRIVILEGES_FETCH_FAILURE:
      return [...state, createToastError(getErrorTranslationKey(action.payload, action.meta.errorToastMessageConfig))];

    // TODO: remove TOASTS_ADD_TOAST case when all the slices are refactored
    case actionTypes.TOASTS_ADD_TOAST:
      return [...state, action.payload];
    case actionTypes.TOASTS_REMOVE_TOAST:
      return state.filter(toast => toast.id !== action.payload.id);
    default:
      return state;
  }
};
