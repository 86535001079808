import { CALL_API } from 'store/middlewares/api';
import { ERROR_CODE } from 'utils/error';
import { fetchCurrentUserSession } from 'store/auth/actions';
import { getApiMarket, getApiPrefix } from 'store/utils';
import { getUserListSearch } from 'store/search/selectors';
import { getUsersFilters } from 'store/usersFilters/selectors';

import { getCurrentUsersOffset } from './selectors';

const DEFAULT_NUMBRE_OF_USERS_PER_PAGE = 50;

const defaultGetUsersParams = {
  limit: DEFAULT_NUMBRE_OF_USERS_PER_PAGE,
  offset: 0,
  search: '',
};

export const actionTypes = {
  USERS_FETCH: 'USERS_FETCH',
  USERS_FETCH_REQUEST: 'USERS_FETCH_REQUEST',
  USERS_FETCH_SUCCESS: 'USERS_FETCH_SUCCESS',
  USERS_FETCH_FAILURE: 'USERS_FETCH_FAILURE',

  USERS_LOAD_MORE: 'USERS_LOAD_MORE',
  USERS_LOAD_MORE_REQUEST: 'USERS_LOAD_MORE_REQUEST',
  USERS_LOAD_MORE_SUCCESS: 'USERS_LOAD_MORE_SUCCESS',
  USERS_LOAD_MORE_FAILURE: 'USERS_LOAD_MORE_FAILURE',

  USERS_CREATE_USER: 'USERS_CREATE_USER',
  USERS_CREATE_USER_REQUEST: 'USERS_CREATE_USER_REQUEST',
  USERS_CREATE_USER_SUCCESS: 'USERS_CREATE_USER_SUCCESS',
  USERS_CREATE_USER_FAILURE: 'USERS_CREATE_USER_FAILURE',

  USERS_RESET_PASSWORD: 'USERS_RESET_PASSWORD',
  USERS_RESET_PASSWORD_REQUEST: 'USERS_RESET_PASSWORD_REQUEST',
  USERS_RESET_PASSWORD_SUCCESS: 'USERS_RESET_PASSWORD_SUCCESS',
  USERS_RESET_PASSWORD_FAILURE: 'USERS_RESET_PASSWORD_FAILURE',

  USERS_SET_PASSWORD: 'USERS_SET_PASSWORD',
  USERS_SET_PASSWORD_REQUEST: 'USERS_SET_PASSWORD_REQUEST',
  USERS_SET_PASSWORD_SUCCESS: 'USERS_SET_PASSWORD_SUCCESS',
  USERS_SET_PASSWORD_FAILURE: 'USERS_SET_PASSWORD_FAILURE',

  USERS_SET_BRAINLY_ACCOUNT_LINK: 'USERS_SET_BRAINLY_ACCOUNT_LINK',
  USERS_SET_BRAINLY_ACCOUNT_LINK_REQUEST: 'USERS_SET_BRAINLY_ACCOUNT_LINK_REQUEST',
  USERS_SET_BRAINLY_ACCOUNT_LINK_SUCCESS: 'USERS_SET_BRAINLY_ACCOUNT_LINK_SUCCESS',
  USERS_SET_BRAINLY_ACCOUNT_LINK_FAILURE: 'USERS_SET_BRAINLY_ACCOUNT_LINK_FAILURE',

  USERS_RESET_BRAINLY_PROFILE_LINK: 'USERS_RESET_BRAINLY_PROFILE_LINK',
  USERS_RESET_BRAINLY_PROFILE_LINK_REQUEST: 'USERS_RESET_BRAINLY_PROFILE_LINK_REQUEST',
  USERS_RESET_BRAINLY_PROFILE_LINK_SUCCESS: 'USERS_RESET_BRAINLY_PROFILE_LINK_SUCCESS',
  USERS_RESET_BRAINLY_PROFILE_LINK_FAILURE: 'USERS_RESET_BRAINLY_PROFILE_LINK_FAILURE',

  USERS_UPLOAD_AVATAR: 'USERS_UPLOAD_AVATAR',
  USERS_UPLOAD_AVATAR_REQUEST: 'USERS_UPLOAD_AVATAR_REQUEST',
  USERS_UPLOAD_AVATAR_SUCCESS: 'USERS_UPLOAD_AVATAR_SUCCESS',
  USERS_UPLOAD_AVATAR_FAILURE: 'USERS_UPLOAD_AVATAR_FAILURE',

  USERS_DELETE_AVATAR: 'USERS_DELETE_AVATAR',
  USERS_DELETE_AVATAR_REQUEST: 'USERS_DELETE_AVATAR_REQUEST',
  USERS_DELETE_AVATAR_SUCCESS: 'USERS_DELETE_AVATAR_SUCCESS',
  USERS_DELETE_AVATAR_FAILURE: 'USERS_DELETE_AVATAR_FAILURE',
};

export const getUsers = () => (dispatch, getState) => {
  const state = getState();
  const search = getUserListSearch(state);
  const usersFilters = getUsersFilters(state);
  dispatch({
    [CALL_API]: {
      type: actionTypes.USERS_FETCH,
      payload: {
        endpoint: `/${getApiMarket()}${getApiPrefix()}/users`,
        method: 'get',
        params: {
          ...defaultGetUsersParams,
          search,
          ...usersFilters,
        },
      },
      meta: {},
    },
  });
};

export const loadMoreUsers = () => (dispatch, getState) => {
  const state = getState();
  const offset = getCurrentUsersOffset(state);
  const search = getUserListSearch(state);
  const usersFilters = getUsersFilters(state);
  dispatch({
    [CALL_API]: {
      type: actionTypes.USERS_LOAD_MORE,
      payload: {
        endpoint: `/${getApiMarket()}${getApiPrefix()}/users`,
        method: 'get',
        params: {
          ...defaultGetUsersParams,
          offset,
          search,
          ...usersFilters,
        },
      },
      meta: {},
    },
  });
};

export const createUser = values => ({
  [CALL_API]: {
    type: actionTypes.USERS_CREATE_USER,
    payload: {
      endpoint: `/${getApiMarket()}${getApiPrefix()}/users`,
      method: 'post',
      ...values,
    },
    meta: {
      successToastMessage: 'userCreated',
      errorToastMessageConfig: {
        [ERROR_CODE.CONFLICT]: { default: 'userExists' },
        [ERROR_CODE.UNPROCESSABLE_ENTITY]: { default: 'createValidationError' },
      },
    },
  },
});

export const resetPassword = id => ({
  [CALL_API]: {
    type: actionTypes.USERS_RESET_PASSWORD,
    payload: {
      endpoint: `/${getApiMarket()}${getApiPrefix()}/users/${id}/reset-password`,
      method: 'post',
    },
    meta: {
      successToastMessage: 'userPasswordReset',
    },
  },
});

export const setPassword = (id, password) => async dispatch => {
  await dispatch({
    [CALL_API]: {
      type: actionTypes.USERS_SET_PASSWORD,
      payload: {
        endpoint: `/${getApiMarket()}/users/${id}/set-password`,
        method: 'post',
        password,
      },
      meta: {
        successToastMessage: 'userPasswordChanged',
        errorToastMessageConfig: {
          [ERROR_CODE.UNPROCESSABLE_ENTITY]: { default: 'invalidPassword' },
        },
      },
    },
  });
  dispatch(fetchCurrentUserSession());
};

export const setBrainlyAccountLink = ({ id, ...body }) => async dispatch => {
  await dispatch({
    [CALL_API]: {
      type: actionTypes.USERS_SET_BRAINLY_ACCOUNT_LINK,
      payload: {
        endpoint: `/${getApiMarket()}/users/${id}/brainly-sign-in`,
        method: 'post',
        ...body,
      },
      meta: {
        successToastMessage: 'userBrainlyAccountLinkSet',
        errorToastMessageConfig: {
          [ERROR_CODE.CONFLICT]: { default: 'brainlyLinkExists' },
          [ERROR_CODE.UNPROCESSABLE_ENTITY]: { default: 'invalidBrainlyLink' },
        },
      },
    },
  });
  dispatch(fetchCurrentUserSession());
};

export const resetBrainlyProfileLink = id => ({
  [CALL_API]: {
    type: actionTypes.USERS_RESET_BRAINLY_PROFILE_LINK,
    payload: {
      endpoint: `/${getApiMarket()}${getApiPrefix()}/users/${id}/unlink-brainly-profile`,
      method: 'post',
    },
    meta: {
      successToastMessage: 'userBrainlyProfileLinkReset',
    },
  },
});
