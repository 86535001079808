import uuid from 'uuid/v1';

import { TOAST_TYPE } from 'const/styles';

const createToast = (type, defaultMessage) => (translationKey = defaultMessage) => {
  return { id: uuid(), type, translationKey };
};

export const createToastSuccess = createToast(TOAST_TYPE.SUCCESS, 'defaultSuccessMessage');
export const createToastError = createToast(TOAST_TYPE.ERROR);
