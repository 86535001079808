import { actionTypes } from './actions';

const initialState = {
  data: [],
  loading: false,
  fulfilled: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROJECTS_FETCH_REQUEST:
      return { ...initialState, loading: true };
    case actionTypes.PROJECTS_FETCH_SUCCESS:
      return {
        ...initialState,
        data: action.payload.projects,
        fulfilled: true,
      };
    case actionTypes.PROJECTS_FETCH_FAILURE:
      return { ...initialState, error: action.payload };
    default:
      return state;
  }
};
