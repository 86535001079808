import { createSelector } from 'reselect';

const getQuestionsFeed = createSelector(
  state => state.questionsFeed,
  questionsCounters => questionsCounters,
);

export const getQuestions = createSelector(
  getQuestionsFeed,
  ({ questions }) => questions,
);

export const getIsQuestionsFeedLoading = createSelector(
  getQuestionsFeed,
  ({ loading }) => loading,
);

export const getIsQuestionsFeedFulfilled = createSelector(
  getQuestionsFeed,
  ({ fulfilled }) => fulfilled,
);

export const getCurrentQuestionsFeedType = createSelector(
  getQuestionsFeed,
  ({ feedType }) => feedType,
);

export const getSkippedQuestions = createSelector(
  getQuestionsFeed,
  ({ skippedQuestions }) => skippedQuestions,
);

export const getQuestionsFeedTotalCount = createSelector(
  getQuestionsFeed,
  ({ totalCount }) => totalCount,
);

export const getFirstQuestion = createSelector(
  getQuestions,
  ([question]) => question || {},
);

export const getFirstQuestionTimestamp = createSelector(
  getFirstQuestion,
  ({ createdTimestamp }) => createdTimestamp || null,
);

export const getCurrentOffset = createSelector(
  getQuestionsFeed,
  ({ offset }) => offset,
);

export const getActiveFilters = createSelector(
  getQuestionsFeed,
  ({ activeFilters }) => activeFilters,
);

export const getNewQuestionsAmount = createSelector(
  getQuestionsFeed,
  ({ newQuestions }) => newQuestions,
);
