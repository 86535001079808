import { TOAST_TYPE } from 'const/styles';
import { addToast } from 'store/toasts/actions';

export const UNKNOWN_ERROR_TYPE = 'UNKNOWN_ERROR';

const DEFAULT_ERROR_TRANSLATION_KEY = 'responseFailed';

export const ERROR_CODE = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  UNPROCESSABLE_ENTITY: 422,
};

/**
 * @param errorResponse
 * @returns {ErrorInterface}}
 */
export const getErrorMessage = errorResponse => {
  if (errorResponse) {
    return {
      httpStatus: errorResponse.status,
      message: errorResponse.data.error,
      type: errorResponse.data.type,
    };
  }

  return {
    type: UNKNOWN_ERROR_TYPE,
    message: null,
    httpStatus: null,
  };
};

const defaultErrorHandlingConfig = {
  [ERROR_CODE.FORBIDDEN]: { USER_IS_NOT_ALLOWED_TO_DISABLE_GIVEN_USER: 'requestForbidden' },
};

export const getErrorTranslationKey = ({ httpStatus, message, type }, handlingConfig = defaultErrorHandlingConfig) => {
  const translationKeys = handlingConfig[httpStatus] || { default: DEFAULT_ERROR_TRANSLATION_KEY };
  const translation =
    translationKeys[message] || translationKeys[type] || translationKeys.default || DEFAULT_ERROR_TRANSLATION_KEY;
  return translation;
};

/**
 * @param { ErrorInterface } error
 * @param handlingConfig
 */
// TODO remove handle APIErrorAction when refactored all slices
// Toast should be handled only by toast reducer
export const handleAPIErrorAction = (error, handlingConfig = defaultErrorHandlingConfig) => {
  return addToast(TOAST_TYPE.ERROR, getErrorTranslationKey(error, handlingConfig));
};
