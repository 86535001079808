import { createSelector } from 'reselect';

const getAnswerGroupUsers = createSelector(
  state => state.answerGroupUsers,
  answerGroupUsers => answerGroupUsers,
);

export const areAnswerGroupUsersLoaded = createSelector(
  getAnswerGroupUsers,
  answerGroupUsers => answerGroupUsers.fulfilled,
);

export const areAnswerGroupUsersLoading = createSelector(
  getAnswerGroupUsers,
  answerGroupUsers => answerGroupUsers.loading,
);

export const getAnswerGroupUsersData = createSelector(
  getAnswerGroupUsers,
  answerGroupUsers => answerGroupUsers.data,
);

export const getAnswerGroupUsersTotalCount = createSelector(
  getAnswerGroupUsers,
  answerGroupUsers => answerGroupUsers.totalCount,
);

export const getCurrentAnswerGroupUsersOffset = createSelector(
  getAnswerGroupUsers,
  answerGroupUsers => answerGroupUsers.offset,
);
