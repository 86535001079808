import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';

import api from './middlewares/api';
import rootReducer from './rootReducer';

const { NODE_ENV } = process.env;
const { hot: HotReload } = module;
const isDevelopment = NODE_ENV === 'development';

export function configureAppStore(initialState) {
  const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(api, thunk)));

  if (isDevelopment && HotReload) {
    HotReload.accept('./reducers', () => store.replaceReducer(rootReducer));
  }

  return store;
}

const store = configureAppStore();

export default store;
