import { loadingState, successState } from 'store/consts';

import { actionTypes } from './actions';

const initialState = {
  data: {},
  loading: false,
  fulfilled: false,
  error: null,
  score: undefined,
  currentFeed: null,
  endCursor: null,
  count: 0,
  filters: {},
  checkpoints: {
    loading: false,
    fulfilled: false,
    error: null,
    data: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EVALUATE_ANSWER_SET_CURRENT_FEED:
      return {
        ...initialState,
        currentFeed: action.payload,
      };
    case actionTypes.EVALUATE_ANSWERS_GET_DATA_REQUEST:
    case actionTypes.EVALUATE_ANSWERS_MARK_AS_FAULTY_REQUEST:
    case actionTypes.EVALUATE_ANSWERS_SKIP_REQUEST:
    case actionTypes.EVALUATE_ANSWERS_RATE_REQUEST:
      return {
        ...state,
        ...loadingState,
      };
    case actionTypes.EVALUATE_ANSWERS_GET_DATA_SUCCESS:
      return {
        ...state,
        ...successState,
        data: action.payload,
      };
    case actionTypes.EVALUATE_ANSWERS_GET_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.EVALUATE_ANSWER_SET_ANSWER_FILTERS:
      return {
        ...initialState,
        currentFeed: state.currentFeed,
        filters: action.payload,
      };
    case actionTypes.EVALUATE_ANSWER_SET_CURRENT_SCORE:
      return {
        ...state,
        score: action.payload,
      };
    case actionTypes.EVALUATE_ANSWERS_MARK_AS_FAULTY_SUCCESS:
    case actionTypes.EVALUATE_ANSWERS_SKIP_SUCCESS:
    case actionTypes.EVALUATE_ANSWERS_RATE_SUCCESS:
      return {
        ...initialState,
        currentFeed: state.currentFeed,
        data: { count: state.data.count },
        endCursor: state.data.endCursor,
        filters: state.filters,
      };
    case actionTypes.EVALUATE_ANSWERS_MARK_AS_FAULTY_FAILURE:
    case actionTypes.EVALUATE_ANSWERS_SKIP_FAILURE:
    case actionTypes.EVALUATE_ANSWERS_RATE_FAILURE:
      return {
        ...initialState,
        currentFeed: state.currentFeed,
      };
    case actionTypes.FREELANCER_EVALUATION_CHECKPOINTS_FETCH_REQUEST:
      return {
        ...state,
        checkpoints: { loading: true, fulfilled: false, error: null, data: null },
      };
    case actionTypes.FREELANCER_EVALUATION_CHECKPOINTS_FETCH_SUCCESS:
      return {
        ...state,
        checkpoints: {
          loading: false,
          fulfilled: true,
          error: null,
          data: {
            feedId: action.payload.feedId,
            market: action.payload.market,
            content: JSON.parse(action.payload.content),
            default: action.payload.default,
          },
        },
      };
    case actionTypes.FREELANCER_EVALUATION_CHECKPOINTS_FETCH_FAILURE:
      return {
        ...state,
        checkpoints: { loading: false, fulfilled: true, error: action.payload, data: null },
      };
    default:
      return state;
  }
};
