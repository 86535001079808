import PropTypes from 'prop-types';
import React from 'react';

const StringToHtml = ({ content, ...props }) => {
  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: content }} {...props} />;
};

StringToHtml.propTypes = {
  content: PropTypes.string,
};

StringToHtml.defaultProps = {
  content: '',
};

export default StringToHtml;
