import PropTypes from 'prop-types';

export const childrenPropType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
  PropTypes.func,
  PropTypes.string,
]);

export const selectOptionsPropType = PropTypes.arrayOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ label: PropTypes.string, value: PropTypes.any })]),
);

export const tableCellPropType = PropTypes.oneOfType([
  PropTypes.shape({
    content: PropTypes.oneOfType([PropTypes.string, childrenPropType]),
    style: PropTypes.string,
  }),
  PropTypes.oneOfType([PropTypes.string, childrenPropType]),
]);

export const tableRowPropType = PropTypes.arrayOf(tableCellPropType);
