import { useTranslation } from 'react-i18next';
import React from 'react';

import { HEADLINE_SIZE } from 'const/styles';
import { Headline, Logo } from 'ui/index';

import style from './TopBar.module.scss';

const AppDetails = () => {
  const { t } = useTranslation('AppDetails');
  return (
    <div className='sg-header__left'>
      <Logo />
      <Headline value={t('appName')} className={style.appName} size={HEADLINE_SIZE.SMALL} />
    </div>
  );
};

export default AppDetails;
