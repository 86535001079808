import { CALL_API } from 'store/middlewares/api';
import { getApiMarket } from 'store/utils';

export const actionTypes = {
  FILTERS_GET_GRADES: 'FILTERS_GET_GRADES',
  FILTERS_GET_GRADES_REQUEST: 'FILTERS_GET_GRADES_REQUEST',
  FILTERS_GET_GRADES_SUCCESS: 'FILTERS_GET_GRADES_SUCCESS',
  FILTERS_GET_GRADES_FAILURE: 'FILTERS_GET_GRADES_FAILURE',
  FILTERS_GET_SUBJECTS: 'FILTERS_GET_SUBJECTS',
  FILTERS_GET_SUBJECTS_REQUEST: 'FILTERS_GET_SUBJECTS_REQUEST',
  FILTERS_GET_SUBJECTS_SUCCESS: 'FILTERS_GET_SUBJECTS_SUCCESS',
  FILTERS_GET_SUBJECTS_FAILURE: 'FILTERS_GET_SUBJECTS_FAILURE',
  FILTERS_GET_PROJECTS: 'FILTERS_GET_PROJECTS',
  FILTERS_GET_PROJECTS_REQUEST: 'FILTERS_GET_PROJECTS_REQUEST',
  FILTERS_GET_PROJECTS_SUCCESS: 'FILTERS_GET_PROJECTS_SUCCESS',
  FILTERS_GET_PROJECTS_FAILURE: 'FILTERS_GET_PROJECTS_FAILURE',
};

export const getGradesFilters = () => ({
  [CALL_API]: {
    type: actionTypes.FILTERS_GET_GRADES,
    payload: {
      endpoint: `/${getApiMarket()}/grades`,
      method: 'get',
    },
    meta: {},
  },
});

export const getSubjectsFilters = () => ({
  [CALL_API]: {
    type: actionTypes.FILTERS_GET_SUBJECTS,
    payload: {
      endpoint: `/${getApiMarket()}/subjects`,
      method: 'get',
    },
    meta: {},
  },
});

export const getProjectsFilters = () => ({
  [CALL_API]: {
    type: actionTypes.FILTERS_GET_PROJECTS,
    payload: {
      endpoint: `/${getApiMarket()}/projects`,
      method: 'get',
    },
    meta: {},
  },
});
