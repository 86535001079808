import { actionTypes } from './actions';

const initialState = {
  data: {},
  fetchedQuestionId: null,
  loading: false,
  fulfilled: false,
  error: null,
};

const { COMMENTS_FETCH_REQUEST, COMMENTS_FETCH_SUCCESS, COMMENTS_FETCH_FAILURE, COMMENTS_REMOVE } = actionTypes;

export default (state = initialState, action) => {
  switch (action.type) {
    case COMMENTS_FETCH_REQUEST:
      return { ...initialState, fetchedQuestionId: action.meta.questionId, loading: true, data: { ...state.data } };
    case COMMENTS_FETCH_SUCCESS:
      return {
        ...initialState,
        fulfilled: true,
        fetchedQuestionId: state.fetchedQuestionId,
        data: { ...state.data, [action.meta.questionId]: action.payload.comments },
      };
    case COMMENTS_FETCH_FAILURE:
      return {
        ...initialState,
        error: action.payload,
        fetchedQuestionId: state.fetchedQuestionId,
        data: { ...state.data },
      };
    case COMMENTS_REMOVE: {
      const { [action.payload]: removedComments, ...restComments } = state.data;
      return {
        ...state,
        data: { ...restComments },
      };
    }
    default:
      return state;
  }
};
