import { CALL_API, UPLOAD_API } from 'store/middlewares/api';
import { ERROR_CODE } from 'utils/error';
import { getApiMarket } from 'store/utils';

export const actionTypes = {
  EVALUATION_CONTENT_CREATE: 'EVALUATION_CONTENT_CREATE',
  EVALUATION_CONTENT_CREATE_REQUEST: 'EVALUATION_CONTENT_CREATE_REQUEST',
  EVALUATION_CONTENT_CREATE_SUCCESS: 'EVALUATION_CONTENT_CREATE_SUCCESS',
  EVALUATION_CONTENT_CREATE_FAILURE: 'EVALUATION_CONTENT_CREATE_FAILURE',
  EVALUATION_FEEDS_FETCH: 'EVALUATION_FEEDS_FETCH',
  EVALUATION_FEEDS_FETCH_REQUEST: 'EVALUATION_FEEDS_FETCH_REQUEST',
  EVALUATION_FEEDS_FETCH_SUCCESS: 'EVALUATION_FEEDS_FETCH_SUCCESS',
  EVALUATION_FEEDS_FETCH_FAILURE: 'EVALUATION_FEEDS_FETCH_FAILURE',
};

export const createEvaluationFeed = csvfile => ({
  [UPLOAD_API]: {
    type: actionTypes.EVALUATION_CONTENT_CREATE,
    payload: {
      endpoint: `/${getApiMarket()}/evaluation/upload-answers`,
      method: 'post',
      csvfile,
    },
    meta: {
      successToastMessage: 'evaluationFeedAdded',
      errorToastMessageConfig: {
        [ERROR_CODE.BAD_REQUEST]: { default: 'uploadContentBadRequest' },
        [ERROR_CODE.UNAUTHORIZED]: { default: 'uploadContentUnauthorized' },
        [ERROR_CODE.FORBIDDEN]: { default: 'uploadContentForbidden' },
        [ERROR_CODE.NOT_FOUND]: { default: 'uploadContentNotFound' },
        [ERROR_CODE.CONFLICT]: { default: 'uploadContentConflict' },
        [ERROR_CODE.UNPROCESSABLE_ENTITY]: {
          default: 'uploadContentUnprocessable',
        },
      },
    },
  },
});

export const getEvaluationFeeds = () => ({
  [CALL_API]: {
    type: actionTypes.EVALUATION_FEEDS_FETCH,
    payload: {
      endpoint: `/${getApiMarket()}/evaluation/feeds`,
      method: 'get',
    },
    meta: {},
  },
});
