import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { TOAST_TYPE } from 'const/styles';

import style from './Toast.module.scss';

const TOAST_LIFESPAN = 2000;
const ANIMATION_TIMEOUT = parseInt(style.animationTime, 10);

const Toast = ({ className, translationKey, type, onDismissClick }) => {
  const [hidden, hideToast] = useState(false);
  const { t } = useTranslation('Toast');

  useEffect(() => {
    const autoToastHideTimeout = setTimeout(() => hideToast(true), TOAST_LIFESPAN);
    const autoToastRemoveTimeout = setTimeout(onDismissClick, TOAST_LIFESPAN + ANIMATION_TIMEOUT / 2);
    return () => {
      clearTimeout(autoToastHideTimeout);
      clearTimeout(autoToastRemoveTimeout);
    };
  }, [onDismissClick]);

  const classNames = cx(style.toast, {
    [style.hidden]: hidden,
    'sg-flash__message': true,
    [`sg-flash__message--${type}`]: !!type,
    [className]: !!className,
  });

  return (
    <div className={`sg-flash ${style.sgFlash}`}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div className={classNames} onClick={onDismissClick}>
        <div className='sg-text sg-text--small sg-text--default sg-text--bold'>{t(translationKey)}</div>
      </div>
    </div>
  );
};

Toast.propTypes = {
  className: PropTypes.string,
  onDismissClick: PropTypes.func,
  translationKey: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(TOAST_TYPE)),
};

Toast.defaultProps = {
  className: null,
  onDismissClick: null,
  type: TOAST_TYPE.DEFAULT,
};

export default Toast;
