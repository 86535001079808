import { actionTypes } from './actions';

const initialState = {
  data: {},
  loading: false,
  fulfilled: false,
  error: null,
};

const {
  SINGLE_USER_FETCH_REQUEST,
  SINGLE_USER_FETCH_SUCCESS,
  SINGLE_USER_FETCH_FAILURE,
  SINGLE_USER_DISABLE_SUCCESS,
  SINGLE_USER_ENABLE_SUCCESS,
  SINGLE_USER_UPDATE_SUCCESS,
} = actionTypes;

export default (state = initialState, action) => {
  switch (action.type) {
    case SINGLE_USER_FETCH_REQUEST:
      return { ...initialState, loading: true };
    case SINGLE_USER_FETCH_SUCCESS:
      return { ...initialState, data: action.payload, fulfilled: true };
    case SINGLE_USER_FETCH_FAILURE:
      return { ...initialState, error: action.payload };

    case SINGLE_USER_ENABLE_SUCCESS:
      return { ...state, data: { ...state.data, disabledAt: null } };
    case SINGLE_USER_DISABLE_SUCCESS:
      return { ...state, data: { ...state.data, disabledAt: new Date().toISOString() } };
    case SINGLE_USER_UPDATE_SUCCESS:
      return { ...state, data: { ...action.payload } };
    default:
      return state;
  }
};
