import Lockr from 'lockr';

import { FEED_TYPE } from 'const/feeds';

Lockr.prefix = 'ct_';

export const LOCALSTORAGE_KEYS = {
  SKIPPED_QUESTIONS: (userId, feedType) => `${userId}_${feedType}_skipped`,
};

export const addSkippedQuestionForUser = (userId, questionId, feedType = FEED_TYPE.FAST_ANSWERING) => {
  const key = LOCALSTORAGE_KEYS.SKIPPED_QUESTIONS(userId, feedType);
  Lockr.sadd(key, questionId);
};

export const getSkippedQuestionForUser = (userId, feedType = FEED_TYPE.FAST_ANSWERING) => {
  const key = LOCALSTORAGE_KEYS.SKIPPED_QUESTIONS(userId, feedType);

  return (Lockr.smembers(key) || []).map(id => Number(id));
};

export const clearSkippedQuestionForUser = (userId, feedType = FEED_TYPE.FAST_ANSWERING) => {
  const key = LOCALSTORAGE_KEYS.SKIPPED_QUESTIONS(userId, feedType);

  return Lockr.rm(key);
};
