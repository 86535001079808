import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import { LABEL_TYPE, TEXTAREA_TYPE, TEXT_TYPE } from 'const/styles';
import { Label } from 'ui';

import style from './TextArea.module.scss';

const TextArea = ({ placeholder, type, label, required, onChange, value, error, maxLength, minLength, className }) => {
  const classNames = cx(style.textArea, {
    'sg-textarea': true,
    'sg-textarea--invalid': error,
    [`sg-textarea--${type}`]: !!type,
  });

  return (
    <>
      {label && (
        <Label
          type={LABEL_TYPE.TRANSPARENT}
          labelText={`${TEXT_TYPE.TEXT_BLACK},${TEXT_TYPE.SMALL},${TEXT_TYPE.BOLD}`}
          isRequired={required}
        >
          {label}
        </Label>
      )}
      <textarea
        className={`${className} ${classNames}`}
        placeholder={placeholder}
        onChange={onChange}
        maxLength={maxLength}
        minLength={minLength}
        value={value}
      />
    </>
  );
};

TextArea.propTypes = {
  error: PropTypes.bool,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(TEXTAREA_TYPE)),
  value: PropTypes.string,
  className: PropTypes.string,
};

TextArea.defaultProps = {
  error: false,
  label: null,
  maxLength: null,
  minLength: null,
  placeholder: '',
  required: false,
  type: '',
  value: '',
  className: '',
};

export default TextArea;
