import PropTypes from 'prop-types';
import React from 'react';

import { ICON_STYLE } from 'const/styles';

import Icon from 'ui/Icon/Icon';
import style from './CloseButton.module.scss';

const CloseButton = ({ onClick, fillColor }) => {
  return (
    <button className={`sg-toplayer__close ${style.closeBtn}`} onClick={onClick}>
      <Icon iconName='close' fillColor={fillColor} size={24} />
    </button>
  );
};

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  fillColor: PropTypes.string,
};

CloseButton.defaultProps = {
  fillColor: ICON_STYLE.ICON_BLACK,
};

export default CloseButton;
