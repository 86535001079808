import { actionTypes } from './actions';

const initialState = {
  userListSearch: '',
  answerGroupsListSearch: '',
  answerGroupUsersSearch: '',
  addUsersToGroupSearch: '',
  projectsSearch: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_SET:
      return { ...initialState, ...action.payload };
    default:
      return state;
  }
};
