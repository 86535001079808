import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React from 'react';

import { getCurrentUserData } from 'store/auth/selectors';
import { loginPath, logoutPath, privacyInformationPath, privacyPolicyPath, termsOfUsePath } from 'const/routing';
import AccountInfo from './AccountInfo';
import AppDetails from './AppDetails';
import SocketConnectionAlert from './SocketConnectionAlert';

import style from './TopBar.module.scss';

const TopBar = () => {
  const { email } = useSelector(getCurrentUserData);

  const history = useHistory();
  if (
    [loginPath, logoutPath, privacyInformationPath, privacyPolicyPath, termsOfUsePath].includes(
      history.location.pathname,
    )
  ) {
    return null;
  }
  return (
    <>
      <header className={`sg-header ${style.header}`}>
        <div className='sg-header__container'>
          <div className={`sg-header__content ${style.headerContent}`}>
            <AppDetails />
            <div className='sg-header__middle' />
            <If condition={email}>
              <AccountInfo email={email} />
            </If>
          </div>
        </div>
      </header>
      <SocketConnectionAlert />
    </>
  );
};

export default TopBar;
