import { CALL_API } from 'store/middlewares/api';
import { getApiMarket } from 'store/utils';

export const actionTypes = {
  COMMENTS_FETCH: 'COMMENTS_FETCH',
  COMMENTS_FETCH_REQUEST: 'COMMENTS_FETCH_REQUEST',
  COMMENTS_FETCH_SUCCESS: 'COMMENTS_FETCH_SUCCESS',
  COMMENTS_FETCH_FAILURE: 'COMMENTS_FETCH_FAILURE',

  COMMENTS_REMOVE: 'COMMENTS_REMOVE',
};

export const getComments = (feed, questionId) => ({
  [CALL_API]: {
    type: actionTypes.COMMENTS_FETCH,
    payload: {
      endpoint: `/${getApiMarket()}/questions/${feed}/${questionId}/comments`,
      method: 'get',
    },
    meta: {
      questionId,
    },
  },
});

export const removeComments = questionId => ({
  type: actionTypes.COMMENTS_REMOVE,
  payload: questionId,
});
