import { actionTypes } from './actions';

const initialState = {
  data: {},
  fetchedQuestionId: null,
  loading: false,
  fulfilled: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ATTACHMENTS_FETCH_REQUEST:
      return { ...initialState, fetchedQuestionId: action.meta.questionId, loading: true, data: { ...state.data } };
    case actionTypes.ATTACHMENTS_FETCH_SUCCESS:
      return {
        ...initialState,
        fulfilled: true,
        fetchedQuestionId: state.fetchedQuestionId,
        data: { ...state.data, [action.meta.questionId]: action.payload.attachments },
      };
    case actionTypes.ATTACHMENTS_FETCH_FAILURE:
      return {
        ...initialState,
        error: action.payload,
        fetchedQuestionId: state.fetchedQuestionId,
        data: { ...state.data },
      };
    default:
      return state;
  }
};
