import moment from 'moment';

import { CALL_API } from 'store/middlewares/api';
import { ERROR_CODE } from 'utils/error';
import { getApiMarket } from 'store/utils';

export const actionTypes = {
  LIMITS_FETCH: 'LIMITS_FETCH',
  LIMITS_FETCH_REQUEST: 'LIMITS_FETCH_REQUEST',
  LIMITS_FETCH_SUCCESS: 'LIMITS_FETCH_SUCCESS',
  LIMITS_FETCH_FAILURE: 'LIMITS_FETCH_FAILURE',

  LIMITS_CREATE: 'LIMITS_CREATE',
  LIMITS_CREATE_REQUEST: 'LIMITS_CREATE_REQUEST',
  LIMITS_CREATE_SUCCESS: 'LIMITS_CREATE_SUCCESS',
  LIMITS_CREATE_FAILURE: 'LIMITS_CREATE_FAILURE',

  LIMITS_INCREMENT_CALENDAR_DATE: 'LIMITS_INCREMENT_CALENDAR_DATE',
  LIMITS_DECREMENT_CALENDAR_DATE: 'LIMITS_DECREMENT_CALENDAR_DATE',
  LIMITS_SET_SELECTED_DATES_AND_LIMITS: 'LIMITS_SET_SELECTED_DATES_AND_LIMITS',
  LIMITS_SET_SELECTED_FILTERS: 'LIMITS_SET_SELECTED_FILTERS',
  LIMITS_CLEAR_SELECTED_LIMITS: 'LIMITS_CLEAR_SELECTED_LIMITS',
  LIMITS_CLEAR_LIMITS_STATE: 'LIMITS_CLEAR_LIMITS_STATE',
  LIMITS_CLEAR_CALENDAR_DATES: 'LIMITS_CLEAR_CALENDAR_DATES',
};

// ACTIONS
export const incrementCalendarDate = () => ({
  type: actionTypes.LIMITS_INCREMENT_CALENDAR_DATE,
});
export const decrementCalendarDate = () => ({
  type: actionTypes.LIMITS_DECREMENT_CALENDAR_DATE,
});
export const setSelectedDatesAndLimits = limits => ({
  type: actionTypes.LIMITS_SET_SELECTED_DATES_AND_LIMITS,
  payload: limits,
});
export const setSelectedLimitsFilters = filters => ({
  type: actionTypes.LIMITS_SET_SELECTED_FILTERS,
  payload: filters,
});

export const clearSelectedLimits = () => ({
  type: actionTypes.LIMITS_CLEAR_SELECTED_LIMITS,
});
export const clearLimitsState = () => ({
  type: actionTypes.LIMITS_CLEAR_LIMITS_STATE,
});
export const clearCalendarDates = () => ({
  type: actionTypes.LIMITS_CLEAR_CALENDAR_DATES,
});

// THUNKS
export const getLimits = () => (dispatch, getState) => {
  const {
    limits: {
      calendar: { dateFrom, dateTo },
      selectedFilters: { answerGroupId, projectId, feed },
    },
  } = getState();

  const formatedDateFrom = moment(dateFrom)
    .utc()
    .format('YYYY-MM-DD');
  const formatedDateTo = moment(dateTo)
    .utc()
    .format('YYYY-MM-DD');

  return dispatch({
    [CALL_API]: {
      type: actionTypes.LIMITS_FETCH,
      payload: {
        endpoint: `/${getApiMarket()}/settings/${feed}/limits`,
        method: 'get',
        params: {
          dateFrom: formatedDateFrom,
          dateTo: formatedDateTo,
          answerGroupId,
          projectId,
        },
      },
      meta: {
        errorToastMessageConfig: {
          [ERROR_CODE.BAD_REQUEST]: { default: 'limitsBadRequest' },
          [ERROR_CODE.FORBIDDEN]: { default: 'limitsForbidden' },
        },
      },
    },
  });
};

export const createLimits = limits => (dispatch, getState) => {
  const {
    limits: {
      changedTableLimits,
      selectedFilters: { answerGroupId, projectId, feed },
    },
  } = getState();

  const postArgs = limits ? Object.values(limits) : changedTableLimits;

  return dispatch({
    [CALL_API]: {
      type: actionTypes.LIMITS_CREATE,
      payload: {
        endpoint: `/${getApiMarket()}/settings/${feed}/limits`,
        method: 'post',
        answerGroupId,
        projectId,
        limits: postArgs,
      },
      meta: {
        successToastMessage: 'limitsAdded',
        errorToastMessageConfig: {
          [ERROR_CODE.BAD_REQUEST]: { default: 'limitsBadRequest' },
          [ERROR_CODE.UNPROCESSABLE_ENTITY]: {
            default: 'createValidationError',
          },
        },
      },
    },
  });
};
