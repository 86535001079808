import { actionTypes } from './actions';

const initialState = {
  data: [],
  loading: false,
  fulfilled: false,
  error: null,
  totalCount: null,
  offset: 0,
};

const {
  USERS_FETCH_REQUEST,
  USERS_FETCH_SUCCESS,
  USERS_FETCH_FAILURE,
  USERS_LOAD_MORE_REQUEST,
  USERS_LOAD_MORE_SUCCESS,
  USERS_LOAD_MORE_FAILURE,
} = actionTypes;

export default (state = initialState, action) => {
  switch (action.type) {
    case USERS_FETCH_REQUEST:
      return { ...initialState, loading: true };
    case USERS_FETCH_SUCCESS:
      return {
        ...initialState,
        data: action.payload.users,
        totalCount: action.payload.totalCount,
        offset: action.payload.users.length,
        fulfilled: true,
      };
    case USERS_FETCH_FAILURE:
      return { ...initialState, error: action.payload };
    case USERS_LOAD_MORE_REQUEST:
      return {
        ...state,
        fulfilled: false,
        error: null,
        loading: true,
      };
    case USERS_LOAD_MORE_SUCCESS:
      return {
        ...initialState,
        fulfilled: true,
        data: [...state.data, ...action.payload.users],
        totalCount: action.payload.totalCount,
        offset: state.offset + action.payload.users.length,
      };
    case USERS_LOAD_MORE_FAILURE:
      return {
        ...state,
        loading: false,
        fulfilled: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
