import PropTypes from 'prop-types';
import React from 'react';

import { LOADER_TYPE } from 'const/styles';

import style from './Loader.module.scss';

const Loader = ({ type }) => {
  const loaders = {
    [LOADER_TYPE.SPINNER]: <div className={`sg-spinner ${style.loader}`} data-testid='no-data-spinner' />,
    [LOADER_TYPE.DOTS]: (
      <>
        <span className={style.dot}>.</span>
        <span className={style.dot}>.</span>
        <span className={style.dot}>.</span>
      </>
    ),
  };

  return loaders[type];
};

Loader.propTypes = {
  type: PropTypes.oneOf(Object.values(LOADER_TYPE)),
};

Loader.defaultProps = {
  type: LOADER_TYPE.SPINNER,
};

export default Loader;
