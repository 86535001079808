import { useDispatch, useSelector } from 'react-redux';
import React from 'react';

import { Toast } from '..';
import { getToasts } from 'store/toasts/selectors';
import { removeToast } from 'store/toasts/actions';

import style from './Notifications.module.scss';

const Notifications = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(getToasts);

  return (
    <div className={style.notifications}>
      {notifications.map(({ id, type, translationKey }) => (
        <Toast key={id} type={type} onDismissClick={() => dispatch(removeToast(id))} translationKey={translationKey} />
      ))}
    </div>
  );
};

export default Notifications;
