import PropTypes from 'prop-types';
import React from 'react';
import styles from './Tooltip.module.scss';

const Tooltip = ({ children, tooltipText }) => (
  <div className={styles.tooltip}>
    <div className={styles.tooltipText}>{tooltipText}</div>
    {children}
  </div>
);

Tooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  tooltipText: PropTypes.string.isRequired,
};

export default Tooltip;
