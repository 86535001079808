import * as Cookies from 'utils/cookies';
import { Fetch } from 'utils/fetch';

export const refreshSession = (() => {
  let isRefreshing = false;
  let refreshesQueue = [];

  const processQueue = (error, data = null) => {
    refreshesQueue.forEach(promise => {
      if (error) {
        promise.reject(error);
      } else {
        promise.resolve(data);
      }
    });

    refreshesQueue = [];
  };

  return refreshToken => {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        refreshesQueue.push({ resolve, reject });
      });
    }
    isRefreshing = true;
    return new Promise((resolve, reject) => {
      Fetch.post('/auth/refresh-token', { token: refreshToken })
        .then(({ data }) => {
          Cookies.setSessionCookies(data);
          processQueue(null, data);
          resolve(data);
        })
        .catch(error => {
          processQueue(error);
          reject(error);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  };
})();
