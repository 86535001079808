import OutsideClickHandler from 'react-outside-click-handler';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import DropdownOption from './DropdownMenuOption';
import style from './DropdownMenu.module.scss';

const DropdownMenu = ({ items, dropDownTitle, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const closeDropdown = () => setIsOpen(false);

  return (
    <OutsideClickHandler onOutsideClick={closeDropdown}>
      <div className={style.dropdown} {...props}>
        <div onClick={toggleDropdown}>{dropDownTitle}</div>
        <div className={style.dropdownList}>{isOpen && items.map(DropdownOption)}</div>
      </div>
    </OutsideClickHandler>
  );
};

DropdownMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ).isRequired,
  dropDownTitle: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default DropdownMenu;
