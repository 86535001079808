import * as AuthService from 'services/auth';
import * as Cookies from 'utils/cookies';
import { WEBSOCKET_EVENT } from 'const/websocket';
import {
  clearSubjectQuestions,
  deleteQuestionById,
  reserveQuestion,
  setNewQuestionsAmount,
  unreserveQuestion,
} from 'store/questionsFeed/actions';
import { closeAnsweringMode } from 'store/answeringMode/actions';
import { getActiveFilters, getCurrentQuestionsFeedType } from 'store/questionsFeed/selectors';
import { getCurrentUserId } from 'store/auth/selectors';
import { getQuestionFeedCounterStrategy } from 'store/questionsCounters/actions';

export const actionTypes = {
  WEBSOCKET_SET_SOCKET_CREATED: 'WEBSOCKET_SET_SOCKET_CREATED',
  WEBSOCKET_SET_SOCKET_REMOVED: 'WEBSOCKET_SET_SOCKET_REMOVED',
  WEBSOCKET_SET_SOCKET_CONNECTED: 'WEBSOCKET_SET_SOCKET_CONNECTED',
  WEBSOCKET_SET_SOCKET_DISCONNECTED: 'WEBSOCKET_SET_SOCKET_DISCONNECTED',
  WEBSOCKET_SET_SOCKET_RECONNECTING: 'WEBSOCKET_SET_SOCKET_RECONNECTING',
};

export const setSocketCreated = () => ({
  type: actionTypes.WEBSOCKET_SET_SOCKET_CREATED,
});
export const setSocketRemoved = () => ({
  type: actionTypes.WEBSOCKET_SET_SOCKET_REMOVED,
});
export const setSocketConnected = () => ({
  type: actionTypes.WEBSOCKET_SET_SOCKET_CONNECTED,
});
export const setSocketDisconnected = () => ({
  type: actionTypes.WEBSOCKET_SET_SOCKET_DISCONNECTED,
});
export const setSocketReconnecting = () => ({
  type: actionTypes.WEBSOCKET_SET_SOCKET_RECONNECTING,
});

// WEBSOCKET CONNECTION LOGIC SHOULD BE REFACTORED
const { REACT_APP_WS_URL: wsURL } = process.env;
const RETRY_TIME_MS = 1000;
const KEEP_ALIVE_TIME_MS = 1000 * 45; // 45 second

let socket = null;
let retryTimeoutID = null;
let keepAliveTimoutID = null;

const keepAlive = () => {
  if (socket && socket.readyState === WebSocket.OPEN) {
    socket.send('');
  }

  keepAliveTimoutID = setTimeout(keepAlive, KEEP_ALIVE_TIME_MS);
};

const cancelKeepAlive = () => {
  if (keepAliveTimoutID) {
    clearTimeout(keepAliveTimoutID);
  }
};

export const socketCleanup = () => dispatch => {
  if (socket) {
    socket.close();
    socket = null;
    dispatch(setSocketRemoved());
  }
};

const reconnect = () => dispatch => {
  dispatch(setSocketReconnecting());
  clearTimeout(retryTimeoutID);
  retryTimeoutID = setTimeout(() => {
    // eslint-disable-next-line no-use-before-define
    dispatch(initSocket());
  }, RETRY_TIME_MS);
};

// eslint-disable-next-line consistent-return
export const initSocket = () => async (dispatch, getState) => {
  dispatch(socketCleanup());

  const accessToken = Cookies.getAuthToken();

  if (!accessToken) {
    const refreshToken = Cookies.getRefreshToken();
    await AuthService.refreshSession(refreshToken);
    return dispatch(reconnect());
  }

  socket = new WebSocket(`${wsURL}?token=${accessToken}`);
  dispatch(setSocketCreated());

  socket.onopen = () => {
    dispatch(setSocketConnected());
    keepAlive();
  };

  socket.onerror = () => {
    dispatch(reconnect());
  };

  socket.onmessage = ({ data }) => {
    if (!data) return;

    /**
     * @type {SocketEventInterface}
     */
    const response = JSON.parse(data);
    const { eventType, feed, payload: { questionsCount, questionId, subjectId, projectId, userId } = {} } = response;
    switch (eventType) {
      case WEBSOCKET_EVENT.NEW_QUESTIONS: {
        const currentFeed = getCurrentQuestionsFeedType(getState());
        if (currentFeed === feed) {
          dispatch(setNewQuestionsAmount(questionsCount));
        }
        break;
      }
      case WEBSOCKET_EVENT.COUNTER_INCREMENTED: {
        const activeFilters = getActiveFilters(getState());
        dispatch(getQuestionFeedCounterStrategy(feed, activeFilters));
        break;
      }
      case WEBSOCKET_EVENT.DELETED:
        dispatch(deleteQuestionById(questionId));
        break;
      case WEBSOCKET_EVENT.RESERVED:
        dispatch(reserveQuestion(questionId));
        break;
      case WEBSOCKET_EVENT.UNRESERVED:
        dispatch(unreserveQuestion(questionId));
        break;
      case WEBSOCKET_EVENT.LIMIT_REACHED:
        dispatch(clearSubjectQuestions({ subjectId, projectId }));
        break;
      case WEBSOCKET_EVENT.ANSWERING_MODE_CLOSED: {
        const currentUserId = getCurrentUserId(getState());
        if (userId === currentUserId) {
          dispatch(closeAnsweringMode());
        }
        break;
      }
      default:
        break;
    }
  };

  socket.onclose = ({ wasClean }) => {
    dispatch(setSocketDisconnected());
    if (!wasClean) {
      dispatch(reconnect());
    } else {
      cancelKeepAlive();
    }
  };
};
