export const BUTTON_TYPE = {
  SOLID: 'solid',
  SOLID_INVERTED: 'solid-inverted',
  SOLID_MINT: 'solid-mint',
  SOLID_BLUE: 'solid-blue',
  OUTLINE: 'outline',
  TRANSPARENT: 'transparent',
  TRANSPARENT_INVERTED: 'transparent-inverted',
  TRANSPARENT_PEACH: 'transparent-peach',
  TRANSPARENT_MUSTARD: 'transparent-mustard',
  TRANSPARENT_BLUE: 'transparent-blue',
};

export const BUTTON_SIZE = {
  LARGE: 'l',
  MEDIUM: 'm',
  SMALL: 's',
};

export const HEADLINE_SIZE = {
  XSMALL: 'xsmall',
  SMALL: 'small',
  NORMAL: '',
  MEDIUM: 'medium',
  LARGE: 'large',
  XLARGE: 'xlarge',
  XXLARGE: 'xxlarge',
};

export const HEADLINE_COLOR = {
  TEXT_BLUE_60: 'text-blue-60',
  TEXT_BLUE_40: 'text-blue-40',
  DEFAULT: '',
};

export const INPUT_SIZE = {
  DEFAULT: '',
  FULLWIDTH: 'full-width',
  LARGE: 'l',
};

export const LABEL_TYPE = {
  TRANSPARENT: 'transparent',
  CLOSABLE: 'closable',
};

export const TEXT_TYPE = {
  BOLD: 'bold',
  UPPERCASE: 'uppercase',
  SMALL: 'small',
  TEXT_BLACK: 'text-black',
};

export const SELECT_TYPE = {
  DEFAULT: '',
  FULLWIDTH: 'full-width',
};

export const DROPDOWN_TYPE = {
  DEFAULT: '',
  FULLWIDTH: 'full-width',
};

export const TEXTAREA_TYPE = {
  AUTO_HEIGHT: 'auto-height',
  DEFAULT: '',
  FULLWIDTH: 'full-width',
  SHORT: 'short',
  SIMPLE: 'simple',
  TALL: 'tall',
  XTALL: 'xtall',
};

// Colors on Style Guide
export const TAB_COLOR = {
  GREEN_50: 'green-50',
  GREEN_40: 'green-40',
  RED_50: 'red-50',
  RED_40: 'red-40',
  YELLOW_50: 'yellow-50',
  YELLOW_40: 'yellow-40',
};

// Flash Messages on Style Guide
export const TOAST_TYPE = {
  DEFAULT: '',
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
};

export const OVERLAY_COLORS = {
  BLACK: 'black',
  BLUE: 'blue',
};

// Not from Style Guide. Local Css styles.
export const LOADER_TYPE = {
  SPINNER: 'spinner',
  DOTS: 'dots',
};

// Toplayer on Style Guide
export const MODAL_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  FILL: 'fill',
};

export const ICON_STYLE = {
  ICON_BLACK: 'icon-black',
  ICON_WHITE: 'icon-white',
};

export const ICON_TYPE = {
  ARROW_DOWN: 'arrow_down',
  ARROW_LEFT: 'arrow_left',
  ARROW_RIGHT: 'arrow_right',
  ARROW_UP: 'arrow_up',
  ATTACHMENT: 'attachment',
  CHECK: 'check',
  CLOSE: 'close',
  CREDIT_CARD: 'credit_card',
  CROSS: 'less',
  FRIEND_CHECK: 'friend_checked',
  FRIENDS: 'friends',
  LOCK: 'lock_with_play',
  MESSAGES: 'messages',
  PLUS: 'plus',
  PROFILE: 'profile',
  PROJECT: 'answer',
  REPORT_FLAG: 'report_flag',
  ROTATE: 'rotate',
  SEARCH: 'search',
  SHARE: 'share',
  TRASH: 'trash',
  VERIFIED: 'verified',
};
