import { CALL_API } from 'store/middlewares/api';
import { getApiMarket } from 'store/utils';

export const actionTypes = {
  PRIVILEGES_FETCH: 'PRIVILEGES_FETCH',
  PRIVILEGES_FETCH_REQUEST: 'PRIVILEGES_FETCH_REQUEST',
  PRIVILEGES_FETCH_SUCCESS: 'PRIVILEGES_FETCH_SUCCESS',
  PRIVILEGES_FETCH_FAILURE: 'PRIVILEGES_FETCH_FAILURE',
  PRIVILEGES_UPDATE: 'PRIVILEGES_UPDATE',
  PRIVILEGES_UPDATE_REQUEST: 'PRIVILEGES_UPDATE_REQUEST',
  PRIVILEGES_UPDATE_SUCCESS: 'PRIVILEGES_UPDATE_SUCCESS',
  PRIVILEGES_UPDATE_FAILURE: 'PRIVILEGES_UPDATE_FAILURE',
};

export const getPrivileges = () => ({
  [CALL_API]: {
    type: actionTypes.PRIVILEGES_FETCH,
    payload: {
      endpoint: `/${getApiMarket()}/privileges`,
      method: 'get',
    },
    meta: {},
  },
});

export const updatePrivileges = answerGroupsPrivileges => ({
  [CALL_API]: {
    type: actionTypes.PRIVILEGES_UPDATE,
    payload: {
      endpoint: `/${getApiMarket()}/privileges`,
      method: 'post',
      answerGroupsPrivileges,
    },
    meta: {
      successToastMessage: 'feedPrivilegesUpdated',
    },
  },
});
