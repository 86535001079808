import { BrowserRouter } from 'react-router-dom';
import React from 'react';

import { Notifications, TopBar } from 'ui';
import ErrorBoundary from 'HOC/Errors/ErrorBoundary';
import NotificationsPortal from 'portals/NotificationsPortal';
import Routes from 'routing/Routes';

import style from './App.module.scss';

const App = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <div className={style.app}>
          <TopBar />
          <Routes />
          <NotificationsPortal>
            <Notifications />
          </NotificationsPortal>
        </div>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default App;
