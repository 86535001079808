import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import { HEADLINE_COLOR, HEADLINE_SIZE } from 'const/styles';

import style from './Headline.module.scss';

const Headline = ({ value, size, className, textBit, color }) => {
  const baseClassName = textBit ? 'sg-text-bit' : 'sg-headline';
  const classNames = cx(style.headline, {
    [baseClassName]: true,
    [`${baseClassName}--${size}`]: true,
    [`${baseClassName}--${color}`]: true,
    [className]: !!className,
  });

  return <h2 className={classNames}>{value}</h2>;
};

Headline.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.values(HEADLINE_COLOR)),
  size: PropTypes.oneOf(Object.values(HEADLINE_SIZE)),
  textBit: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

Headline.defaultProps = {
  className: null,
  color: HEADLINE_COLOR.DEFAULT,
  textBit: false,
  size: HEADLINE_SIZE.NORMAL,
};

export default Headline;
