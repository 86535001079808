import PropTypes from 'prop-types';
import React from 'react';

const DropdownOption = ({ value, onClick }) => (
  <div className='sg-dropdown__item' key={value} onClick={onClick}>
    <div>{value}</div>
  </div>
);

DropdownOption.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DropdownOption;
