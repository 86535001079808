import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import { BUTTON_SIZE, BUTTON_TYPE, ICON_STYLE } from 'const/styles';
import { childrenPropType } from 'utils/propTypes';

import style from './Button.module.scss';

const Button = ({
  value,
  onClick,
  type,
  hover,
  disabled,
  icon,
  iconStyle,
  iconClassName,
  submit,
  className,
  loading,
  loaderClassName,
  withLoader,
  buttonTextClassName,
  size,
  customIcon,
}) => {
  const classNames = cx({
    'sg-button': true,
    [`sg-button--${type}`]: true,
    [`sg-button--disabled`]: disabled,
    [`sg-button--${size}`]: size,
    [`docs-button-hovered--${type}`]: hover,
    [className]: !!className,
    [style.button]: true,
  });

  const loaderClassNames = cx({
    'sg-spinner-container': true,
    [style.loader]: true,
    [loaderClassName]: !!loaderClassName,
  });

  const buttonTextClassNames = cx({
    'sg-button__text': true,
    [buttonTextClassName]: !!buttonTextClassName,
  });

  const buttonType = submit && !disabled ? 'submit' : 'button';

  const buttonPreparation = (
    <button
      style={{ outline: 'none' }}
      type={buttonType}
      className={classNames}
      onClick={disabled ? undefined : onClick}
    >
      <If condition={icon != null}>
        <span className={`sg-button__icon sg-button__icon--${size} ${iconClassName}`}>
          <div className={`${iconClassName} sg-icon--${iconStyle} sg-icon sg-icon--x24`}>
            <svg className='sg-icon__svg' aria-labelledby={`title-${icon}-owdgco`} focusable='false'>
              <use xlinkHref={`#icon-${icon}`} aria-hidden='true' />
            </svg>
          </div>
        </span>
      </If>
      <If condition={customIcon != null}>
        <span className={`sg-button__icon ${iconClassName}`}>
          <div className={`sg-icon sg-icon--${iconStyle} sg-icon--x24`}>{customIcon}</div>
        </span>
      </If>
      <If condition={value !== null}>
        <div className={buttonTextClassNames}>{value}</div>
      </If>
    </button>
  );

  const button =
    icon !== null && value !== null ? (
      <div className='sg-flex sg-flex--column sg-flex--align-items-center'>{buttonPreparation}</div>
    ) : (
      buttonPreparation
    );

  return (
    <Choose>
      <When condition={withLoader}>
        <div className={loaderClassNames}>
          {button}
          <If condition={loading}>
            <div className='sg-spinner-container__overlay'>
              <div className='sg-spinner sg-spinner--light' />
            </div>
          </If>
        </div>
      </When>
      <Otherwise>{button}</Otherwise>
    </Choose>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hover: PropTypes.bool,
  icon: PropTypes.string,
  iconStyle: PropTypes.oneOf(Object.values(ICON_STYLE)),
  iconClassName: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  submit: PropTypes.bool,
  loaderClassName: PropTypes.string,
  type: PropTypes.oneOf(Object.values(BUTTON_TYPE)),
  withLoader: PropTypes.bool,
  value: childrenPropType,
  buttonTextClassName: PropTypes.string,
  size: PropTypes.oneOf(Object.values(BUTTON_SIZE)),
  customIcon: PropTypes.node,
};

Button.defaultProps = {
  className: null,
  disabled: false,
  hover: false,
  icon: null,
  iconStyle: ICON_STYLE.ICON_BLACK,
  iconClassName: null,
  onClick: null,
  loading: false,
  loaderClassName: null,
  submit: false,
  type: BUTTON_TYPE.SOLID,
  withLoader: false,
  buttonTextClassName: null,
  size: null,
  customIcon: null,
  value: null,
};

export default Button;
