import axios from 'axios';

import * as AuthService from 'services/auth';
import { logout } from 'store/auth/actions';

import * as Cookies from './cookies';
import { ERROR_CODE, getErrorMessage } from './error';

const { REACT_APP_API_URL: baseURL } = process.env;

const defaultOptions = {
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const Fetch = axios.create(defaultOptions);

export const initFetch = store => {
  Fetch.interceptors.request.use(config => {
    const newConfig = { ...config };
    const token = Cookies.getAuthToken();

    if (token && !config.headers.Authorization) {
      newConfig.headers.Authorization = `Bearer ${token}`;
      return newConfig;
    }

    return config;
  });

  Fetch.interceptors.response.use(
    response => response,
    async error => {
      const { response, config } = error;

      if (response && response.status === ERROR_CODE.UNAUTHORIZED) {
        if (config.url.includes('/auth/refresh-token')) {
          store.dispatch(logout());
        }
        const token = Cookies.getRefreshToken();
        if (token) {
          return AuthService.refreshSession(token)
            .then(data => {
              const newConfig = { ...error.config };
              newConfig.headers.Authorization = `Bearer ${data.accessToken.value}`;
              return axios.request(config);
            })
            .catch(err => Promise.reject(err));
        }
        if (!config.url.includes('/sign-in')) {
          store.dispatch(logout());
        }
      }

      return Promise.reject(getErrorMessage(response));
    },
  );
};
