import { createSelector } from 'reselect';

const getSocket = createSelector(
  state => state.websocket,
  websocket => websocket,
);

export const isSocketConnected = createSelector(
  getSocket,
  ({ connected }) => connected,
);

export const isSocketReconnecting = createSelector(
  getSocket,
  ({ reconnecting }) => reconnecting,
);
