import { CALL_API } from 'store/middlewares/api';
import { ERROR_CODE } from 'utils/error';
import { getApiMarket, getApiPrefix } from 'store/utils';

export const actionTypes = {
  SINGLE_USER_FETCH: 'SINGLE_USER_FETCH',
  SINGLE_USER_FETCH_REQUEST: 'SINGLE_USER_FETCH_REQUEST',
  SINGLE_USER_FETCH_SUCCESS: 'SINGLE_USER_FETCH_SUCCESS',
  SINGLE_USER_FETCH_FAILURE: 'SINGLE_USER_FETCH_FAILURE',

  SINGLE_USER_DISABLE: 'SINGLE_USER_DISABLE',
  SINGLE_USER_DISABLE_REQUEST: 'SINGLE_USER_DISABLE_REQUEST',
  SINGLE_USER_DISABLE_SUCCESS: 'SINGLE_USER_DISABLE_SUCCESS',
  SINGLE_USER_DISABLE_FAILURE: 'SINGLE_USER_DISABLE_FAILURE',

  SINGLE_USER_ENABLE: 'SINGLE_USER_ENABLE',
  SINGLE_USER_ENABLE_REQUEST: 'SINGLE_USER_ENABLE_REQUEST',
  SINGLE_USER_ENABLE_SUCCESS: 'SINGLE_USER_ENABLE_SUCCESS',
  SINGLE_USER_ENABLE_FAILURE: 'SINGLE_USER_ENABLE_FAILURE',

  SINGLE_USER_UPDATE: 'SINGLE_USER_UPDATE',
  SINGLE_USER_UPDATE_REQUEST: 'SINGLE_USER_UPDATE_REQUEST',
  SINGLE_USER_UPDATE_SUCCESS: 'SINGLE_USER_UPDATE_SUCCESS',
  SINGLE_USER_UPDATE_FAILURE: 'SINGLE_USER_UPDATE_FAILURE',
};

export const getSingleUserData = id => ({
  [CALL_API]: {
    type: actionTypes.SINGLE_USER_FETCH,
    payload: {
      endpoint: `/${getApiMarket()}${getApiPrefix()}/users/${id}`,
      method: 'get',
    },
    meta: {},
  },
});

export const disableUser = id => ({
  [CALL_API]: {
    type: actionTypes.SINGLE_USER_DISABLE,
    payload: {
      endpoint: `/${getApiMarket()}${getApiPrefix()}/users/${id}/disable`,
      method: 'post',
    },
    meta: {
      successToastMessage: 'userDisabled',
    },
  },
});

export const enableUser = id => ({
  [CALL_API]: {
    type: actionTypes.SINGLE_USER_ENABLE,
    payload: {
      endpoint: `/${getApiMarket()}${getApiPrefix()}/users/${id}/enable`,
      method: 'post',
    },
    meta: {
      successToastMessage: 'userEnabled',
    },
  },
});

export const updateUser = (id, values) => ({
  [CALL_API]: {
    type: actionTypes.SINGLE_USER_UPDATE,
    payload: {
      endpoint: `/${getApiMarket()}${getApiPrefix()}/users/${id}`,
      method: 'put',
      ...values,
    },
    meta: {
      successToastMessage: 'userUpdated',
      errorToastMessageConfig: {
        [ERROR_CODE.CONFLICT]: { default: 'userExists' },
        [ERROR_CODE.UNPROCESSABLE_ENTITY]: { default: 'updateValidationError' },
      },
    },
  },
});
