import PropTypes from 'prop-types';
import React from 'react';

import { LABEL_TYPE, TEXT_TYPE } from 'const/styles';
import { Label } from 'ui';

import style from './InputFile.module.scss';

const InputFile = ({ onFileSelectSuccess, onFileSelectError, extensions, label, buttonText, required, disabled }) => {
  const handleFileInput = e => {
    const file = e.target.files[0];
    e.target.value = null;

    if (file !== undefined) {
      // If more file cheks needed --> example: If (file.size > 1024) {onFileSelectError({error:'File size cannot exceed more than 1 MB'})}
      onFileSelectSuccess(file);
    } else {
      onFileSelectError({ error: 'File not found.' });
    }
  };

  return (
    <>
      {label && (
        <Label
          type={LABEL_TYPE.TRANSPARENT}
          labelText={`${TEXT_TYPE.TEXT_BLACK},${TEXT_TYPE.SMALL},${TEXT_TYPE.BOLD}`}
          isRequired={required}
        >
          {label}
        </Label>
      )}
      <div className={style.inputFileContainer}>
        {<span className={style.inputText}>{buttonText}</span>}
        <input
          type='file'
          accept={extensions}
          className={style.inputFile}
          onChange={handleFileInput}
          disabled={disabled}
        />
      </div>
    </>
  );
};

InputFile.propTypes = {
  onFileSelectSuccess: PropTypes.func.isRequired,
  onFileSelectError: PropTypes.func.isRequired,
  extensions: PropTypes.string,
  label: PropTypes.string,
  buttonText: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

InputFile.defaultProps = {
  extensions: '',
  label: null,
  buttonText: 'Select File',
  required: false,
  disabled: false,
};

export default InputFile;
