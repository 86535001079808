import { CALL_API } from 'store/middlewares/api';

import { getAnswerGroupUsersSearch } from 'store/search/selectors';
import { getApiMarket } from 'store/utils';
import { getCurrentAnswerGroupUsersOffset } from './selectors';

const DEFAULT_NUMBRE_OF_USERS_PER_PAGE = 50;

const defaultGetAnswerGroupUsersParams = {
  limit: DEFAULT_NUMBRE_OF_USERS_PER_PAGE,
  offset: 0,
  search: '',
};

export const actionTypes = {
  ANSWER_GROUP_USERS_FETCH: 'ANSWER_GROUP_USERS_FETCH',
  ANSWER_GROUP_USERS_FETCH_REQUEST: 'ANSWER_GROUP_USERS_FETCH_REQUEST',
  ANSWER_GROUP_USERS_FETCH_SUCCESS: 'ANSWER_GROUP_USERS_FETCH_SUCCESS',
  ANSWER_GROUP_USERS_FETCH_FAILURE: 'ANSWER_GROUP_USERS_FETCH_FAILURE',

  ANSWER_GROUP_USERS_UPDATE_USERS: 'ANSWER_GROUP_USERS_UPDATE_USERS',

  ANSWER_GROUP_USERS_LOAD_MORE: 'ANSWER_GROUP_USERS_LOAD_MORE',
  ANSWER_GROUP_USERS_LOAD_MORE_REQUEST: 'ANSWER_GROUP_USERS_LOAD_MORE_REQUEST',
  ANSWER_GROUP_USERS_LOAD_MORE_SUCCESS: 'ANSWER_GROUP_USERS_LOAD_MORE_SUCCESS',
  ANSWER_GROUP_USERS_LOAD_MORE_FAILURE: 'ANSWER_GROUP_USERS_LOAD_MORE_FAILURE',
};

export const getAnswerGroupUsers = (answerGroupId, params = {}) => ({
  [CALL_API]: {
    type: actionTypes.ANSWER_GROUP_USERS_FETCH,
    payload: {
      endpoint: `/${getApiMarket()}/answer-groups/${answerGroupId}/users`,
      method: 'get',
      params: {
        ...defaultGetAnswerGroupUsersParams,
        ...params,
      },
    },
    meta: {},
  },
});

export const loadMoreAnswerGroupUsers = answerGroupId => (dispatch, getState) => {
  const state = getState();
  const offset = getCurrentAnswerGroupUsersOffset(state);
  const search = getAnswerGroupUsersSearch(state);
  dispatch({
    [CALL_API]: {
      type: actionTypes.ANSWER_GROUP_USERS_LOAD_MORE,
      payload: {
        endpoint: `/${getApiMarket()}/answer-groups/${answerGroupId}/users`,
        method: 'get',
        params: {
          ...defaultGetAnswerGroupUsersParams,
          offset,
          search,
        },
      },
      meta: {},
    },
  });
};

export const updateAnswerGroupUsers = reassignedUsers => ({
  type: actionTypes.ANSWER_GROUP_USERS_UPDATE_USERS,
  payload: reassignedUsers,
});
