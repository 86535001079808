import { Redirect, Route, Switch } from 'react-router-dom';
import React, { lazy } from 'react';

import { Container } from 'ui';
import { answeringGroupAdminRoutes } from 'const/routing';

const AnsweringGroupAdminPanel = lazy(() => import('./AnsweringGroupAdminPanel/AnsweringGroupAdminPanel'));
const ProfilePage = lazy(() => import('screens/Profile/ProfilePage'));

const AnsweringGroupAdminRoutes = () => {
  return (
    <Container>
      <Switch>
        <Route path={answeringGroupAdminRoutes.answeringGroupAdminBasePath} component={AnsweringGroupAdminPanel} />
        <Route exact path={answeringGroupAdminRoutes.profilePath} component={ProfilePage} />
        <Route exact path='/' component={() => <Redirect to={answeringGroupAdminRoutes.userListPath} />} />
      </Switch>
    </Container>
  );
};

export default AnsweringGroupAdminRoutes;
