import { actionTypes } from './actions';

const mapEvaluationPriviligesToAnswerGroup = data =>
  data.reduce((acc, { answerGroupId, feedsPrivileges }) => {
    const privileges = feedsPrivileges.reduce(
      (obj, { feedId, isEnabled }) => ({
        ...obj,
        [feedId]: isEnabled,
      }),
      {},
    );
    return {
      ...acc,
      [answerGroupId]: privileges,
    };
  }, {});

const initialState = {
  data: [],
  loading: false,
  fulfilled: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EVALUATION_PRIVILEGES_FETCH_REQUEST:
      return { ...initialState, loading: true };
    case actionTypes.EVALUATION_PRIVILEGES_FETCH_SUCCESS:
      return {
        ...initialState,
        data: mapEvaluationPriviligesToAnswerGroup(action.payload.evaluationPrivileges),
        fulfilled: true,
      };
    case actionTypes.EVALUATION_PRIVILEGES_FETCH_FAILURE:
      return { ...initialState, error: action.payload };
    case actionTypes.EVALUATION_PRIVILEGES_UPDATE_FAILURE:
      return { ...initialState, error: action.payload };
    default:
      return state;
  }
};
