import { actionTypes } from './actions';

const initialState = {
  created: false,
  connected: false,
  reconnecting: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.WEBSOCKET_SET_SOCKET_CREATED:
      return { ...state, created: true };
    case actionTypes.WEBSOCKET_SET_SOCKET_REMOVED:
      return { ...state, created: false };
    case actionTypes.WEBSOCKET_SET_SOCKET_CONNECTED:
      return { ...state, connected: true, reconnecting: false };
    case actionTypes.WEBSOCKET_SET_SOCKET_DISCONNECTED:
      return { ...state, connected: false };
    case actionTypes.WEBSOCKET_SET_SOCKET_RECONNECTING:
      return { ...state, reconnecting: true };
    default:
      return state;
  }
};
