import { CALL_API } from 'store/middlewares/api';
import { FEED_TYPE } from 'const/feeds';
import { getActiveFilters, getCurrentQuestionsFeedType } from 'store/questionsFeed/selectors';
import { getCurrentUserMarket } from 'store/auth/selectors';

export const actionTypes = {
  QUESTIONS_LIMITS_TSR_FETCH: 'QUESTIONS_LIMITS_TSR_FETCH',
  QUESTIONS_LIMITS_TSR_FETCH_REQUEST: 'QUESTIONS_LIMITS_TSR_FETCH_REQUEST',
  QUESTIONS_LIMITS_TSR_FETCH_SUCCESS: 'QUESTIONS_LIMITS_TSR_FETCH_SUCCESS',
  QUESTIONS_LIMITS_TSR_FETCH_FAILURE: 'QUESTIONS_LIMITS_TSR_FETCH_FAILURE',
  QUESTIONS_LIMITS_FA_FETCH: 'QUESTIONS_LIMITS_FA_FETCH',
  QUESTIONS_LIMITS_FA_FETCH_REQUEST: 'QUESTIONS_LIMITS_FA_FETCH_REQUEST',
  QUESTIONS_LIMITS_FA_FETCH_SUCCESS: 'QUESTIONS_LIMITS_FA_FETCH_SUCCESS',
  QUESTIONS_LIMITS_FA_FETCH_FAILURE: 'QUESTIONS_LIMITS_FA_FETCH_FAILURE',
  QUESTIONS_LIMITS_QI_FETCH: 'QUESTIONS_LIMITS_QI_FETCH',
  QUESTIONS_LIMITS_QI_FETCH_REQUEST: 'QUESTIONS_LIMITS_QI_FETCH_REQUEST',
  QUESTIONS_LIMITS_QI_FETCH_SUCCESS: 'QUESTIONS_LIMITS_QI_FETCH_SUCCESS',
  QUESTIONS_LIMITS_QI_FETCH_FAILURE: 'QUESTIONS_LIMITS_QI_FETCH_FAILURE',
};

// API ACTIONS
const getQuestionFeedCounter = (feedType, baseAction, market, params) => {
  return {
    [CALL_API]: {
      type: baseAction,
      payload: {
        endpoint: `/${market}/counter/${feedType}`,
        method: 'get',
        meta: { feedType },
        params,
      },
    },
  };
};

export const getTsrQuestionFeedCounter = (market, params) =>
  getQuestionFeedCounter(FEED_TYPE.TSR, actionTypes.QUESTIONS_LIMITS_TSR_FETCH, market, params);
export const getFaQuestionFeedCounter = (market, params) =>
  getQuestionFeedCounter(FEED_TYPE.FAST_ANSWERING, actionTypes.QUESTIONS_LIMITS_FA_FETCH, market, params);
export const getQiQuestionFeedCounter = (market, params) =>
  getQuestionFeedCounter(FEED_TYPE.QI, actionTypes.QUESTIONS_LIMITS_QI_FETCH, market, params);

const getCounterToFeedTypeMap = {
  [FEED_TYPE.TSR]: getTsrQuestionFeedCounter,
  [FEED_TYPE.FAST_ANSWERING]: getFaQuestionFeedCounter,
  [FEED_TYPE.QI]: getQiQuestionFeedCounter,
};

export const getQuestionFeedCounterStrategy = (feedType, market, params) => {
  return getCounterToFeedTypeMap[feedType](market, params);
};

// THUNKS

export const refreshCurrentCounter = () => (dispatch, getState) => {
  const state = getState();
  const feedType = getCurrentQuestionsFeedType(state);
  const activeFilters = getActiveFilters(state);
  const market = getCurrentUserMarket(state);
  return dispatch(getQuestionFeedCounterStrategy(feedType, market, activeFilters));
};
