import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import style from './LineSeparator.module.scss';

const LineSeparator = ({ spaceTop, spaceBottom }) => {
  const classNames = cx({
    'sg-horizontal-separator sg-horizontal-separator--grey': true,
    [style.spaceBottom]: spaceBottom,
    [style.spaceTop]: spaceTop,
  });

  return <div className={classNames} />;
};

LineSeparator.propTypes = {
  spaceBottom: PropTypes.bool,
  spaceTop: PropTypes.bool,
};

LineSeparator.defaultProps = {
  spaceBottom: false,
  spaceTop: true,
};

export default LineSeparator;
