import { FEED_TYPE } from 'const/feeds';
import { actionTypes } from './actions';

const initialState = {
  [FEED_TYPE.FAST_ANSWERING]: {
    faulty: 0,
    answered: 0,
    limit: 0,
  },
  [FEED_TYPE.QI]: {
    faulty: 0,
    answered: 0,
    limit: 0,
  },
  [FEED_TYPE.TSR]: {
    faulty: 0,
    answered: 0,
    limit: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.QUESTIONS_LIMITS_FA_FETCH_SUCCESS:
      return { ...state, [FEED_TYPE.FAST_ANSWERING]: action.payload };
    case actionTypes.QUESTIONS_LIMITS_QI_FETCH_SUCCESS:
      return { ...state, [FEED_TYPE.QI]: action.payload };
    case actionTypes.QUESTIONS_LIMITS_TSR_FETCH_SUCCESS:
      return { ...state, [FEED_TYPE.TSR]: action.payload };
    default:
      return state;
  }
};
