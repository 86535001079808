import { CALL_API } from 'store/middlewares/api';
import { getApiMarket } from 'store/utils';

export const actionTypes = {
  ATTACHMENTS_FETCH: 'ATTACHMENTS_FETCH',
  ATTACHMENTS_FETCH_REQUEST: 'ATTACHMENTS_FETCH_REQUEST',
  ATTACHMENTS_FETCH_SUCCESS: 'ATTACHMENTS_FETCH_SUCCESS',
  ATTACHMENTS_FETCH_FAILURE: 'ATTACHMENTS_FETCH_FAILURE',
};

export const getAttachments = (feed, questionId) => ({
  [CALL_API]: {
    type: actionTypes.ATTACHMENTS_FETCH,
    payload: {
      endpoint: `/${getApiMarket()}/questions/${feed}/${questionId}/attachments`,
      method: 'get',
    },
    meta: {
      questionId,
    },
  },
});
