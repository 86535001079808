import { CALL_API } from 'store/middlewares/api';
import { ERROR_CODE } from 'utils/error';
import { getApiMarket } from 'store/utils';

export const actionTypes = {
  ASSIGNED_QUESTIONS_LOG_FETCH: 'ASSIGNED_QUESTIONS_LOG_FETCH',
  ASSIGNED_QUESTIONS_LOG_FETCH_REQUEST: 'ASSIGNED_QUESTIONS_LOG_FETCH_REQUEST',
  ASSIGNED_QUESTIONS_LOG_FETCH_SUCCESS: 'ASSIGNED_QUESTIONS_LOG_FETCH_SUCCESS',
  ASSIGNED_QUESTIONS_LOG_FETCH_FAILURE: 'ASSIGNED_QUESTIONS_LOG_FETCH_FAILURE',

  REASSIGN_QUESTIONS: 'REASSIGN_QUESTIONS',
  REASSIGN_QUESTIONS_REQUEST: 'REASSIGN_QUESTIONS_REQUEST',
  REASSIGN_QUESTIONS_SUCCESS: 'REASSIGN_QUESTIONS_SUCCESS',
  REASSIGN_QUESTIONS_FAILURE: 'REASSIGN_QUESTIONS_FAILURE',
};

export const getAssignedQuestionsLogData = ({ projectId, feed, dateRanges }) => ({
  [CALL_API]: {
    type: actionTypes.ASSIGNED_QUESTIONS_LOG_FETCH,
    payload: {
      endpoint: `/${getApiMarket()}/assigned-questions/${feed}`,
      method: 'get',
      params: {
        projectId,
        dateRanges,
      },
    },
    meta: {
      errorToastMessageConfig: {
        [ERROR_CODE.BAD_REQUEST]: { default: 'limitsBadRequest' },
        [ERROR_CODE.FORBIDDEN]: { default: 'limitsForbidden' },
      },
    },
  },
});

export const reassignQuestions = feed => ({
  [CALL_API]: {
    type: actionTypes.REASSIGN_QUESTIONS,
    payload: {
      endpoint: `/${getApiMarket()}/assigned-questions/${feed}/reassign`,
      method: 'post',
    },
    meta: {
      successToastMessage: 'questionsReassigned',
    },
  },
});
