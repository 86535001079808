import { createSelector } from 'reselect';

const getUsers = createSelector(
  state => state.users,
  users => users,
);

export const areUsersLoaded = createSelector(
  getUsers,
  ({ fulfilled }) => fulfilled,
);

export const areUsersLoading = createSelector(
  getUsers,
  ({ loading }) => loading,
);

export const getUsersData = createSelector(
  getUsers,
  ({ data }) => data,
);

export const getCurrentUsersOffset = createSelector(
  getUsers,
  ({ offset }) => offset,
);

export const getUsersTotalCount = createSelector(
  getUsers,
  ({ totalCount }) => totalCount,
);
